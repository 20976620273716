import { Fragment } from 'react';
import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import { setInboundPurchaseOrders } from '../../../store/inboundPOSlice';
import Table from "../../../components/table/table";

const InboundPurchaseOrders = (props) => {
	const inboundPurchaseOrders = useSelector((state) => state.inboundPurchaseOrders);
	const columnVisibilityModel = { locationName: false };

	let columns = [
		{ 
			field: 'purchaseOrderName', 
			filterable: true, 
			headerName: 'ORDER', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
		{ 
			field: 'createdAt', 
			filterable: true, 
			headerName: 'DATE', 
			flex: 1.8, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
		{ 
			field: 'vendorName', 
			filterable: true, 
			headerName: 'VENDOR', 
			flex: 1.5, 
			headerClassName: 'super-app-theme--header', 
			sortable: false,
			type: 'enum',
			columnName: 'vendorName',
			tableName: 'PoVendorDetails'
		},
		{ 
			field: 'locationCode', 
			filterable: true, 
			headerName: 'Location Code', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true,
			type: 'enum',
			columnName: 'locationCode',
			tableName: 'PoLocationDetails'
		},
		{ 
			field: 'locationName', 
			filterable: true, 
			headerName: 'Location Name', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true,
			type: 'enum',
			columnName: 'locationName',
			tableName: 'PoLocationDetails'
		},
		{
			field: 'paymentStatus',
			headerName: 'PAY STATUS',
			headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
			filterable: false,
			sortable: true,
			flex: 1.5,
			renderCell: (params) => (
				params.value === "Unpaid" ? (
					<Chip label={params.value} />
				) : (
					<Chip style={{ backgroundColor: '#ffea88' }} label={params.value} />
				)
			),
			enumValue: ['Unpaid', 'Paid']
		},
		{
			field: 'status',
			headerName: 'STATUS',
			headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
			filterable: true,
			sortable: true,
			flex: 1.5,
			type: 'enum',
			columnName: 'status',
			tableName: 'PurchaseOrders',
			renderCell: (params) => (
				params.value === "Completed" ? (
					<Chip label={params.value} />
				) : (
					<Chip style={{ backgroundColor:params.value === 'Delivered'? '#128038':'#ffea88', color:params.value === 'Delivered' ?'#fff' :'#000' }} label={params.value} />
				)
			),
			
		},
		{ 
			field: 'totalAmount', 
			filterable: false, 
			valueFormatter: (params) => `₹${params.value}`, 
			headerName: 'TOTAL', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
		{ 
			field: 'createdBy', 
			filterable: false, 
			headerName: 'CREATED BY', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true,
			type: 'enum',
			columnName: 'createdBy',
			tableName: 'PurchaseOrders',
		},
	];

	return (
		<Fragment>
			<Table
				columns={columns}
				dataPosition={"purchaseOrders"}
				endpoint={`/purchaseOrders/purchaseOrders`}
				rowId={'purchaseOrderName'}
				checkBoxSelection={false}
				version="version-1"
				service={'purchaseOrders'}
				tabName={'Inbound Purchase Orders'}
				navigateOnRowClickEndpoint={`/inboundPurchaseOrders`}
				setData={setInboundPurchaseOrders}
				data={inboundPurchaseOrders}
				mobileResponsive={true}
				columnVisibilityModel={columnVisibilityModel}
				exportEndpoint={'/exports/purchaseOrders'}
				exportFileName={`Purchase Orders - ${new Date()}`}
				searchKey={'text'}
				clickable={true}
			/>
		</Fragment>
	);
};

export default InboundPurchaseOrders;
