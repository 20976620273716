import { useState, forwardRef } from 'react'
import Permission from "../pages/auth/permissions";
import axiosInstance from '../utility/axios-instance';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { downloadCSV } from "../utility/downloadCSV";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import FileOpenIcon from '@mui/icons-material/FileOpen';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ImportFileComponent = ({ endpoint, type, serviceName, templateDownloadEndpoint, setReRender, setData, text }) => {
    const [loading, setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [error, setError] = useState({ display: false, message: "default message!", data: [] })
    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };

    const handleErrorClose = () => {
        setError(
            { display: false, message: "default message!", data: [] }
        );
    };

    const handleErrorExport = () => {
        if (error?.data?.length > 0) {
            downloadCSV(error?.data, `Error (${type}) - ${new Date()}`);
        }
        handleErrorClose()
    }

    const handleImport = async (event) => {
        setLoading(true);
        event.preventDefault();
        var formData = new FormData();
        formData.append(`${type}`, document.getElementById(`${type}`).files[0]);

        await axiosInstance
            .post(`${endpoint}`, formData)
            .then((res) => {
                event.target.reset();
                const successMessage = res?.data?.message ? res?.data?.message : `${type} imported successfully`
                if(typeof(setData) === 'function'){
                    setData(res?.data?.data)
                }
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setReRender?.((prev) => !prev);
                if (res?.data?.data?.errorRecords?.length > 0) {
                    setError(
                        { display: true, message: `${res?.data?.data?.errorRecords?.length} invalid entries!!`, data: res?.data?.data?.errorRecords }
                    );
                }
            })
            .catch((error) => {
                console.log(error, "Ajay");
                event.target.reset();
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleTemplateDownload = (event) => {
        event.preventDefault();
        setLoading(true);

        axiosInstance
            .get(`${templateDownloadEndpoint}`)
            .then((result) => {
                const arrayOfRowObjects = result?.data?.data
	            downloadCSV(arrayOfRowObjects,`${type} template - ${new Date()}`);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: `${type} template downloaded successfully`, type: "success" }
                });
            })
            .catch((error) => {
                console.log(error)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: error?.response?.data?.message, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }


    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} open={error?.display} autoHideDuration={10000} onClose={handleErrorClose}
            >
                <Alert action={
                    <Button color="inherit" size="small" onClick={() => handleErrorExport()}>
                        EXPORT
                    </Button>
                } onClose={handleSnackBarClose} severity="error" sx={{ width: '100%' }}>
                    {error?.message}
                </Alert>
            </Snackbar>

            <Snackbar open={snackBar?.display} autoHideDuration={1500} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>
            <Permission service={serviceName} permission="update">
                <div style={{display:'flex', gap:10, flexWrap:'wrap' }}>
                <form onSubmit={handleImport}>
                    <div className="d-flex" style={{flexWrap:'wrap',gap:10}}>
                        <div>
                            <input
                                className="form-control margin-right"
                                id={type}
                                type="file"
                                accept=".csv"
                                name={type}
                                required
                                style={{marginTop:0}}
                            />
                        </div>
                        <div>
                            <Button
                                type="submit"
                                id='import'
                                variant="contained"
                                color="primary"
                                sx={{
                                    textTransform: "capitalize",
                                    "& .MuiButton-startIcon>*:nth-of-type(1)": { fontSize: '16px' }
                                }}
                                style={{ minWidth:'fit-content' }}
                                startIcon={<FileUploadIcon />}
                            >
                                {text ?? 'Import'}
                            </Button>
                        </div>

                        {templateDownloadEndpoint && (
                            <Fab color="primary" size="small" onClick={handleTemplateDownload}>
                                <FileOpenIcon />
                            </Fab>
                        )}
                    </div>
                </form>
              
                </div>
            </Permission>
        </div>
    )
}
