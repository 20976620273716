import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Classes from "./pages/home/draftOrdersNew/css/draftOrderDetailPage.module.css";
import PushPinIcon from '@mui/icons-material/PushPin';

const PinnedTab = () => {
    const pinTab = useSelector((state) => state.pinTab);
    const navigate = useNavigate();

    const handleRedirectDashboard = (url) => {
        navigate(`/${url}`);
    }

    return (
        <div style={{ gap:"0.5rem",fontSize:14 }} className={Classes.Company}>
            {Object.keys(pinTab)?.map((key, index) => (
                <div key={index}>
                    {pinTab[key]?.name && (
                        <div className={Classes.PinnedTab} onClick={(e) =>  handleRedirectDashboard(pinTab[key]?.url)}>
                            <p style={{marginRight:5}}>{pinTab[key]?.name}</p>
                            <PushPinIcon style={{fontSize:14,transform:'rotate(30deg)',marginTop:3}} />
                        </div>
                    )}
                </div>
            ))}
            <div className={Classes['cust-divider']}></div>
        </div>
    )
}

export default PinnedTab;