import React, { Fragment, useState, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import Classes from './css/product.module.css';
import axiosInstance from '../../../utility/axios-instance';
import ConfirmPopup from './confirmPopup';
import { useSelector } from 'react-redux';
import Permission from '../../auth/permissions';
import SettingsPopup from './settingsPopup';
import Spinner from '../../../components/spinner';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import { Badge, Tooltip } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { setProducts } from '../../../store/productsSlice';
import moment from 'moment';
import Table from "../../../components/table/table";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Products = (props) => {
  const [productId, setProductId] = useState([]);
  const [showConfirmPopup, setConfirmShowPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reRender,setReRender] = useState(false);
  const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

  const products = useSelector((state) => state.products);

  const countPlaces = (num) => {
    var sep = String(23.32).match(/\D/)[0];
    var b = String(num).split(sep);
    const result =  b[1] ? b[1].length : 0;
    return result === 1 || result === 0 || result === 2 ? num : Math.trunc(num*100)/100
  };

  const colorizeRowData = (data, condition) => (
    <Tooltip title={data} placement="top">
        <div style={{color: condition === true ? "#ff6200" : '#000'}}>{data}</div>
    </Tooltip>
  );

  const ImageRenderCell = (row) => {
    return (
      <img
        src={row?.image !== undefined ? row?.image : ''}
        alt='icon'
        className={Classes.ProductImages}
      />
    )
  }

  const StatusRenderCell = (row) => {
    const chip = row?.active ?
      <Chip label="Active" sx={{ backgroundColor: 'darkcyan', color: 'white' }} /> :
      <Chip label="Draft" />
      
    return (
      <> 
        {row?.isParentProduct === true && row?.isVirtualProduct === false ?
          <Badge badgeContent='P' 
            sx={{"& .MuiBadge-badge": {color: "white", backgroundColor: "#ff6200"}}}
          >
            {chip}
          </Badge>
        : chip}
      </>
    )
  }

  let columns = [
    {
      field: 'image', 
      filterable: true, 
      headerName: 'IMAGE', 
      width: 90, 
      headerClassName: 'super-app-theme--header', 
      sortable: false,
      renderCell: (props) => ( <ImageRenderCell {...props?.row} />),
      customRenderCell: ImageRenderCell
    },
    {
      field: 'productName',
      filterable: true,
      headerName: 'PRODUCT',
      width: 280,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      renderCell: (params) => (
        colorizeRowData(params?.row?.productName, params?.row?.isParentProduct === true)
      )
    },
    {
      field: 'active',
      headerName: 'STATUS',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      filterable: true,
      sortable: false,
      width: 140,
      enumValue: [true, false],
      renderCell: (props) => ( <StatusRenderCell {...props?.row} /> ),
      customRenderCell: StatusRenderCell
    },
    {
      field: 'sku',
      filterable: true,
      headerName: 'SKU',
      width: 185,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      renderCell: (params) => (colorizeRowData(params?.row?.sku, params?.row?.isParentProduct === true))
    },
    { 
      field: 'brandName', 
      filterable: true, 
      headerName: 'BRAND',  
      width: 140, 
      headerClassName: 'super-app-theme--header', 
      sortable: true,
      type: 'enum',
      columnName: 'brand_name',
      tableName: 'product_collections',
      renderCell: (params) => (
        colorizeRowData(params?.row?.brandName, params?.row?.isParentProduct === true)
      )
    },
    {
      field: "mrp",
      headerName: "MRP",
      width: 100,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        colorizeRowData(`₹${countPlaces(params?.row?.mrp)}`, params?.row?.isParentProduct === true)
      )
    },
    {
      field: "listingPrice",
      headerName: "LISTING PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        colorizeRowData(`₹${countPlaces(params?.row?.listingPrice)}`, params?.row?.isParentProduct === true)
      )
    },
    {
      field: "retailPrice",
      headerName: "RETAILER PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        colorizeRowData(params?.row?.retailPrice ? `₹${countPlaces(params?.row?.retailPrice)}`: "N/A", params?.row?.isParentProduct === true)
      )
    },
    {
      field: "specialRetailPrice",
      headerName: "SPECIAL RETAILER PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        colorizeRowData(params?.row?.specialRetailPrice ? `₹${countPlaces(params?.row?.specialRetailPrice)}` : "N/A", params?.row?.isParentProduct === true)  
      )
    },
    {
      field: "interiorPrice",
      headerName: "INTERIOR PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        colorizeRowData(params?.row?.interiorPrice ? `₹${countPlaces(params?.row?.interiorPrice)}` : "N/A", params?.row?.isParentProduct === true)
      )
    },
    {
      field: "oemPrice",
      headerName: "OEM PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        colorizeRowData(params?.row?.oemPrice ? `₹${countPlaces(params?.row?.oemPrice)}` : "N/A", params?.row?.isParentProduct === true)
      )
    },
    {
      field: "endUserPrice",
      headerName: "END USER PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        colorizeRowData(params?.row?.endUserPrice ? `₹${countPlaces(params?.row?.endUserPrice)}` : "N/A", params?.row?.isParentProduct === true)  
      )
    },
    {
      field: "partnerPrice",
      headerName: "PARTNER PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        colorizeRowData(params?.row?.partnerPrice ? `₹${countPlaces(params?.row?.partnerPrice)}` : "N/A", params?.row?.isParentProduct === true)
      )
    },
    {
      field: "promoterPrice",
      headerName: "PROMOTER PRICE",
      width: 140,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        colorizeRowData(params?.row?.promoterPrice ? `₹${countPlaces(params?.row?.promoterPrice)}` : "N/A", params?.row?.isParentProduct === true)
      )
    },
    {
      field: 'createdAt',
      filterable: true,
      headerName: 'Created At',
      width: 210,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      renderCell: (params) => (
        colorizeRowData(moment(params?.row?.createdAt).format('MMMM Do YYYY, h:mm a'), params?.row?.isParentProduct === true)
      )
    },
    {
      field: 'updatedAt',
      filterable: true,
      headerName: 'Updated At',
      width: 210,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      renderCell: (params) => (
        colorizeRowData(moment(params?.row?.updatedAt).format('MMMM Do YYYY, h:mm a'), params?.row?.isParentProduct === true)
      )
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    document.getElementById("importForm").reset();
    document.getElementById("updateForm").reset();
  };

  const handleDeleteCustomer = () => {
    setLoading(true);
    axiosInstance
      .post('/product/deleteProductsInBulk/', {
        productsId: productId
      })
      .then((res) => {
        const arr = []
        setProductId(arr);
        const successMessage = "Products deleted successfully!"
        setSnackBar((prevSnackBar) => {
          return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
        });
        setReRender(!reRender);
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message || "Something went wrong";
        setSnackBar((prevSnackBar) => {
          return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
        });
      });
    setLoading(false);
  }

  const handleShowPopup = (event) => {
    event.preventDefault();
    setConfirmShowPopup(true)
  }

  const hideConfirmPopup = (event) => {
    if (event.target.innerText === 'Yes') {
      if (productId.length !== 0) {
        handleDeleteCustomer();
      }
    }
    setConfirmShowPopup(false)
  }

  const handleUpdateAvailableQty = async (event) => {
    setLoading(true);
    event.preventDefault();
    await axiosInstance
      .get(`/product/updateInventory`)
      .then((res) => {
        if (res.data.status === 'success') {
          const successMessage = "Inventory updated successfully!"
          setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
          });
          document.getElementById('errorPopup').style.display = 'none';
        }
        else {
          alert('Something went wrong')
        }
      });
    setLoading(false);
  }

  const handleUpdateIndexSortKey = async (event) => {
    setLoading(true);
    event.preventDefault();
    await axiosInstance
      .get(`/product/assignIndex`)
      .then((res) => {
        if (res.data.status === 'success') {
          const successMessage = "Index & Sort-key updated successfully!"
          setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
          });
          document.getElementById('errorPopup').style.display = 'none';
        }
        else {
          alert('Something went wrong')
        }
      });
    setLoading(false);

  }

  const handleSnackBarClose = () => {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: false }
    });
  };

  const columnVisibilityModel = {
    // mrp: false,
    // listingPrice: false,
    retailPrice: false,
    specialRetailPrice: false,
    interiorPrice: false,
    oemPrice: false,
    endUserPrice: false,
    partnerPrice: false,
    promoterPrice: false
  }

  return (
    <Fragment>
      <div id="color">
        <Snackbar open={snackBar?.display} autoHideDuration={2000} onClose={handleSnackBarClose}>
          <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
            {snackBar?.message}
          </Alert>
        </Snackbar>

        <div
          className="alert alert-primary p-2"
          data-file-upload-alert role="alert"
          style={{ "display": "none" }}
          id='updateNotification'
        >
          File Successfully Updated
        </div>

        <div 
          className={Classes.FileContainer} 
          style={{justifyContent:'end',marginTop:-25,marginBottom:10}}
        >
          <Permission service="products" permission="create">
            <div style={{marginRight:10,marginBottom:5}}>
              <Link to='/products/addProduct'>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AddIcon />}
                  style={{textTransform:'capitalize',padding:"4px 12px"}}
                  sx={{"& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:"16px"}}}
                >
                  Create
                </Button>
              </Link>
            </div>
          </Permission>
              
          <div style={{ display: 'flex', gap: '10px',marginRight:10,marginBottom:5 }}>
            <Permission service="products" permission="delete">
              <div >
                <Button
                  disabled={productId.length === 0 ? true : false}
                  variant='contained'
                  color='error'
                  id='deleteProduct'
                  startIcon={<DeleteIcon />}
                  onClick={handleShowPopup}
                  style={{textTransform:'capitalize',padding:"4px 12px"}}
                  sx={{"& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:"16px"}}}
                >
                  Delete
                </Button>
              </div>
            </Permission>
            <Permission service="products" permission="update">
              <Button
                variant='contained'
                color='secondary'
                onClick={handleClickOpen}
                style={{textTransform:'capitalize',padding:"4px 12px"}}
                sx={{"& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:"16px"}}}
                startIcon={<FileUploadIcon />}
              >
                Import / Update
              </Button>
            </Permission>
          </div>
        </div>

        <div>
          <Table
            columns={columns}
            dataPosition={"products"}
            endpoint={`/product?prices='allPrices'`}
            rowId={'variantId'}
            checkBoxSelection={true}
            version="version-5"
            service={'products'}
            tabName={'Products'}
            navigateOnRowClickEndpoint={`/products`}
            columnVisibilityModel={columnVisibilityModel}
            setData={setProducts}
            data={products}
            reRender={reRender}
            mobileResponsive={true}
            exportEndpoint={'/exports/products?prices=allPrices'}
            exportFileName={`Products - ${new Date()}`}
            delayedExport={true}
            redirectUrlOnSnackBarClick={'/dashboard/reportAnalytics'}
            setSelectedId={setProductId}
            searchKey='text'
            filterName='filters'
            clickable={true}
          />

          <div className={showConfirmPopup ? Classes.UpdateSalesDialog : undefined}>
            <ConfirmPopup
              showConfirmPopup={showConfirmPopup}
              hideConfirmPopup={hideConfirmPopup}
              productId={productId}
            />
          </div>
        </div>
        {loading && <Spinner />}

        <SettingsPopup
          open={open}
          handleClose={handleClose}
          Classes={Classes}
          setReRender={setReRender}
          reRender={reRender}
          // setFieldName={setFieldName}
          // setErrorFields={setErrorFields}
          handleUpdateAvailableQty={handleUpdateAvailableQty}
          handleUpdateIndexSortKey={handleUpdateIndexSortKey}
          dashboard='Products'
        />
      </div>
    </Fragment>
  )
};

export default Products;