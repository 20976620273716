import { useEffect, useState } from 'react';
import axiosInstance from '../../utility/axios-instance';
import SnackBar from '../SnackBar';
import Classes from '../mobileResponsive/style.module.css';
import Spinner from '../spinner';
import NewPagination from '../../components/newPagination';
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { createSvgIcon } from '@mui/material/utils';
import { downloadCSV } from '../../utility/downloadCSV';
import FilterListIcon from '@mui/icons-material/FilterList';
import DialogSlide from './mobileResponsiveFilter';

const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    'SaveAlt',
)

const SearchInput = ({ searchFor, handleSearch }) => {
    return (
        <div style={{ width: '100%' }}>
            <TextField
                id="search"
                type="search"
                label="Search"
                value={searchFor}
                autoFocus={true}
                onChange={(e) => handleSearch(e)}
                autoComplete='off'
                sx={{ width: '100%', "& label": { top: searchFor ? "0%" : "-18%" }, "& .MuiOutlinedInput-input": { padding: "8.5px 14px" } }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    )
}

const MobileResponsiveTable = (props) => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: true, type: "success", message: "default message!" })
    const [paginationData, setPaginationData] = useState(null);
    const [searchFor,setSearchFor] = useState("");
    const [open, setOpen] = useState(false);
    const [applyFilter,setApplyFilter] = useState(false);
    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);
    const { 
        dataPosition, endpoint, rowId, columns, clickable,tab, exportEndpoint, 
        exportFileName, initialFilterState, delayedExport,
        redirectUrlOnSnackBarClick
    } = props;
    const [multiFilters, setMultiFilters] = useState(initialFilterState?.items || [{
        field: '', operator: 'contains', value: ''
    }]);

    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleApplyFilter = () => {
        setApplyFilter(true);
        setOpen(false);
        fetchData();
    }
  
    const handleRemoveAllFilter = () => {
        multiFilters?.splice(0, multiFilters?.length);
        setMultiFilters([{ field: '', operator: 'contains', value: '' }])
        setApplyFilter(false);
        setOpen(false);
        fetchData();
    }

    const handleSearch = (event) => {
        setSearchFor(event.target.value);
    }

    const handleFilteredExport = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if(!delayedExport){
            setLoading(true);
        }
        let params = { searchFor, text: searchFor };

        let multiFiltersArray = multiFilters?.filter((item) => item?.value)
        let endDateData = endDate ? `${endDate.$y}-${endDate.$M + 1}-${endDate.$D}` : new Date().toJSON().slice(0,10).replace(/-/g,'-');
        if (multiFiltersArray?.some((item) => item.operator === 'between' && startDate && endDateData)) {
            let currentIndex = multiFiltersArray.findIndex((item) => item.operator === 'between');
            multiFiltersArray[currentIndex] = {
                ...multiFiltersArray[currentIndex],
                startDate: `${startDate.$y}-${startDate.$M + 1}-${startDate.$D}`,
                endDate: endDateData,
                value: ''
            }
        }

         if (multiFiltersArray?.[0]?.value === 0 && multiFiltersArray?.[0]?.field) {
            params.filter = multiFiltersArray
        }

        axiosInstance
            .get(exportEndpoint, {
                params
            })
            .then((result) => {
                const arrayOfRowObjects = result?.data?.data?.exportedData
                const responseMessage = result?.data?.message
                downloadCSV(arrayOfRowObjects, exportFileName || `Export - ${new Date()}`);
                const successMessage = responseMessage || "Exported Successfully!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false)
            });
        setSnackBar((prevSnackBar) => {
            return {
                ...prevSnackBar,
                display: true,
                message:
                `${exportFileName} Report is being generated. Click here to check it's status.`,
                type: "success",
                redirectUrl: redirectUrlOnSnackBarClick,
            };
        });
    };

    const fetchData = async (page) => {
        setLoading(true)
        let params = { searchFor, page, text: searchFor };

        let multiFiltersArray = multiFilters?.filter((item) => item?.value);
        let endDateData = endDate ? `${endDate.$y}-${endDate.$M + 1}-${endDate.$D}` : new Date().toJSON().slice(0,10).replace(/-/g,'-');
        if (multiFiltersArray?.some((item) => item.operator === 'between' && startDate && endDateData)) {
            let currentIndex = multiFiltersArray.findIndex((item) => item.operator === 'between');
            multiFiltersArray[currentIndex] = {
                ...multiFiltersArray[currentIndex],
                startDate: `${startDate.$y}-${startDate.$M + 1}-${startDate.$D}`,
                endDate: endDateData,
                value: ''
            }
        }

        params.filter = multiFiltersArray

        await axiosInstance
            .get(`${endpoint}`, {
                params
            })
            .then((res) => {
                const responseData = res?.data?.data;
                setData(responseData[dataPosition]);
                setPaginationData({
                    page: responseData?.page,
                    total: responseData?.total,
                    maxPage: responseData?.maxPage,
                })
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                console.log(errorMessage, 'error')
            })
            .finally(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        fetchData();
    },[searchFor]);

    const nestedFieldName = ['createdBy', 'preSalesUser', 'salesUser'];
    const dateColumns = ["created_at", "createdAt", "updatedAt", "updated_at"];

    const columnData = (row, RenderCell)=> {
        return (
            <RenderCell {...row}/>
        )
    }

    const handleAddFilter = () => {
        setMultiFilters([...multiFilters, {field: '', value: '', operator: 'contains'} ])
    }

    const handleRemoveFilter = (e, index) => {
        if (multiFilters?.length > 1) {
            const filters = multiFilters?.filter((item) => item?.value);
            const removeFilterColumn = multiFilters?.filter((item) => item?.field !== filters[index]?.field);
            setMultiFilters([...removeFilterColumn, {field: '', value: '', operator: 'contains'}]);
        }
    }

    return (
        <div>
            <div>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    {
                        exportEndpoint &&
                        <Button
                            color='primary'
                            size='small'
                            startIcon={<ExportIcon />}
                            onClick={(event) => handleFilteredExport(event)}
                            sx={{ marginBottom: '1em' }}
                        >
                            Filtered Export
                        </Button>
                    }
                    <Button
                        color='primary'
                        size='small'
                        sx={{ marginBottom: '1em' }}
                        onClick={handleClickOpen}
                    >
                        {applyFilter && (
                            <span 
                                style={{ 
                                    display:'flex', position:'absolute', height:20, zIndex:1,
                                    backgroundColor: "rgb(25, 118, 210)", color: "rgb(255, 255, 255)",
                                    top: 8, right: 0, transform: "scale(1) translate(50%, -50%)",
                                    transformOrigin: "100% 0%", padding: "0px 6px",
                                    borderRadius: 10, transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    fontSize: '12px', marginRight: 75
                                }}
                            >{multiFilters?.filter((item) => item?.value)?.length}</span>
                        )}
                        <FilterListIcon style={{ marginRight:'8px'}} /> Filter
                    </Button>
                </div>
                
                <SearchInput searchFor={searchFor} handleSearch={handleSearch} />

                {data?.length && data?.map((row) => (
                    <div key={row[rowId]} className={Classes.Box} onClick={() => clickable &&  navigate(`${tab}/${row[rowId]}`)}>
                        {columns?.length && columns?.map((column,index) => (
                            <div style={{display:'flex', marginBottom:5}} key={index}>
                                <p style={{marginRight:5,color:'#6c757d'}}>{column?.headerName}:</p>
                                <p style={{lineBreak:'anywhere'}}>
                                    {column?.customRenderCell ? columnData(row, column?.customRenderCell) :
                                    dateColumns.includes(column?.field) ?  
                                    moment(row[column?.field]).format('MMM DD, YYYY h:mm:ss A') : 
                                    column?.field === 'total' ? `₹${row[column?.field]}`: 
                                    row[column?.field] ? row[nestedFieldName.includes(column?.field)? 
                                    column?.field?.name : column?.field === 'services' ? 
                                    row[column?.field]?.map((service) => (service?.service)) : column?.field] : ''}
                                </p>
                            </div>
                        ))}
                    </div>
                ))}

                <div style={{ margin:'20px auto 0px',paddingBottom:20 }}>
                    <NewPagination
                        totalPage={paginationData}
                        fetchData={fetchData}
                    />
                </div>

                {loading && <Spinner />}

                <SnackBar open={snackBar}  setSnackBar={setSnackBar} />
            </div>

            <DialogSlide 
                open={open}
                handleRemoveAllFilter={handleRemoveAllFilter}
                columns={columns}
                multiFilters={multiFilters} 
                filters={multiFilters?.[multiFilters?.length - 1]}
                setMultiFilters={setMultiFilters}
                handleApplyFilter={handleApplyFilter}
                handleAddFilter={handleAddFilter}
                handleRemoveFilter={handleRemoveFilter}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
        </div>
    )
};

export default MobileResponsiveTable;