import React, { Fragment, useState, useEffect } from 'react';
import Table from '../../../components/table/table';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import axiosInstance from '../../../utility/axios-instance';
import PutAwayPopup from './putAwayPopup';
import QcPopup from './qcPopup';
import InboundPopup from './inboundPopup';
import SnackBar from '../../../components/SnackBar';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';
import MobileResponsive from './mobileResponsive';
import Classes from '../customers/css/customer.module.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setInwardReturns } from '../../../store/inwardReturns';
import { setInboundOrders } from '../../../store/inboundOrders';
import { setQCOrders } from '../../../store/qcOrders';
import { setPutAwayOrders } from '../../../store/putAwayOrders';

const ReturnInwards = ({type}) => {
    const serviceName = services?.inwardReturns;

    const InboundBtnRenderCell = (row) => {
        return (
            <Permission service={serviceName} permission={"create"}>
                <Button
                    variant="contained"
                    size='small'
                    color="primary"
                    onClick={() => handleClickOpen(row)}
                >Inbound</Button>
            </Permission>
        )
    }

    const columns = [
        {
            field: 'returnNumber',
            headerName: 'Return No.',
            flex: 1.4,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'orderName',
            headerName: 'Order No.',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'customerName',
            headerName: 'Customer',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1.6,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Inbound',
            headerName: 'Inbound',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            // flex: 1,
            sortable: false,
            renderCell: (params) => ( <InboundBtnRenderCell {...params?.row} />),
            customRenderCell: InboundBtnRenderCell
        },
    ];

    const QcBtnRenderCell = (row) => {
        return (
            <Permission service={serviceName} permission={"update"}>
                <Button
                    variant="contained"
                    size='small'
                    color="primary"
                    onClick={() => handleQcOpen(row)}
                >QC</Button>
            </Permission>
        )
    }

    const inboundColumns = [
        {
            field: 'inboundNumber',
            headerName: 'Inbound No.',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'orderName',
            headerName: 'Order No.',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'sku',
            headerName: 'SKU',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'qcStatus',
            headerName: 'QC Status',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'user',
            headerName: 'Created By',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1.6,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'QC',
            headerName: 'QC',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            // flex: 0.8,
            sortable: false,
            renderCell: (params) => ( <QcBtnRenderCell {...params?.row} />),
            customRenderCell: QcBtnRenderCell
        },
    ];

    const PutAwayBtnRenderCell = (row) => {
        return (
            <Permission service={serviceName} permission={"create"}>
                <Button
                    variant="contained"
                    size='small'
                    color="primary"
                    onClick={() => handlePutawayOpen(row)}
                >PutAway</Button>
            </Permission>
        )
    }

    const qcColumns = [
        {
            field: 'inboundNumber',
            headerName: 'Inbound No.',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'orderName',
            headerName: 'Order No.',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'sku',
            headerName: 'SKU',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'qcPass',
            headerName: 'Pass',
            type: 'number',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'qcFail',
            headerName: 'Fail',
            type: 'number',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'qcPerson',
            headerName: 'QC Person',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'qcStatus',
            headerName: 'QC Status',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'PutAway',
            headerName: 'PutAway',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            flex: 1,
            sortable: false,
            renderCell: (params) => ( <PutAwayBtnRenderCell {...params?.row} /> ),
            customRenderCell: PutAwayBtnRenderCell
        },

    ];

    const putAwayColumns = [
        {
            field: 'putawayId',
            headerName: 'Putaway Id',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'orderName',
            headerName: 'Order No.',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'sku',
            headerName: 'SKU',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            flex: 0.8,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'qcPass',
            headerName: 'Qc Pass Qty',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            filterable: true,
        },
        {
            field: 'passBinCode',
            headerName: 'Pass Bin Code',
            headerClassName: 'super-app-theme--header',
            flex: 1,
        },
        {
            field: 'qcFail',
            headerName: 'Qc Fail Qty',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            filterable: true,
        },
        {
            field: 'failBinCode',
            headerName: 'Fail Bin Code',
            headerClassName: 'super-app-theme--header',
            flex: 1,
        },
        {
            field: 'locationCode',
            headerName: 'Location',
            headerClassName: 'super-app-theme--header',
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
    ];

    const [types, setTypes] = useState(type ? type : 'new');
    const [alignment, setAlignment] = useState(type ? type : 'new');
    const [reRender, setReRender] = useState(false);
    const [open, setOpen] = useState(false);
    const [bins, setBins] = useState([]);
    const [rejectedBins, setRejectedBins] = useState([]);
    const [binsForInbound, setBinsForInbound] = useState([]);
    const [binId, setBinId] = useState(null);
    const [locations, setLocations] = useState([]);
    const [locationCode, setLocationCode] = useState(null);
    const [locationCodeForInbound, setLocationCodeForInbound] = useState(null);
    const [returnId, setReturnId] = useState(null);
    const [putAwayOrder,setPutAwayOrder] = useState(null);
    const [inboundDetails, setInboundDetails] = useState(null);
    const [rejectBinId, setRejectBinId] = useState(null);
    const [products, setProducts] = useState([]);
    const [inboundOpen, setInboundOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const inwardReturns = useSelector((state) => state.inwardReturns);
    const inboundOrders = useSelector((state) => state.inboundOrders);
    const qcOrder = useSelector((state) => state.qcOrder);
    const putAwayOrders = useSelector((state) => state.putAwayOrders);
    const navigate = useNavigate();


    const handleQcOpen = (details) => {
        setInboundDetails(details);
        setOpen(true);
    }

    const handleQcClose = () => {
        setInboundDetails(null);
        setReRender(!reRender);
        setOpen(false);
    }

    const handleClickOpen = async (returnDetail) => {
        setReturnId(returnDetail);
        setInboundOpen(true);
        setLocationCodeForInbound(returnDetail?.locationCode);
    };

    const handlePutawayOpen = async (returnDetail) => {
        setPutAwayOrder(returnDetail);
        setLocationCode(returnDetail?.locationCode);
        setOpen(true);
    };

    const handleClose = () => {
        setReturnId(null);
        setInboundOpen(false);
        setBinId(null);
        setRejectBinId(null);
        setLocationCodeForInbound(null);
        setReRender(!reRender);
        setOpen(false);
    };


    const handlePutawaySubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            binId: binId?.binId,
            locationCode: locationCode,
            rejectBinId: rejectBinId?.binId
        }
        await axiosInstance.post(`/pgOrder/admin/returnPutAwayMark/${putAwayOrder?.id}`, data)
            .then(res => {
                handleClose();
            })
            .catch(error => {
                console.log(error);
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        setLoading(false);
    }

    const fetchLineItemsFromReturnNumber = async (returnNumber) => {
        try {
            await axiosInstance.get(`/pgOrder/admin/returnsNew/${returnNumber}`)
                .then((res) => {
                    setProducts(res?.data?.data)
                })
                .catch((error) => {
                    console.log(error)
                    const errorMessage = error?.response?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                })

        } catch (err) {
            console.log(err);
        }
    }

    const handleChange = (event) => {
        setTypes(event.target.value);
        setAlignment(event.target.value);
        navigate(`/inwardReturns${event.target.value === 'new' ? '' : `/${event.target.value}`}`)
    };

    useEffect(() => {
        axiosInstance.get(`/location/locations?text=`)
            .then(res => {
                const locations = [];
                for (let location of res?.data?.data) {
                    locations.push(location?.locationCode)
                }
                setLocations(locations)
            })
            .catch(error => {
                console.log(error);
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }, [])


    useEffect(() => {
        locationCode && axiosInstance.get(`/inventory/bins?locationCode=${locationCode}&type=Saleable`)
            .then(res => {
                let bins = res?.data?.data?.bins;
                setBins(bins)
            })
            .catch(error => {
                console.log(error);
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })

            locationCode && axiosInstance.get(`/inventory/bins?locationCode=${locationCode}&type=Rejected`)
            .then(res => {
                let bins = res?.data?.data?.bins;
                setRejectedBins(bins)
            })
            .catch(error => {
                console.log(error);
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }, [locationCode])

    useEffect(() => {
        locationCodeForInbound && axiosInstance.get(`/inventory/bins?locationCode=${locationCodeForInbound}&type=Staging`)
            .then(res => {
                let bins = res?.data?.data?.bins;
                setBinsForInbound(bins.map((bin) => bin.code))
            })
            .catch(error => {
                console.log(error);
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }, [locationCodeForInbound])

    useEffect(() => {
        if (returnId) {
            fetchLineItemsFromReturnNumber(returnId?.returnNumber);
        }
    }, [returnId]);

    return (
        <Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0px' }}>
                <div style={{ backgroundColor:'#9e9e9e1f', borderRadius: 4, padding: 4 }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        sx={{
                            "& .MuiToggleButtonGroup-grouped": { border: '0px', color: 'black', textTransform: 'capitalize', padding: '2px 11px' },
                            "& .MuiToggleButton-root.Mui-selected": { backgroundColor: '#fff' }
                        }}
                    >
                        <ToggleButton value="new">New</ToggleButton>
                        <ToggleButton value="inbound">Inbound</ToggleButton>
                        <ToggleButton value="qc">Q.C</ToggleButton>
                        <ToggleButton value="putaway">Put Away</ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>

            {types === 'new' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={columns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsNew`}
                            rowId={'returnNumber'}
                            rowSelectionEndpoint={`/pgOrder/admin/createReturnInbound`}
                            version="version-1"
                            reRender={reRender}
                            initialFilterState={{
                                items: [
                                    {
                                        field: 'openQty', operator: '>', value: 0
                                    }
                                ]
                            }}
                            service={'returnOrder'}
                            type={types}
                            tabName={'Inward Returns'}
                            setData={setInwardReturns}
                            data={inwardReturns}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={columns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsNew`}
                            rowId={'returnNumber'}
                            handleClickOpen={handleClickOpen}
                            reRender={reRender}
                        />
                    </div>
                </div>
            )}

            {types === 'inbound' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={inboundColumns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsInbound?type=salesOrder`}
                            rowId={'id'}
                            version="version-1"
                            reRender={reRender}
                            service={'inwardReturns'}
                            type={types}
                            tabName={'Inward Returns'}
                            setData={setInboundOrders}
                            data={inboundOrders}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={inboundColumns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsInbound?type=salesOrder`}
                            rowId={'id'}
                            reRender={reRender}
                            handleClickOpen={handleQcOpen}
                        />
                    </div>
                </div>
            )}

            {types === 'qc' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={qcColumns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsQcMarked?type=salesOrder`}
                            rowId={'inboundId'}
                            reRender={reRender}
                            service={'inwardReturns'}
                            type={types}
                            tabName={'Inward Returns'}
                            setData={setQCOrders}
                            data={qcOrder}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={qcColumns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsQcMarked?type=salesOrder`}
                            rowId={'inboundId'}
                            reRender={reRender}
                            handleClickOpen={handlePutawayOpen}
                        />
                    </div>
                </div>
            )}
            
            {types === 'putaway' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table
                            columns={putAwayColumns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsPutAway`}
                            rowId={'putawayId'}
                            reRender={reRender}
                            service={'inwardReturns'}
                            type={types}
                            tabName={'Inward Returns'}
                            setData={setPutAwayOrders}
                            data={putAwayOrders}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={putAwayColumns}
                            dataPosition={"fetchedReturns"}
                            endpoint={`/pgOrder/admin/returnsPutAway`}
                            rowId={'putawayId'}
                            reRender={reRender}
                        />
                    </div>
                </div>
            )}

            {types === 'new' && (
                <InboundPopup
                    handleClose={handleClose}
                    open={inboundOpen}
                    products={products}
                    bins={binsForInbound}
                    locationCode={locationCodeForInbound}
                    loading={loading}
                    setLoading={setLoading}
                    url={"/pgOrder/admin/createReturnInbound"}
                    setSnackBar={setSnackBar}
                />
            )}

            {types === 'inbound' && (
                <QcPopup
                    open={open}
                    handleClose={handleQcClose}
                    inboundDetails={inboundDetails}
                    reRender={reRender}
                    setReRender={setReRender}
                    loading={loading}
                    setLoading={setLoading}
                    url={"/pgOrder/admin/returnQcMark"}
                />
            )}

            {types === 'qc' && (
                <PutAwayPopup
                    title={'Put Away'}
                    open={open}
                    handleClose={handleClose}
                    bins={bins}
                    locations={locations}
                    locationCode={locationCode}
                    setLocationCode={setLocationCode}
                    returnId={putAwayOrder}
                    binId={binId}
                    rejectBinId={rejectBinId}
                    setRejectBinId={setRejectBinId}
                    setBinId={setBinId}
                    handleSubmit={handlePutawaySubmit}
                    rejectBins={rejectedBins}
                    loading={loading}
                    setLoading={setLoading}
                />
            )}
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    )
}
export default ReturnInwards;