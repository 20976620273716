import React, { Fragment, useEffect, useState, forwardRef, useMemo } from 'react';
import Classes from '../product/css/product.module.css';
import axiosInstance from '../../../utility/axios-instance';
import { useSelector, useDispatch } from 'react-redux';
import { downloadCsvFromS3SignedUrl } from "../../../utility/downloadCSV";
import Permission from '../../auth/permissions';
import NewPagination from '../../../components/newPagination';
import Spinner from '../../../components/spinner';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Paper, Tooltip, Chip } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { setProducts } from '../../../store/productsSlice';
import { brandOnlyOperators, timestampOnlyOperators, SearchInput, b2cAndB2BOperators } 
from '../../../components/products/productComponents';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { 
  GridToolbarColumnsButton, GridToolbarContainer, 
  GridToolbarFilterButton,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';
import { TextField, Autocomplete, MenuItem } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SettingsPopup from '../product/settingsPopup';
import UpdateProductManagement from './updateProductManagement';
import { useSearchParams } from 'react-router-dom';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const L3sAutoComplete = ({l3s, setL3, l3, setPageNo}) => {
  return (
    <Autocomplete
      freeSolo
      disablePortal
      id='brands'
      options={l3s || []}
      size='small'
      ListboxProps={{sx: { fontSize: 14 }}}
      sx={{ 
        width: 180, 
        "& .MuiOutlinedInput-root": { paddingRight:"35px !important" }, 
        "& .MuiAutocomplete-input": {fontSize:'14px'},
        "& .MuiInputLabel-root": { fontSize: "14px" },
        "& .MuiInputBase-input": {textAlign:'start !important'}
      }}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionSelected ={(option, value) => option === value}
      key={l3 ? l3 : ''}
      defaultValue={l3 ? l3 : ''}
      onChange={(event, newValue) => {
        if (newValue === null) {
          setPageNo(1);
          setL3(null);
          return;
        }
        setL3(newValue);
        setPageNo(1);
      }}
      renderInput={(params) => (
        <TextField {...params} label='L3' />
      )}
    />
  );
}

const BrandAutoComplete = ({ brands, setBrand, brand, setPageNo }) => {
  return (
    <Autocomplete
      freeSolo
      disablePortal
      id='brands'
      options={brands || []}
      size='small'
      ListboxProps={{sx: { fontSize: 14 }}}
      key={brand ? brand : ''}
      defaultValue={brand ? brand : ''}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionSelected ={(option, value) => option === value}
      sx={{ 
        width: 180, 
        "& .MuiOutlinedInput-root": { paddingRight:"35px !important" }, 
        "& .MuiAutocomplete-input": {fontSize:'14px'},
        "& .MuiInputLabel-root": { fontSize: "14px" },
        "& .MuiInputBase-input": {textAlign:'start !important'}
      }}
      onChange={(event, newValue) => {
        if (newValue === null) {
          setPageNo(1);
          setBrand(null);
          return;
        }
        setPageNo(1);
        setBrand(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label='Brands'/>
      )}
    />
  );
}

const AutoComplete = ({franchises, setFranchise, franchise, setPageNo}) => {
  return (
    <Autocomplete
      freeSolo
      disablePortal
      id='franchises'
      options={franchises || []}
      size='small'
      ListboxProps={{sx: { fontSize: 14 }}}
      key={franchise ? franchise?.label : ''}
      defaultValue={franchise ? franchise?.label : ''}
      isOptionEqualToValue={(option, value) => option?.Id === value?.Id}
      getOptionSelected ={(option, value) => option?.Id === value?.Id}
      sx={{ 
        width: 180, 
        "& .MuiOutlinedInput-root": { paddingRight:"35px !important" }, 
        "& .MuiAutocomplete-input": { fontSize:"14px" },
        "& .MuiInputLabel-root": { fontSize: "14px" },
        "& .MuiInputBase-input": {textAlign:'start !important'}
      }}
      onChange={(event, newValue) => {
        if (newValue === null) {
          setPageNo(1);
          setFranchise(null);
          return;
        }
        setPageNo(1);
        setFranchise(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label='Franchises' />
      )}
    />
);
}

const CustomToolbar = ({ 
  searchFor, handleSearch,franchises, setFranchise, brands, setBrand, l3s, setL3, brand, l3, franchise, setPageNo 
}) => {
  return (
    <GridToolbarContainer style={{ paddingBottom: '4px' }}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <div
        className={Classes.SearchBox}
        style={{ border: 'none', marginLeft: 'auto',display:'flex' }}
      >
        <L3sAutoComplete l3s={l3s} setL3={setL3} l3={l3} setPageNo={setPageNo} />
        <BrandAutoComplete brands={brands} setBrand={setBrand} brand={brand} setPageNo={setPageNo} />
        <AutoComplete franchises={franchises} setFranchise={setFranchise} franchise={franchise} setPageNo={setPageNo} />
        <SearchInput searchFor={searchFor} handleSearch={handleSearch} width={200}/>
      </div>
    </GridToolbarContainer>
  );
}

const FranchiseProductManagement = (props) => {
  const [searchProduct, setSearchProduct] = useState('');
  const [pageNo,setPageNo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
  const initialFilterState={ items: [{ field: 'active', operator: '=', value: true }] }
  const [filters, setFilters] = useState(initialFilterState?.items || []);
  const [sort, setSort] = useState( { field: 'productName', sort: 'asc' });
  const [reRender,setReRender] = useState(false);
  const [franchises,setFranchises] = useState(null);
  const [franchise,setFranchise] = useState(null);
  const [brands, setBrands] = useState([]);
  const [brand,setBrand] = useState(null);
  const [l3s,setL3s] = useState([]);
  const [l3,setL3] = useState(null);

  const products = useSelector((state) => state.products);
  const pinTab = useSelector((state) => state.pinTab);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [open,setOpen] = useState(false);
  const [showErrorMsg, setErrorMsg] = useState('');
  const [skuName, setSkuName] = useState([]);
  const [errorFields, setErrorFields] = useState([]);
  const [fieldName, setFieldName] = useState([]);

  const [updateProduct,setUpdateProduct] = useState(false);
  const [updateProductDetails,setUpdateProductDetails] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleUpdateProduct = (e,product) => {
    setUpdateProductDetails([product])
    setUpdateProduct(true);
  }
  
  let columns = [
    ['super-admin', 'admin'].includes(auth.user.role) &&
    {
      field: 'edit', 
      filterable: false, 
      headerName: '',
      headerClassName: 'super-app-theme--header', 
      sortable: false,
      width:50,
      renderCell: (params) => (
        <EditIcon
          sx={{minWidth:'50px',color:'#243750'}}
          onClick={(e) => handleUpdateProduct(e,params?.row)}
        />
      )
    },
    {
      field: 'sku',
      filterable: true,
      headerName: 'SKU',
      width: 170,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.sku} placement="top">
          <p style={{ color: params?.row?.franchisePrices ? "#ff6200" : '#000' }}>
            {params?.row?.sku}
          </p>
        </Tooltip>
      )
    },
    {
      field: 'productName',
      filterable: true,
      headerName: 'PRODUCT',
      width: 280,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.productName} placement="top">
          <p style={{ color: params?.row?.franchisePrices ? "#ff6200" : '#000' }}>
            {params?.row?.productName}
          </p>
        </Tooltip>
      )
    },
    { 
      field: 'mrp',
      filterable: false,
      headerName: 'MRP',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center',
      type: 'number',
      sortable: true,
      renderCell: (params) => (
        <div style={{ color: params?.row?.franchisePrices ? "#ff6200" : '#000' }}>
          ₹{params?.row?.mrp}
        </div>
      )
    },
    {
      field: 'listingPrice',
      filterable: false,
      headerName: 'LISTING PRICE (NP)',
      width: 140,
      headerClassName: 'super-app-theme--header',
      align:'center',
      type: 'number',
      sortable: true,
      renderCell: (params) => (
        <div style={{ color: params?.row?.franchisePrices ? "#ff6200" : '#000' }}>
          ₹{params?.row?.listingPrice}
        </div>
      )
    },
    { 
      field: 'retailerPrice',
      filterable: false,
      headerName: 'RETAIL PRICE (NP)',
      width: 140,
      headerClassName: 'super-app-theme--header',
      align:'center',
      sortable: true,
      type: 'number',
      renderCell: (params) => (
        <div style={{ color: params?.row?.franchisePrices ? "#ff6200" : '#000' }}>
          {params?.row?.retailerPrice ? `₹${params?.row?.retailerPrice}` : "N/A"}
        </div>
      )
    },
    { 
      field: 'specialRetailerPrice',
      filterable: false,
      headerName: 'SPECIAL RETAIL PRICE (NP)',
      width: 140,
      headerClassName: 'super-app-theme--header',
      align:'center',
      sortable: true,
      type: 'number',
      renderCell: (params) => (
        <div style={{ color: params?.row?.franchisePrices ? "#ff6200" : '#000' }}>
          {params?.row?.specialRetailerPrice ? `₹${params?.row?.specialRetailerPrice}` : "N/A"}
        </div>
      )
    },
    {
      field: 'interiorPrice',
      filterable: false,
      headerName: 'INTERIOR PRICE (NP)',
      width: 140,
      headerClassName: 'super-app-theme--header',
      align:'center',
      sortable: true,
      type: 'number',
      renderCell: (params) => (
        <div style={{ color: params?.row?.franchisePrices ? "#ff6200" : '#000' }}>
          {params?.row?.interiorPrice ? `₹${params?.row?.interiorPrice}` : "N/A"}
        </div>
      )
    },
    {
      field: 'endUserPrice',
      filterable: false,
      headerName: 'END USER PRICE (NP)',
      width: 140,
      headerClassName: 'super-app-theme--header',
      align:'center',
      sortable: true,
      type: 'number',
      renderCell: (params) => (
        <div style={{ color: params?.row?.franchisePrices ? "#ff6200" : '#000' }}>
          {params?.row?.endUserPrice ? `₹${params?.row?.endUserPrice}` : "N/A"}
        </div>
      ),
    },
    {
      field: 'oemPrice',
      filterable: false,
      headerName: 'OEM PRICE (NP)',
      width: 140,
      headerClassName: 'super-app-theme--header',
      align:'center',
      sortable: true,
      type: 'number',
      renderCell: (params) => (
        <div style={{ color: params?.row?.franchisePrices ? "#ff6200" : '#000' }}>
          {params?.row?.oemPrice ? `₹${params?.row?.oemPrice}` : "N/A"}
        </div>
      )
    },
    {
      field: 'partnerPrice',
      filterable: false,
      headerName: 'PARTNER PRICE (NP)',
      width: 140,
      headerClassName: 'super-app-theme--header',
      align:'center',
      sortable: true,
      type: 'number',
      renderCell: (params) => (
        <div style={{ color: params?.row?.franchisePrices ? "#ff6200" : '#000' }}>
          {params?.row?.partnerPrice ? `₹${params?.row?.partnerPrice}` : "N/A"}
        </div>
      )
    },
    {
      field: 'promoterPrice',
      filterable: false,
      headerName: 'PROMOTER PRICE (NP)',
      width: 140,
      headerClassName: 'super-app-theme--header',
      align:'center',
      sortable: true,
      type: 'number',
      renderCell: (params) => (
        <div style={{ color: params?.row?.franchisePrices ? "#ff6200" : '#000' }}>
          {params?.row?.promoterPrice ? `₹${params?.row?.promoterPrice}` : "N/A"}
        </div>
      )
    },
    {
      field: 'franchises',
      headerName: 'Franchise',
      width: 200,
      padding: 5,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
          params?.row?.franchises?.length !== 0 && 
          <TextField
            id="skuBinQty"
            select
            renderValue={(p) => p}
            SelectProps={{ renderValue: (p) => p }}
            sx={{ 
              width: '100%', padding: '5px', 
              "& .MuiOutlinedInput-input": { padding: '7px', fontSize: 13,
              color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000' },
              "& .MuiInputBase-input": {textAlign:'start !important'} 
            }}
            key={franchise ? franchise?.label : params?.row?.franchises?.[0]}
            defaultValue={franchise ? franchise?.label : params?.row?.franchises?.[0]}
          >
            {params?.row?.franchises?.map((item, i) => (
              <MenuItem
                disabled={true}
                sx={{'&.Mui-disabled': { color: '#000000',opacity:1}}}
                style={{fontSize:13,padding:'4px 10px'}}
                key={item}
                defaultValue={item}
              >{item}</MenuItem>
            ))}
          </TextField>
      )
    },
    {
      field: 'isActiveB2C',
      filterable: false,
      headerName: 'Is Active B2C ?',
      width: 140,
      headerClassName: 'super-app-theme--header',
      align:'center',
      sortable: true,
      type: 'boolean'
    },
    {
      field: 'active',
      filterable: false,
      headerName: 'Active Location',
      width: 140,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      renderCell: (params) => (
        <div>
          {params?.row?.inventory?.length > 0 && params?.row?.inventory?.slice(0,2)?.map((inventory) => (
            <Chip label={inventory?.locationCode} size='small' style={{ marginRight: 5 }} />
          ))}
          {params?.row?.inventory?.length  > 2 ? (
            <Tooltip title={
              <div>
                {params?.row?.inventory?.slice(5)?.map((inventory, index) => (
                  <p>{inventory?.locationCode}, </p>
                ))}
              </div>
            } arrow>
              <Chip size="small" label={`+ ${params?.row?.inventory?.length - 5} `} style={{ cursor: "pointer", opacity:'0.8',height:'21px'}} />
            </Tooltip>
          ) : null}
        </div>
      )
    },
    {
      field: 'B2B',
      filterable: true,
      headerName: 'B2B',
      width: 80,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center',
      sortable: true,
      type: 'boolean'
    },
    {
      field: 'B2C',
      filterable: true,
      headerName: 'B2C',
      width: 80,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align:'center',
      sortable: true,
      type: 'boolean'
    },
    {
      field: 'size',
      filterable: true,
      headerName: 'SIZE',
      width: 140,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      type: 'string',
      renderCell: (params) => (
        <div>{params?.row?.size ? params?.row?.size : "N/A"}</div>
      )
    },
    {
      field: 'material',
      filterable: true,
      headerName: 'MATERIAL',
      width: 140,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      type: 'string',
      renderCell: (params) => (
        <div>{params?.row?.material ? params?.row?.material : "N/A"}</div>
      )
    },
    {
      field: 'finish',
      filterable: true,
      headerName: 'FINISH',
      width: 140,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      type: 'string',
      renderCell: (params) => (
        <div>{params?.row?.finish ? params?.row?.finish : "N/A"}</div>
      )
    },
    {
      field: 'weight',
      filterable: true,
      headerName: 'WEIGHT',
      width: 140,
      headerClassName: 'super-app-theme--header',
      sortable: true,
      type: 'string',
      renderCell: (params) => (
        <div>{params?.row?.weight ? params?.row?.weight : "N/A"}</div>
      )
    },
  ];

  const columnVisibilityModel = {
    size: false,
    material: false,
    finish: false,
    weight: false
  }
  
  const countPlaces = (num) => {
    const data = Math.round(num*100); 
    return Math.round(data/100)
  };

  document.addEventListener('mouseup', function (e) {
    let container = document.getElementById('modalOutline');
    if (container !== null) {
      if (!container.contains(e.target)) {
        container.style.border = '1px solid rgb(206, 212, 218)';
      }
    }
  });

  const handleExport = (event) => {
    event.preventDefault();
    setLoading(true);

    const filtersForQuery = [...filters]
    if (brand) {
      filtersForQuery.push({"field":"brandName","operator":"contains","id":19959,"value":brand})
    }
    if (l3) {
      filtersForQuery.push({"field":"l3","operator":"contains","id":19958,"value":l3})
    }
    if(filtersForQuery?.[0]?.field === 'active'){
      if(filtersForQuery?.[0]?.value === 'Active'){
        filtersForQuery[0].value = true
      }else if(filtersForQuery?.[0]?.value === 'Draft'){
        filtersForQuery[0].value = false
      }
    }

    if (filtersForQuery?.[0]?.field === 'B2B' || filtersForQuery?.[0]?.field === 'B2C') {
      filtersForQuery[0].value = filtersForQuery?.[0]?.value === 'Yes' ? true : filtersForQuery?.[0]?.value === 'No' ? false : '';
    }

    const params = {
      text: searchProduct,
      l3: l3,
      franchiseId: franchise ? franchise?.Id : '',
      sort: JSON.stringify(sort) || null,
      dashboard: franchise ? 'productManagement' : '',
      prices: 'allPrices',
      ...((filtersForQuery?.[0]?.value || filtersForQuery?.[0]?.value === false) && { 
        filters: JSON.stringify(filtersForQuery) || null 
      }),
    };
    axiosInstance
      .get(`/exports/productsManagement`,{
        params
      })
      .then((result) => {
        const signedUrl = result?.data?.signedUrlOfObject;
        downloadCsvFromS3SignedUrl(signedUrl, `Products export - ${new Date()}`);
        const successMessage = "Products exported successfully!"
        setSnackBar((prevSnackBar) => {
          return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error,"error")
        const errorMessage = error?.response?.data?.message
        setSnackBar((prevSnackBar) => {
          return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
        });
      })
  }

  const handleProduct = async (event) => {
    setPageNo(1);
    setSearchProduct(event?.target?.value);
  }

  const fetchProduct = async (type) => {
    setLoading(true);
    let filtersForQuery = [...filters]
    if (brand) {
      filtersForQuery.push({"field":"brandName","operator":"contains","id":19959,"value":brand})
    }
    if (l3) {
      filtersForQuery.push({"field":"l3","operator":"contains","id":19958,"value":l3})
    }
    if(filtersForQuery?.[0]?.field === 'active'){
      if(filtersForQuery?.[0]?.value === 'Active'){
        filtersForQuery[0].value = true
      }else if(filtersForQuery?.[0]?.value === 'Draft'){
        filtersForQuery[0].value = false
      }
    }

    if (filtersForQuery?.[0]?.field === 'B2B' || filtersForQuery?.[0]?.field === 'B2C') {
      filtersForQuery[0].value = filtersForQuery?.[0]?.value === 'Yes' ? true : filtersForQuery?.[0]?.value === 'No' ? false : '';
    }

    const params = {
      page: type,
      text: searchProduct,
      l3: l3,
      franchiseId: franchise ? franchise?.Id : '',
      sort: JSON.stringify(sort) || null,
      dashboard: franchise ? 'productManagement' : '',
      prices: 'allPrices',
      ...((filtersForQuery?.[0]?.value || filtersForQuery?.[0]?.value === false) && { 
        filters: JSON.stringify(filtersForQuery) || null 
      }),
    };

    await axiosInstance
      .get(`/product`, {
        params,
      })
      .then((res) => {
        setPageNo(null);
        dispatch(setProducts(res.data.data));
      });
    setLoading(false);
  };

  const handleSnackBarClose = () => {
    setSnackBar((prevSnackBar) => {
      return { ...prevSnackBar, display: false }
    });
  };

  useEffect(() => {
    // fetch Product
    if (!Object.keys(pinTab)?.some((key) => pinTab[key]?.name === 'Products List')) {
      searchParams.set("page", 1);
      setSearchParams(searchParams);
      const urlParams = new URLSearchParams(window.location.search);
      const page = urlParams.get('page');
      fetchProduct(pageNo ? pageNo : page ? page : 1);
    }
  }, [sort, filters, reRender, franchise, brand, l3, searchProduct]);

  columns =  useMemo(() =>
    columns.map((col) => {
      if (col?.field === 'brandName') {
        return {
          ...col,
          filterOperators: brandOnlyOperators,
        }
      } else if(col?.field === 'createdAt' || col?.field === 'updatedAt'){
        return {
          ...col,
          filterOperators: timestampOnlyOperators,
        }
      } else if(col?.field === 'B2B'){
        return {
          ...col,
          filterOperators: b2cAndB2BOperators,
        }
      } else if(col?.field === 'B2C'){
        return {
          ...col,
          filterOperators: b2cAndB2BOperators,
        }
      }
      return col;
    }),
    [],
  );

  useEffect(() => {
    const fetchFranchises = async () => {
      try {
        const response = await axiosInstance(`/pos/fetchAllFranchises?withId=${true}`);
        const franchises = await response?.data?.data;
        setFranchises(franchises);
      } catch (error) {
        console.error('Error fetching franchises:', error);
      }
    };

    fetchFranchises();
  }, []);

  useEffect(() => {
    const fetchBrands = async () => {
      const query = {
        tableName: "product_collections",
        columnName: "brand_name",
      }
      await axiosInstance
        .get(`/product/filtersForUI`, {
          params: query,
        })
        .then((res) => {
            setBrands(res?.data?.data?.enumValues);
        });
    };
    
    fetchBrands();
  }, []);

  useEffect(() => {
    const fetchL3s = async () => {
      try {
        const response = await axiosInstance(`/exports/l3sCsv`);
        let l3s = await response?.data?.data;
        l3s = l3s?.map((l3) => { return l3?.title });
        setL3s(l3s);
      } catch (error) {
        console.error('Error fetching l3s:', error);
      }
    };

    fetchL3s();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    document.getElementById("importForm").reset();
    document.getElementById("updateForm").reset();
  };

  const handleUpdateDialog = () => {
    setUpdateProduct(false);
  }

  return (
    <Fragment>
      <div id="color">
        <Snackbar open={snackBar?.display} autoHideDuration={2000} onClose={handleSnackBarClose}>
          <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
            {snackBar?.message}
          </Alert>
        </Snackbar>

        <div
          className="alert alert-primary p-2"
          data-file-upload-alert role="alert"
          style={{ "display": "none" }}
          id='updateNotification'
        >
          File Successfully Updated
        </div>

        <div id='errorPopup' className={Classes.showErrorMsg}>
          <div>
            <p>
              {showErrorMsg}
              {errorFields?.length !== 0 && errorFields?.length === 1 ? (
                <span style={{ padding: '0px 5px' }}>
                  {errorFields?.[0]}
                </span>
              ) : (
                <>
                  {errorFields?.length !== 0 && errorFields?.map((field, index) => (
                    <span key={index} style={{ padding: '0px 5px' }}>
                      {field},
                    </span>
                  ))}
                </>
              )}

              of {errorFields?.length !== 0 && skuName?.length !== 0 && (
                <span style={{ paddingRight: 5 }}>
                  {skuName.length === 1 ? "this" : "these"}
                </span>
              )} sku.
            </p>

            {fieldName?.length !== 0 && (
              <div style={{ display: 'flex', padding: 0 }}>
                {fieldName?.map((field, index) => (
                  <p style={{ marginRight: 10 }} key={index}>
                    &#x2022;{field}
                  </p>
                ))}
              </div>
            )}

            {skuName?.length !== 0 && skuName?.map((sku, index) => (
              <p key={index} style={{ fontSize: 13 }}>
                &#x2022; {sku}
              </p>
            ))}
          </div>
        </div>

        <div 
          className={Classes.FileContainer} 
          style={{justifyContent:'end',marginTop:-25,marginBottom:10}}
        > 
          <Permission service="products" permission="update">
            <Button
              variant='contained'
              color='secondary'
              onClick={handleClickOpen}
              style={{textTransform:'capitalize',padding:"4px 12px",marginRight:10}}
              sx={{"& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:"16px"}}}
              startIcon={<FileUploadIcon />}
            >
              Update
            </Button>
          </Permission>

          <Permission service="products" permission="read">
            <Button
              onClick={handleExport}
              variant='contained'
              color='success'
              style={{textTransform:'capitalize',padding:"4px 12px"}}
              sx={{"& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:"16px"}}}
              startIcon={<FileDownloadIcon />}
            >
              Export
            </Button>
          </Permission>
        </div>

        <div className={Classes.Container}>
          <div className={Classes.ProductContainer}>
            <div className="d-block d-md-none">
              {products?.products?.length !== 0 && products?.products?.map((product) => (
                <div
                  key={product?.variantId}
                  className={[Classes.Box, 'childNode'].join(' ')}
                >
                  <div
                    className={Classes.Boxes}
                    style={{ cursor:'pointer',display:'flex',width:'100%' }}
                  >
                    <div className={Classes.Name}>
                      <div style={{display:'flex',width:'94%'}}>
                        <div className={Classes.ProductImages} style={{margin:0,marginRight:5,width:'12%'}}>
                          <img
                            src={product?.image}
                            alt='icon'
                            style={{width:'100%',height:"100%"}}
                          />
                        </div>

                        <div style={{width:'80%',marginRight:5}}>
                          <p className={Classes.ProductHeadText}>
                            {product?.productName}
                          </p>

                          <div className={['d-md-none',Classes.ProductDetails].join(' ')}>
                            <p className='text-muted' style={{marginRight:5}}>SKU:</p>
                            <p className='text-muted'>{product?.sku}</p>
                          </div>

                          <div className={['d-md-none',Classes.ProductVendorText].join(" ")}>
                            <p className='text-muted' style={{marginRight:5}}>MRP:</p>
                            <p className='text-muted'>₹{product?.mrp}</p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>LISTING PRICE (NP):</p>
                            <p className='text-muted'>₹{product?.listingPrice ? countPlaces(product?.listingPrice * (1+(product?.gst/100))) : ''}</p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>RETAIL PRICE (NP):</p>
                            <p className='text-muted'>
                              ₹{product?.retailerPrice ? `₹${countPlaces(product?.retailerPrice * (1+(product?.gst/100)))}` : ''}
                            </p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>SPECIAL RETAIL PRICE (NP):</p>
                            <p className='text-muted'>
                              ₹{product?.specialRetailerPrice ? `₹${countPlaces(product?.specialRetailerPrice * (1+(product?.gst/100)))}` : ''}
                            </p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>INTERIOR PRICE (NP):</p>
                            <p className='text-muted'>
                              ₹{product?.specialRetailerPrice ? `₹${countPlaces(product?.specialRetailerPrice * (1+(product?.gst/100)))}` : ''}
                            </p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>END USER PRICE (NP):</p>
                            <p className='text-muted'>
                              {product?.endUserPrice ? `₹${countPlaces(product?.endUserPrice * (1+(product?.gst/100)))}` : ''}
                            </p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>OEM PRICE (NP):</p>
                            <p className='text-muted'>
                              {product?.oemPrice ? `₹${countPlaces(product?.oemPrice * (1+(product?.gst/100)))}` : ''}
                            </p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>PARTNER PRICE (NP):</p>
                            <p className='text-muted'>
                              {product?.partnerPrice ? `₹${countPlaces(product?.partnerPrice * (1+(product?.gst/100)))}` : ''}
                            </p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>PROMOTER PRICE (NP):</p>
                            <p className='text-muted'>
                              {product?.promoterPrice ? `₹${countPlaces(product?.promoterPrice * (1+(product?.gst/100)))}` : ''}
                            </p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>FRANCHISE:</p>
                            <p className='text-muted'>
                              {product?.franchises?.length !== 0 && 
                                <TextField
                                  id="skuBinQty"
                                  select
                                  renderValue={(p) => p}
                                  SelectProps={{ renderValue: (p) => p }}
                                  sx={{ 
                                    width: '100%', padding: '5px', 
                                    "& .MuiOutlinedInput-input": { padding: '7px', fontSize: 13,
                                    color: product?.createdVia === 'POS'? "#ff6200" : '#000' },
                                    "& .MuiInputBase-input": {textAlign:'start !important'} 
                                  }}
                                  key={franchise ? franchise?.label : product?.franchises?.[0]}
                                  defaultValue={franchise ? franchise?.label : product?.franchises?.[0]}
                                >
                                  {product?.franchises?.map((item, i) => (
                                    <MenuItem
                                      disabled={true}
                                      sx={{'&.Mui-disabled': { color: '#000000',opacity:1}}}
                                      style={{fontSize:13,padding:'4px 10px'}}
                                      key={item}
                                      defaultValue={item}
                                    >{item}</MenuItem>
                                  ))}
                               </TextField>
                              }
                            </p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>IS ACTIVE B2C:</p>
                            <p className='text-muted'>{product?.isActiveB2C ? 'Yes' : 'No'}</p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>B2B:</p>
                            <p className='text-muted'>{product?.B2B ? 'Yes' : 'No'}</p>
                          </div>

                          <div className={Classes.isActiveDiv}>
                            <p className='text-muted' style={{marginRight:5}}>B2C:</p>
                            <p className='text-muted'>{product?.B2C ? 'Yes' : 'No'}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="d-none d-sm-block" style={{ display: 'flex', justifyContent: "center", width: '100%', alignItems: "center" }} >
              <Box 
                className="shadow"
                component={Paper}
                sx={{
                  '& .super-app-theme--header': {
                    backgroundColor: '#243750',
                    color: '#ffffff', 
                    cursor: "default",
                  },
                }}
              >
                <DataGrid
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    toolbar: {
                      searchFor: searchProduct,
                      handleSearch: handleProduct,
                      franchises: franchises,
                      setFranchise: setFranchise,
                      brands: brands,
                      brand: brand,
                      setBrand: setBrand,
                      l3: l3,
                      l3s: l3s,
                      setL3: setL3,
                      franchise: franchise,
                      setPageNo
                    }
                  }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                    },
                  }}
                  rows={
                    products?.products?.map((product) => ({
                      edit: product?.edit,
                      productName: product?.productName,
                      id: product?.productId,
                      sku: product?.sku,
                      mrp: product?.mrp,
                      listingPrice: product?.listingPrice ? countPlaces(product?.listingPrice * (1+(product?.gst/100))) : '',
                      retailerPrice: product?.retailerPrice ? countPlaces(product?.retailerPrice * (1+(product?.gst/100))) : '',
                      specialRetailerPrice: product?.specialRetailerPrice ? countPlaces(product?.specialRetailerPrice * (1+(product?.gst/100))) : '',
                      interiorPrice: product?.interiorPrice ? countPlaces(product?.interiorPrice * (1+(product?.gst/100))) : '',
                      endUserPrice: product?.endUserPrice ? countPlaces(product?.endUserPrice * (1+(product?.gst/100))) : '',
                      oemPrice: product?.oemPrice ? countPlaces(product?.oemPrice * (1+(product?.gst/100))) : '',
                      partnerPrice: product?.partnerPrice ? countPlaces(product?.partnerPrice * (1+(product?.gst/100))) : '',
                      promoterPrice: product?.promoterPrice ? countPlaces(product?.promoterPrice * (1+(product?.gst/100))) : '',
                      inventory: product?.inventory,
                      size: product?.size,
                      finish: product?.finish,
                      material: product?.material,
                      weight: product?.weight,
                      isActiveB2C: product?.isActiveB2C ? product?.isActiveB2C: false,
                      B2B: product?.B2B ? product?.B2B : false,
                      B2C: product?.B2C ? product?.B2C : false,
                      gst: product?.gst,
                      franchises: product?.franchises,
                      franchisePrices: product?.franchisePrices,
                      multiPriceData: product?.multiPriceData
                    }))
                  }
                  columns={columns}
                  filterMode="server"
                  sortingMode="server"
                  paginationMode="server"
                  disableRowSelectionOnClick
                  autoHeight
                  autoPageSize
                  onFilterModelChange={(filter) => (setFilters(filter?.items))}
                  sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "& .MuiDataGrid-columnHeaderTitle" : {
                      fontSize:"12px"
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:first-child": {
                      position: "sticky",
                      left: 0,
                      zIndex: 2,
                      backgroundColor:'#fff'
                    },
                    ".PrivateSwitchBase-input": {
                      height: "23px",
                      margin: "10px 13px",
                      width: "20px"
                    },
                    cursor: "pointer",
                    ".MuiDataGrid-footerContainer": {
                      display: "none",
                    },
                    "& .MuiInputBase-input": {textAlign:'center !important'}
                  }}
                  initialState={{
                    columns: { columnVisibilityModel },
                    sorting: {
                      sortModel: [{ field: 'productName', sort: 'asc' }],
                    },
                    // pinnedColumns: { left: ['edit'] },
                  }}
                  onSortModelChange={(sort) => setSort(sort[0])}
                />
              </Box> 
            </div>
          </div>
        </div>
        {loading && <Spinner />}

        <div style={{ margin: '10px auto 0px' }} >
          {products?.products?.length !== 0 ? (
            <nav aria-label="Page navigation">
              <NewPagination totalPage={products} fetchData={fetchProduct} />
            </nav>
          ) : null}
        </div>

        <SettingsPopup
          open={open}
          handleClose={handleClose}
          Classes={Classes}
          fetchProduct={fetchProduct}
          setFieldName={setFieldName}
          setSkuName={setSkuName}
          setErrorMsg={setErrorMsg}
          setErrorFields={setErrorFields}
          dashboard='ProductManagements'
          routes={'/updatePricing/downloadProductMngTemplate'}
        />

        <UpdateProductManagement
          open={updateProduct}
          handleClose={handleUpdateDialog}
          // editMinMaxQtyProducts={editMinMaxQtyProducts}
          franchiseData={franchise}
          productData={updateProductDetails}
          loading={loading}
          setLoading={setLoading}
          reRender={reRender}
          setReRender={setReRender}
        />
      </div>
    </Fragment>
  )
};

export default FranchiseProductManagement;