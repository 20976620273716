import React, { Fragment, useEffect,useState } from "react";
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from "../../../utility/axios-instance";
import Spinner from '../../../components/spinner';
import SnackBar from '../../../components/SnackBar';
import { Autocomplete, TextField } from '@mui/material';

const QCOrder = (props) => {
    const [loading,setLoading] = useState(false);
    const [user,setUser] = useState('');
    const [users,setUsers] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const {
        qc,
        openQC,
        handleQCClose,
        setReRender
    } = props;


    useEffect(() => {
        const query = {
            type: "qcPerson",
            service: "qcPerson",
            locationCode: qc[0]?.locationCode
        };

        axiosInstance
            .get(`/admin/allPiAdmin`, { params: query })
            .then((res) => {
            setUsers(res?.data?.data);
            });
    }, [qc]);

    const handlePackedOrders = (event) => {
        event.preventDefault();
        setLoading(true);

        const data = {
            "packOrderDetails" :[
                {
                    "picklist_number":qc?.length !== 0 && qc[0]?.pickListNo,
                    "delivery_number":qc?.length !== 0 && qc[0]?.deliveryNo,
                    "qc_person": event.target.qcPerson.value
                }
            ]
        }

        // setReRender(false);
        axiosInstance
            .put('/picklist/addPerson', {...data})
            .then((res) => {
                setLoading(false);
                setReRender((prev) => !prev);
                const successMessage = `Order QC Successfully`
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                handleQCClose()
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setReRender((prev) => !prev);
                setLoading(false);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    return (
        <Fragment>
            {openQC && (
                <div
                    className={Classes.confirmModal}
                    style={{ bottom: '20%',top:'24%',boxShadow:'0 0px 9px 0' }}
                >
                    <form method='post' onSubmit={handlePackedOrders}>
                        <div style={{padding:20}}>
                            <Autocomplete
                                id="qcPerson"
                                name="qcPerson"
                                disablePortal
                                options={users ? users : [{name:"Loading...",id:0}]}
                                sx={{width: '100%',"& label": {top: user? "0%":"-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                getOptionLabel={(user) => user.name}
                                ListboxProps={{sx: { fontSize: 14 }}}
                                onChange={(event, newValue) => { setUser(newValue?.name) }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="QC Person"
                                        variant="outlined"
                                        required
                                    />
                                )}
                            />
                        </div>

                        <div className={Classes.ConfirmBtnBox} style={{bottom:'18%'}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={handleQCClose}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='addCustomer' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                GENERATE QC
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {loading ? <Spinner /> : null}
            
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

        </Fragment>
    );
}


export default QCOrder;