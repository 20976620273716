
import React, { Fragment, useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '../../../components/table/table';
import FormDialog from '../../../components/FormDialog/FormDialog';
import { Grid, IconButton, TextareaAutosize, Tooltip } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Cancel, FileCopy } from '@mui/icons-material';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import { createdAt } from '../../../utility/textFormatting';
import { useSelector } from 'react-redux';
import { setPushNotifications } from '../../../store/pushNotificationsSlice';

const PushNotifications = () => {
  const [reRender, setReRender] = useState(false);
  const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
  const [formData, setFormData] = useState({
    push_topic: '',
    push_title: '',
    push_message: '',
    push_image_url: '',
    push_call_to_action_url: '',
    filter: '',
    push_type: 'instant',
    push_time: '',
  });

  const pushNotifications = useSelector((state) => state.pushNotifications);
  const [openDialog, setOpenDialog] = useState(false);

  const PushSentTimeRenderCell = (row) => {
    return row?.push_sent_time ? createdAt(new Date(row?.push_sent_time)) : null
  }

  const PushTimeRenderCell = (row) => {
    return row?.push_time ? createdAt(new Date(row?.push_time)) : null
  }

  const ActionRenderCell = (row) => {
    const id = row?.id;
    const isScheduledUnsent = (row?.push_type === 'scheduled' && row?.push_status === 'scheduled');
    const isCancelled = (row?.push_status === 'cancelled')
    return (
      <div>
        {isScheduledUnsent ? (
          <Tooltip title="Cancel push notification">
            <span>
              <IconButton onClick={() => {
                axiosInstance
                  .put(`pushNotifications/cancel/${id}`)
                  .then((res) => {
                    const successMessage = res?.data?.message
                    setSnackBar((prevSnackBar) => {
                      return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                  }).catch((error) => {
                    const errorMessage = error?.response?.data?.message
                    setSnackBar((prevSnackBar) => {
                      return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                  }).finally(() => {
                    setReRender((prevReRender) => !prevReRender);
                  });
              }} size="small">
                <Cancel />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          isCancelled ?
            (
              <Tooltip title="Already Cancelled">
                <span>
                  <IconButton disabled size="small">
                    <Cancel />
                  </IconButton>
                </span>
              </Tooltip>
            )
            : (
              <Tooltip title="Only scheduled notification can be cancelled">
                <span>
                  <IconButton disabled size="small">
                    <Cancel />
                  </IconButton>
                </span>
              </Tooltip>
            )
        )}

        <Tooltip title="Duplicate push notification">
          <span>
            <IconButton 
              onClick={() => {
                setOpenDialog(true);
                setFormData({
                  push_topic: row?.push_topic || '',
                  push_title: row?.push_title || '',
                  push_message: row?.push_message || '',
                  push_image_url: row?.push_image_url || '',
                  push_call_to_action_url: row?.push_call_to_action_url || '',
                  filter: row?.filter || '',
                  push_type: row?.push_type || '',
                  push_time: '',
                });
              }} 
              size="small"
            >
              <FileCopy />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'push_topic',
      headerName: 'Topic',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'push_title',
      headerName: 'Title',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'push_message',
      headerName: 'Message',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'push_type',
      headerName: 'Type',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'push_sent_count',
      headerName: 'Sent Count',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'push_sent_time',
      headerName: 'Sent Time',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => ( <PushSentTimeRenderCell {...params?.row} /> ),
      customRenderCell: PushSentTimeRenderCell
    },
    {
      field: 'push_time',
      headerName: 'Scheduled Time',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => ( <PushTimeRenderCell {...params?.row} /> ),
      customRenderCell: PushTimeRenderCell
    },
    {
      field: 'push_sent_to',
      headerName: 'Sent To',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'push_sent_to_count',
      headerName: 'Sent To Count',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'push_status',
      headerName: 'Status',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'push_image_url',
      headerName: 'Image Url',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'push_call_to_action_url',
      headerName: 'CTA URL',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'filter',
      headerName: 'Filter',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'user_type',
      headerName: 'User Type',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'cancelled_by',
      headerName: 'Cancelled By',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'cancelled_at',
      headerName: 'Cancelled At',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'created_by',
      headerName: 'Created By',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    {
      headerName: 'Actions',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      filterable: false,
      sortable: false,
      renderCell: (params) => ( <ActionRenderCell {...params?.row} />),
      customRenderCell: ActionRenderCell
    }
  ];

  return (
    <Fragment>
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
      <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginBottom: '10px' }}>
        <FormDialog
          dialogTitle={"New Push Notification"}
          openDialogButtonName={"Add Push Notification"}
          submitDialogButtonName={"Submit"}
          dialogActionType={"create"}
          endPoint={`/pushNotifications/create`}
          formData={{ ...formData }}
          setReRender={setReRender}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        >
          <Grid container spacing={1.5} marginTop={'0.2em'}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Push Topic"
                value={formData?.push_topic || ''}
                onChange={(event) => setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    push_topic: event?.target?.value
                  }
                })}
                fullWidth
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Push Title"
                value={formData?.push_title || ''}
                onChange={(event) => setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    push_title: event?.target?.value
                  }
                })}
                fullWidth
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextareaAutosize
                aria-label="Push Message"
                placeholder="Push Message"
                value={formData?.push_message || ''}
                onChange={(event) => setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    push_message: event?.target?.value
                  }
                })}
                rowsMin={3}
                style={{ width: '100%', borderRadius: '4px', height: '100%', padding: '10px'}}
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Push Image URL"
                value={formData?.push_image_url || ''}
                onChange={(event) => setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    push_image_url: event?.target?.value
                  }
                })}
                fullWidth
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Push CTA URL"
                value={formData?.push_call_to_action_url || ''}
                onChange={(event) => setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    push_call_to_action_url: event?.target?.value
                  }
                })}
                fullWidth
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="filter-label">Filter</InputLabel>
                <Select
                  labelId="filter-label"
                  id="filter"
                  value={formData?.filter || ''}
                  onChange={(event) => setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      filter: event?.target?.value
                    }
                  })}
                  size='small'
                  label="Filter"
                >
                  <MenuItem value={JSON.stringify({ "customerRole": "retailer" })}>Retailer</MenuItem>
                  <MenuItem value={JSON.stringify({ "firstName": "Aman" })}>Aman</MenuItem>
                  <MenuItem value={JSON.stringify({ 'goAffProAccessToken': { '$exists': true } })}>Affiliate</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="push-type-label">Push Type</InputLabel>
                <Select
                  labelId="push-type-label"
                  id="push-type"
                  value={formData?.push_type || ''}
                  onChange={(event) => setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      push_type: event?.target?.value
                    }
                  })}
                  size='small'
                  label="Push Type"
                >
                  <MenuItem value="instant">Instant</MenuItem>
                  <MenuItem value="scheduled">Scheduled</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formData?.push_type === "scheduled" && <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Scheduled Time"
                  inputVariant="outlined"
                  value={formData?.push_time || null}
                  onChange={(date) => setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      push_time: date
                    }
                  })}
                  fullWidth
                  size='small'
                  sx={{ '& .MuiOutlinedInput-input': { paddingTop: '8.5px', paddingBottom: '8.5px' }, width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>}
          </Grid>
        </FormDialog>
      </div>
      <Table
        columns={columns}
        dataPosition={"fetchedPushNotifications"}
        endpoint={`/pushNotifications/readAll`}
        rowId={'id'}
        version="version-2"
        reRender={reRender}
        service={'returnOrder'}
        columnVisibilityModel={{
          updated_at: false,
          cancelled_at: false,
          id: false,
          cancelled_by: false,
          push_image_url: false,
          push_call_to_action_url: false,
          push_sent_to: false,
          push_sent_to_count: false,
          filter: false,
          user_type: false,
          push_sent_count: false,
          push_time: false,
        }}
        tabName={'Push Notifications'}
        setData={setPushNotifications}
        data={pushNotifications}
        mobileResponsive={true}
      />
    </Fragment>
  );
};

export default PushNotifications;
