import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Classes from "./realTimeLocation.module.scss";
import axiosInstance from "../../../utility/axios-instance";

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import socket from '../../../socket';

const RealtimeLocation = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null)
  const [su, setSU] = useState(user);

  const [text, setText] = useState("");
  const inputRef = useRef();
  const inputRef2 = useRef();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);


  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  // eslint-disable-next-line no-unused-vars
  const [pagination, setPagination] = useState({
    page: 1,
    maxPage: 1,
    total: 0,
  });

  useEffect(() => {
    socket?.on("location received", (data) => {
      console.log("location received");

      if (data?.user && data?.user._id === su?._id) {
        setData(data);
      }
    });
  }, []);

  const map = useRef(null);
  const marker = useRef(null);
  const marker2 = useRef(null);

  const im = async () => {
    map.current = new window.mappls.Map("map", {
      center: { lat: 28.612964, lng: 77.229463 },
    });

    marker.current = new window.mappls.Marker({
      map: map.current,
      position: { lat: 0, lng: 0 },
    });
  };

  useEffect(() => {
    im();
  }, []);

  useEffect(() => {
    if (data && map.current && marker.current) {
      map.current.setCenter({
        lat: data.location.latitude,
        lng: data.location.longitude,
      });

      marker.current.setPosition({
        lat: data.location.latitude,
        lng: data.location.longitude,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useState(() => {
    socket?.emit("setup", su);

    if (map.current && marker.current) {
      map.current.setCenter({ lat: 28.612964, lng: 77.229463 });

      marker.current.setPosition({ lat: 0, lng: 0 });
    }
  }, [su]);

  const fetchAdmins = (page = 1, text = "", pagination = false) => {
    setLoading(true);

    axiosInstance
      .get(`/admin?page=${page}&text=${text}`)
      .then((res) => {
        // console.log(res.data.data);
        if (pagination) {
          setAdmins((prev) => [...prev, ...res.data.data.users]);
          setPagination({
            page: res.data.data.page,
            maxPage: res.data.data.maxPage,
            total: res.data.data.total,
          });
        } else {
          setAdmins(res.data.data.users);
          setPagination({
            page: res.data.data.page,
            maxPage: res.data.data.maxPage,
            total: res.data.data.total,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const timer = useRef(null);
  useEffect(() => {
    clearTimeout(timer?.current);
    timer.current = setTimeout(() => {
      fetchAdmins(1, text);
    }, 100);
  }, [text]);

  const getData2 = async () => {
    axiosInstance.get(`/geoLocation?startDate=${startDate}&endDate=${endDate}`, data).then(res => {
      setData2(res.data.data);
    }).catch(err => {});
  }

  useEffect(() => {
    getData2();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [su, startDate, endDate]);

  const mm = () => {
    marker2.current = [];
    if(!map.current || !data2) return;

    let j = 1;
    let pg;
    for (let i = 0; i < data2.length; i++) {
      let geoLocation = data2[i].geoLocation;

      if (i === 0) {
        pg = data2[i].geoLocation;
      }

      if (i > 0 ) {

        if (pg) {
          let lad = geoLocation.latitude - pg.latitude;
        let lod = geoLocation.longitude - pg.longitude;

        if (lad < 0) {
          lad = -1 * lad;
        }

        if (lod < 0) {
          lod = -1 * lod;
        }

        if (lad < 0.1 || lod < 0.1) {
          continue;
        }

       pg = data2[i].geoLocation;
        }
      }

      let m = new window.mappls.Marker({
      map: map.current,
      position: { lat: geoLocation.latitude, lng: geoLocation.longitude },
    });

    let img = `https://dev-depo24.s3.ap-south-1.amazonaws.com/markers/output_image_${j}.png`

    m.setIcon(img);
    marker2.current.push(m);

    j++;
    }
  }

  useEffect(() => {
    mm()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data2])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: "0.5rem",
        height: "calc(100% - 25.25px)",
        position: "relative",
      }}
    >
      <div
        style={{ display:"flex", alignItems:"center", justifyContent:"space-between", gap:"1rem", flexWrap:'wrap' }}
      >
        <div style={{ display:"flex", flexDirection:'column', alignItems:"start", gap:"1rem" }}>
          <DatePicker 
            label="Start Date" 
            onChange={(newValue) => { setStartDate(`${newValue.$y}-${newValue.$M}-${newValue.$D}`) }} 
          />

          <DatePicker 
            label="End Date" 
            onChange={(newValue) => { setEndDate(`${newValue.$y}-${newValue.$M}-${newValue.$D}`) }} 
          />
        </div>

        <div style={{ display:"flex", alignItems:"stretch", gap:"1rem", flexWrap:'wrap' }}>
          <div>
            {su ? (
              <div className={Classes.Box} style={{ minWidth:220 }}>
                <h6 style={{ fontSize: 20 }}>{su?.username}</h6>
                <p>{su.name}</p>
                <p>{su.role}</p>
              </div>
            ) : null}
          </div>

          <div className={[Classes.Box].join(" ")}>
            <h6>Select Admin</h6>

            <div
              className={Classes.TextSearch}
              onClick={() => { setShowModal(true) }}
            >
              <FontAwesomeIcon icon='fa-solid fa-magnifying-glass' />
              <div>
                <input
                  readOnly
                  ref={inputRef}
                  type='text'
                  placeholder='Search Affiliate'
                  value={text}
                  onChange={(e) => { setText(e.target.value) }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='map' style={{ width:"100%", height:"100%", flexGrow:"1" }}></div>

      {showModal && (
        <div className={Classes.Modal}>
          <div className={Classes.Head}>
            <h5>All Admins</h5>
            <FontAwesomeIcon
              icon='fa-solid fa-xmark'
              size='xl'
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowModal(false);
              }}
            />
          </div>

          <div
            className={`${Classes.TextSearch} m-2`}
            onClick={() => { inputRef2?.current?.focus() }}
          >
            <FontAwesomeIcon icon='fa-solid fa-magnifying-glass' />
            <div>
              <input
                ref={inputRef2}
                type='text'
                placeholder='Search Affiliate'
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </div>
          </div>

          <div
            className={Classes.Results}
            style={{ height: "calc(100% - 2rem - 32px - 36px)" }}
          >
            {admins?.map((adm) => {
              return (
                <div
                  key={adm?._id}
                  className='border-bottom px-4 py-2'
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSU(adm);
                    setShowModal(false);
                  }}
                >
                  <div className='d-flex align-items-center justify-content-between'>
                    <div
                      className='d-flex align-items-center'
                      style={{ gap: "1rem" }}
                    >
                      <h6 className='mb-0'>{adm.username}</h6>
                    </div>
                  </div>

                  <div className='mt-2' style={{ fontSize: "14px" }}>
                    <p>{adm.name}</p>
                    <p>{adm.role}</p>
                  </div>
                </div>
              );
            })}

            {loading && <p className='my-1'>Loading ...</p>}
          </div>
        </div>
      )}
    </div>
    </LocalizationProvider>
  );
};

export default RealtimeLocation;