import { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Classes from './css/draftOrderDetailPage.module.css';
import Aside from './aside';
import axiosInstance from '../../../utility/axios-instance';
import TaxRatesPopup from '../ordersNew/TaxRates_popup';
import ConfirmPopup from '../createOrders/confirmPopup';
import DeleteOrderConfirmPopup from './deleteOrderPopup';
import { Spinner } from 'react-bootstrap';
import Permission from '../../auth/permissions';
import { services } from '../../../utility/checkRoleBasedPermission';
import moment from 'moment';
import CapsuleChip from '../../../components/CapsuleChip';
import SplitButton from '../../../components/SplitButton';
import SnackBar from '../../../components/SnackBar';
import socket from '../../../socket';
import ChildProductsList from '../createOrders/childProductsList';
import { roundOfUptoXDecimal } from '../../../utility/calculation';
import { downloadCSV } from '../../../utility/downloadCSV';
import { BiArrowBack } from "react-icons/bi";

const DraftOrderDetailPageNew = (props) => {
	const [error, setError] = useState(null);
	const [showTaxRate, setTaxRates] = useState(false);
	const [draftOrder, setDraftOrder] = useState([]);
	const [totalItems, setTotalItems] = useState(0);

	// store deliveryRemark & paymentRemark in State
	const [paymentRemark, setPaymentRemark] = useState('');
	const [deliveryRemark, setDeliveryRemark] = useState('');

	// sendExistOrder
	const [updateToggle, setUpdateToggle] = useState(true);
	const [completeExistOrder, setCompleteExistOrder] = useState([]);
	const [showConfirmPopup, setConfirmShowPopup] = useState(false);
	const [showDeleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
	const [loading, setLoading] = useState(false);
	const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

	const navigate = useNavigate();

	const { id } = useParams();

	// handle Discount
	const handleDiscount = (mrp, discountedPrice, value) => {
		const MRP = parseFloat(mrp);
		const DP = parseFloat(discountedPrice);
		const GST = parseInt(value);
		const result = (MRP - (DP * (1 + GST / 100))) / MRP;
		const TotalDiscount = result * 100;
		let countDecimal = countPlaces(TotalDiscount);
		let results = countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? TotalDiscount : Math.trunc(TotalDiscount * 100) / 100;
		return results;
	};


	const countPlaces = (num) => {
		var sep = String(23.32).match(/\D/)[0];
		var b = String(num).split(sep);
		return b[1] ? b[1].length : 0;
	}

	const handleTaxRates = () => {
		setTaxRates(true);
	};

	const hideTaxPopup = () => {
		setTaxRates(false);
	}

	// Complete Exist Order
	const handleCompleteOrder = (event) => {
		if (draftOrder?.DoSellerDetails?.length === 0 && draftOrder?.DoCustomerDetails[0]?.gstNumber?.length === 0) {
			alert('Please add Seller Details or Customer GST Number');
			return;
		}
		setLoading(true);
		const submitBtn = document.getElementById('completeOrder');
		submitBtn.disabled = true;
		submitBtn.innerHTML = 'Completing Order...'
		axiosInstance
			.post(`/orders/admin/draftOrderComplete/${completeExistOrder[0]}`, {
				paymentPending: true
			})
			.then((res) => {
				navigate(`/ordersNew/${res?.data?.orderName}`)
				setLoading(false);
			})
			.catch((err) => {
				alert(err?.response?.data?.message ?? "Error in Completing Order.. Please try again");
				submitBtn.innerHTML = 'Complete Order';
				submitBtn.disabled = false;
				setLoading(false);
			})
	}

	const handleShowPopup = () => {
		setConfirmShowPopup(true)
	}


	const hideConfirmPopup = (event) => {
		if (event.target.innerText === 'Yes') {
			handleCompleteOrder();
		}
		setConfirmShowPopup(false)
	}

	const handleShowDeleteOrderPopup = () => {
		setDeleteConfirmPopup(true);
	}


	const hideDeleteOrderConfirmPopup = (event, orderId) => {
		setDeleteConfirmPopup(false);
	}

	const handleCopyDraftOrder = (event) => {
		event.target.disabled = true;
		setLoading(true);
		axiosInstance
			.post(`/orders/admin/copyDraftOrder/${id}`)
			.then((res) => {
				setLoading(false);
				navigate('/draftOrdersNew');
			})
			.catch((err) => {
				setLoading(false);
				setError("Error in Copying Draft Order.. Please try again")
			})
	}

	const handleSendPiWa = (event) => {
		setLoading(true);
		axiosInstance
			.post(`/pgOrder/admin/sendPi/${draftOrder?.draftOrderName}`)
			.then((res) => {
				alert('PI Sent Successfully');
			})
			.catch((err) => {
				alert(err?.response?.data?.message || 'Error in Sending PI.. Please try again');
			})
			.finally(() => {
				setLoading(false);
			})
	}

	const handleSendPiDownload = (event) => {
		setLoading(true);
		axiosInstance
			.post(`/pgOrder/admin/downloadPi/${draftOrder?.draftOrderName}`)
			.then((res) => {
				const piPdfUrl = res?.data?.data?.piPdfUrl;
				window.open(piPdfUrl, '_blank');
				alert('PI Downloaded Successfully');
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			})
	}

	// Open Window Print
	const handlePrintPI = (event) => {
		axiosInstance
            .get(`/pdf/draftOrderPI/${id}`,{
                responseType: 'blob',
                headers: {
                    Accept: 'application/pdf',
                },
            })
            .then((response) => {
                const blob = response.data;
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = `${id}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
	}

	const handleExport = async (event) => {
        event.preventDefault();
        setLoading(true);

        axiosInstance
            .get(`/exports/draftOrder/${draftOrder?.draftOrderName}`)
			.then((res) => {
                setLoading(false);
                const arrayOfRowObjects = res?.data?.data;
                downloadCSV(arrayOfRowObjects, `${draftOrder?.draftOrderName} export - ${new Date()}`);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: `${draftOrder?.draftOrderName} Exported Successfully`, type: "success" }
                });
            })
            .catch((error) => {
                setLoading(false);
                console.log(error)
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

	useEffect(() => {
		if (id) {
			setLoading(true);
			if (updateToggle) {
				axiosInstance
					.get(`/pgOrder/admin/draftOrder/${id}`)
					.then((response) => {
						setDraftOrder(response?.data?.data?.draftOrder);

						let totalItems = 0;
						for (
							let i = 0;
							i < response?.data?.data?.draftOrder?.DoLineItems?.length;
							i++
						) {
							totalItems += +response?.data?.data?.draftOrder?.DoLineItems[i]?.quantity;
						}


						if (response?.data?.data?.draftOrder?.invoiceType) {
							if (response?.data?.data?.draftOrder?.invoiceType === 'bill') {
								document.getElementById('bill').checked = true;
								document.getElementById('billBox').style.marginRight = '10px';
								document.getElementById('challanBox').style.display = 'none';
							} else {
								document.getElementById('challan').checked = true;
								document.getElementById('challanBox').style.marginRight = '10px';
								document.getElementById('billBox').style.display = 'none';
							}
						} else {
							document.getElementById('invoiceTypes').style.display = 'none';
						}

						setTotalItems(totalItems);
						setDeliveryRemark(response?.data?.data?.draftOrder?.deliveryRemark);
						setPaymentRemark(response?.data?.data?.draftOrder?.paymentRemark);
						setCompleteExistOrder([response?.data?.data?.draftOrder?.draftOrderName]);
						setLoading(false);
					})
					.catch((err) => {
						console.log(err)
						setLoading(false);
						setError('Error in Fetching Draft Order.. Please try again');
					});
			}
		}
	}, [id, updateToggle])

	const handleInclGstTotal = (amount, tax) => {
		const total = parseFloat(amount);
		const gst = parseInt(tax);
		const result = total * (1 + (gst / 100));
		const countDecimal = countPlaces(result)
		return countDecimal === 0 || countDecimal === 1 || countDecimal === 2 ? parseFloat(result).toFixed(2) : parseFloat(Math.trunc(result * 100) / 100).toFixed(2)
	}

	useEffect(() => {
		socket?.on(`${draftOrder.draftOrderName}-complete`, data => {
			// console.log(data)
			navigate(`/ordersNew/${data.orderName}`)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [draftOrder])

	return (
		<Fragment>
			{loading ? (
				<div className={Classes.Spinner}>
					<Spinner animation="border" variant="light" />
				</div>
			) : null}
			
			<div className={Classes.container} id="color">
				<div style={{ display:'flex', justifyContent:'space-between', flexWrap:'wrap' }} id='hideHeader'>
					<div className="d-flex align-items-center" style={{ gap: '1rem' }}>
						<div>
							<button type="button" className="btn btn-light" onClick={() => { navigate(-1) }}>
								<BiArrowBack />
							</button>
						</div>

						<div>
							<h6 className={Classes.draftOrderDetail}>
								{draftOrder?.draftOrderName ? draftOrder?.draftOrderName : draftOrder?.draftOrder?.name}
							</h6>
							<h6 className={Classes.draftOrderDetail}>
								{moment(draftOrder?.createdAt).format('MMM DD, YYYY h:mm:ss A')}
							</h6>
						</div>
					</div>

					<div style={{ gap: '8px', display:'flex', flexWrap:'wrap' }}>
						<Permission service={services?.draftOrder} permission="create">
							<div>
								<button
									id='exportBtn'
									variant="outlined"
									size='small'
									onClick={(e) => handleExport(e)}
									className={[Classes.Btn, Classes.sendPIButton].join(' ')}
									style={{ marginRight:10, width:'fit-content' }}
								>
									Export LineItems
								</button>
							</div>
						</Permission>

						<Permission service={services?.draftOrder} permission="create">
							<div>
								<button
									type='btn'
									className={[Classes.Btn, Classes.sendPIButton].join(' ')}
									style={{ backgroundColor: 'black', color: '#fff', marginRight:10, width:'fit-content' }}
									id='deleteOrder'
									onClick={(e) => handleCopyDraftOrder(e)}
								>
									Copy Draft Order
								</button>
							</div>
						</Permission>
						
						<Permission service={services?.draftOrder} permission="delete">
							<div>
								<button
									type='btn'
									className={[Classes.Btn, Classes.sendPIButton].join(' ')}
									id='deleteOrder'
									onClick={(e) => handleShowDeleteOrderPopup(e)}
									style={{width:'fit-content'}}
								>
									Delete Order
								</button>
							</div>
						</Permission>
						{/* <h6 className={Classes.draftOrderDetail} style={{textAlign:'end'}}>
							{moment(draftOrder?.createdAt).format('MMM DD, YYYY h:mm:ss A')}
						</h6> */}
					</div>
				</div>

				{error ? (
					<Alert variant="danger" onClose={() => setError(false)} dismissible>
						<Alert.Heading>{error}</Alert.Heading>
						<p>Try reloading!</p>
					</Alert>
				) : null}

				{draftOrder.length !== 0 && (
					<div className={Classes.draftOrderName} id='orderNamePrint'>
						<p style={{ textAlign: 'center' }}>Order Summary</p>
						<div style={{ display: 'flex' }}>
							<h6 style={{ marginRight: 5 }}>Order Name:-</h6>
							<p>{draftOrder?.draftOrder?.name ? draftOrder?.draftOrder?.name : draftOrder?.draftOrderName}</p>
						</div>
					</div>
				)}

				<div className={Classes.InvoiceType} id='invoiceTypes'>
					<div style={{ display: 'flex' }} id='billBox'>
						<input type="radio" name="choose" id="bill" value="bill" />
						<p style={{ marginLeft: 5 }}>Send on Bill</p>
					</div>

					<div className={Classes.ChallanBox} id='challanBox'>
						<input type="radio" name="choose" id="challan" value="challan" />
						<p style={{ marginLeft: 5 }}>Send on Challan</p>
					</div>
				</div>

				<div className={Classes.mainContainer} style={{ marginTop: 30 }} id='container'>
					<div className={Classes.containBoxes} id='productContainer'>
						{draftOrder?.length !== 0 ? (
							<div className={[Classes.Box, 'box'].join(' ')} style={{ fontSize: 14, padding: 0 }}>
								<div className={Classes.Thead}>
									<p 
										style={{ padding: '10px 20px', fontWeight: 600 }}
										className={Classes.ProductHeading}
										id='productHeading'
									>Product</p>

									<div
										className={[Classes.hideResponsive, 'discount'].join(' ')}
										style={{ width: '15%', padding: '10px 0px' }}
									>
										<p style={{ fontWeight: 600 }}>Discount</p>
										<p style={{ fontSize: 13 }} id='hideInclGst'>(Incl. GST)</p>
									</div>

									<p style={{ fontWeight:600, width:'10%', padding:'10px 0px' }} id='quantity' className={Classes.hideResponsive}>
										Quantity
									</p>

									<div className={Classes.hideResponsive} id='subtotalHeader'>
										<p style={{ fontWeight: 600, paddingLeft: 5 }}>Subtotal</p>
										<p>(Excl. GST)</p>
									</div>

									<div id='totalHeader' className={Classes.hideResponsive}>
										<p style={{ fontWeight: 600, paddingLeft: 5 }}>Total</p>
										<p>(Incl. GST)</p>
									</div>
								</div>

								<div id='productContainer'>
									{draftOrder?.DoLineItems?.map((item, index) => (
										<div
											className={[Classes.containProduct, 'variantProduct'].join(' ')}
											id={item?._id} key={index}
										>
											<div className={[Classes.productDetail, 'draftOrderProducts'].join(' ')} id={index}>
												<div>
													<img
														src={item?.mainImageUrl || "https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png"}
														alt="icon"
														className={Classes.productImage}
													/>
												</div>

												<div className={Classes.VariantDetail}>
													<Link to={`/products/${item?.productId}`} target='_blank'>
														<p 
															style={{ cursor:'pointer', color: '#0d6efd' }}
															onMouseEnter={(e) => { e.target.style.color = '#ff6200'; }}
															onMouseLeave={(e) => { e.target.style.color = '#0d6efd'; }}
														>
															{item?.variantTitle || item?.productTitle}
														</p>
													</Link>

													<div style={{ color:'#6d7175' }}>
														<p style={{ display: 'flex', gap: '5px' }}>
															{item?.productVariants?.length !== 0 && item?.productVariants?.map((variant, index) => (
																<span key={index}>
																	{variant?.variant_type} : {variant?.variant_value}
																</span>
															))?.reduce((prev, curr) => [prev, ' / ', curr])}
														</p>

														<p>{item?.sku}</p>
													</div>

													<div
														style={{ display: 'flex' }}
														className={[Classes.rateFontSize, 'rateExcl'].join(' ')}
													>
														<p className={Classes.newRateExclGst}>
															Rate Excl. GST
														</p>

														<div className={Classes.DiscountedUnitPrice}>
															<p>&#8377;</p>
															<p>
																{item.discountValue !== "0.00" ? parseFloat(item.discountValue).toFixed(2) : parseFloat(item.originalUnitPrice).toFixed(2)}
															</p>
														</div>

														<div>
															<p className={Classes.newCompareAtPrice}>
																&#8377;{item?.mrp}
															</p>
														</div>
													</div>

													<div style={{ display:'flex' }} className={[Classes.rateFontSize, 'rateExcl'].join(' ')}>
														<p className={Classes.newRateExclGst}>Rate Incl. GST</p>

														<div className={Classes.DiscountedUnitPrice}>
															<p>&#8377;</p>
															<p>
																{item?.discountValue !== "0.00"
																? roundOfUptoXDecimal(+item?.discountValue * (1 + +item?.gstPercentage / 100))
																: roundOfUptoXDecimal(+item?.originalUnitPrice * (1 + +item?.gstPercentage / 100))}
															</p>
														</div>
															
														<p className={Classes.newCompareAtPrice}>&#8377;{item?.mrp}</p>
													</div>

													<div className='priceLocation'>
														{item?.districtForPrices && <CapsuleChip capsulePart1="District For Prices" capsulePart2={`${item?.districtForPrices}`} chipColor="#243750" />}
														{item?.parentSKU && <CapsuleChip capsulePart1={`Virtual Bundle : ${item?.parentSKU}`} capsulePart2={`${item?.sku} X ${item?.childQtyPerBundle} Quantity`} chipColor={"#ff6200"} />}
														{item?.isParentProduct === true && <ChildProductsList capsulePart1={"Bundle Product"} capsulePart2={`${item?.childProducts?.length} x SKUs`} colorChip={"#ff6200"} childProducts={item?.childProducts}/>}
													</div>

													<div id='invText' className='invText mt-1'>
														{item?.inventoryStatus && <div>{item?.inventoryStatus === 'Not Available' ? <p style={{ color: 'red' }}>Not Available </p> : <p style={{ color: 'green' }}>{item?.inventoryStatus}</p>}</div>}
													</div>

													{/* In screen is smaller than 650px then show this box */}
													<div className={Classes.ShowResponsive}>
														<div className='percent' style={{ fontSize: 14, display: 'flex' }}>
															<p className={Classes.Text}>Discount Incl. GST:</p>

															<p>
																{handleDiscount(
																	item?.mrp,
																	item?.discountValue !== "0.00" ? item?.discountValue : item?.originalUnitPrice,
																	item?.gstPercentage === null ? 18 : item?.gstPercentage
																)}%
															</p>

															{item?.discountTitle !== null && (
																<div className={[Classes.hideDiscountTitle,'hideDiscountTitle'].join(' ')}>
																	{item.discountTitle}
																</div>
															)}
														</div>

														<div className='qty' style={{ display:'flex' }}>
															<p className={Classes.Text}>Quantity:</p>
															<p>{item?.quantity}</p>
														</div>

														<div className='total' id='discTotalPrint' style={{ display:'flex' }}>
															<p className={Classes.Text}>Subtotal:</p>
															&#8377;{parseFloat(item?.subTotal).toFixed(2)}
														</div>

														<div className='total' id='discTotalPrint' style={{ display:'flex' }}>
															<p className={Classes.Text}>Total:</p>
															&#8377;{handleInclGstTotal(item?.subTotal, item?.gstPercentage)}
														</div>
													</div>
												</div>
											</div>

											<div className={[Classes.percent, Classes.PercentBox, 'percent'].join(' ')}>
												<p style={{ marginLeft:20 }}>
													{handleDiscount(
														item.mrp,
														item.discountValue !== "0.00" ? item.discountValue : item.originalUnitPrice,
														item.gstPercentage === null ? 18 : item.gstPercentage
													)}%
												</p>

												{item.discountTitle !== null && (
													<p className={[Classes.hideDiscountTitle,'hideDiscountTitle'].join(' ')}>
														{item.discountTitle}
													</p>
												)}

												{item.discountTitle !== null && (
													<p className='exclGSTPrint' style={{ fontSize: 12, display: 'none' }}>(Incl. GST)</p>
												)}
											</div>

											<p className={[Classes.percent, Classes.Quantity, 'qty'].join(' ')} style={{paddingLeft:20,textAlign:'start'}}>{item?.quantity}</p>

											<p
												className={[Classes.percent, 'total'].join(' ')}
												style={{ padding: '10px 0px', width: 80 }}
												id='discTotalPrint'
											>
												&#8377;{parseFloat(item?.subTotal).toFixed(2)}
											</p>

											<p
												className={[Classes.percent, 'total'].join(' ')}
												style={{ padding: '10px 0px', width: 80 }}
												id='discTotalPrint'
											>
												&#8377;{handleInclGstTotal(item?.subTotal, item?.gstPercentage)}
											</p>
										</div>
									))}
								</div>
							</div>
						) : null}

						<div className={[Classes.Box, 'box'].join(' ')} id='paymentBox'>
							<div className={Classes.containerBox}>
								<h6 className="d-flex justify-content-between mb-3" style={{ margin: 'auto 0px' }}>
									Payment
								</h6>

								<div className={Classes.fontSize}>
									<div className={Classes.PaymentBoxSubtotal}>
										<p style={{ width:'25%' }}>Subtotal</p>
										<p style={{ marginRight:3, width:'50%' }}>{totalItems} Items</p>

										{draftOrder?.length !== 0 && (
											<p className={Classes.widthTextAlign}>
												&#8377;{parseFloat(draftOrder?.DoPaymentDetail?.subTotalPrice).toFixed(2)}
											</p>
										)}
									</div>

									<div>
										{draftOrder?.length !== 0 && draftOrder?.DoPaymentDetail?.overallDiscountValue && (
											<div className={Classes.flexWidth} style={{ marginBottom:'1rem' }}>
												<p style={{ width:'25%' }}>Discount</p>
												<div style={{ width:'75%', display: 'flex'  }}>
													<div style={{ width: '50%', display: 'flex' }}>
														<p style={{ marginRight: 3 }}>
															{draftOrder?.DoPaymentDetail?.overallDiscountTitle || 'Custom Discount'}
														</p>
													</div>

													<p className={Classes.widthTextAlign} style={{width: "50%"}}>
														({draftOrder?.DoPaymentDetail?.overallDiscountPercentage}%)
														-&#8377;{draftOrder?.DoPaymentDetail?.overallDiscountAmount}
													</p>
												</div>
											</div>
										)}

										{draftOrder?.length !== 0 && draftOrder?.shippingCost !== 0 && (
											<div className={Classes.flexWidth} style={{ marginBottom:'1rem' }}>
												<p style={{ width:'25%' }}>Shipping</p>
												<p style={{ width:'50%' }}>{draftOrder?.shippingTitle || ""}</p>						
												<p className={[Classes.shippingAmount].join(' ')}>
													+&#8377;{parseFloat(draftOrder?.shippingCost).toFixed(2)}
												</p>
											</div>
										)}

										<div style={{ marginBottom: '1rem', display: 'flex' }}>
											<p style={{ width:'25%' }} id='tax'>Tax</p>

											{draftOrder?.TaxLines ? (
												<p id='taxRate' className={Classes.showTaxRates} onClick={handleTaxRates}>
													Show tax rates
												</p>
											) : ( 
												<p style={{ width: '50%' }}></p> 
											)}

											{draftOrder?.length !== 0 && (
												<p id='taxRateAmount' style={{ color:'#000' }} className={Classes.widthTextAlign}>
													&#8377;{parseFloat(draftOrder?.DoPaymentDetail?.totalTaxPrice).toFixed(2)}
												</p>
											)}
										</div>
									</div>

									<div className="d-flex justify-content-between" style={{ fontSize:14, fontWeight:600 }}>
										<p>Total</p>
										<p>&#8377;{draftOrder?.length !== 0 ? roundOfUptoXDecimal(draftOrder?.DoPaymentDetail?.totalPrice) : 0.00}</p>
									</div>
								</div>
							</div>

							<div className={Classes.containerFooter} id='removeCreateOrderPrint'>
								{draftOrder?.length !== 0 && (
									<div className={Classes.flexBetween}>
										{draftOrder?.DoCustomerDetails?.length !== 0 && draftOrder?.DoCustomerAddresses?.length >= 2 && (
											<Permission service={services?.draftOrder} permission="create">
												<div className={Classes.flexBetween} style={{ gap: '10px' }}>
													<button
														className={[Classes.Btn, Classes.completeOrderButton].join(' ')}
														id='completeOrder'
														onClick={(e) => handleShowPopup(e)}
													>
														Complete Order
													</button>
												</div>
											</Permission>
										)}

										<div className={Classes.flexBetween} style={{ gap: '10px', flexWrap:'wrap' }}>
											<Permission service={services?.draftOrder} permission="create">
												<SplitButton
													buttons={
														{
															buttonsNames: [
																'Send PI to Customer', // --> index 0
																'Download PI',		 // --> index 1
																'Print PI'		 // --> index 2
															],
															selectedButtonIndex: [
																(!draftOrder?.piPdfUrl && true),
																(!draftOrder?.piPdfUrl && true),
																false,
															].findIndex((ele) => ele === false) || 0,
															disabledButtons: [
																(!draftOrder?.piPdfUrl && true),
																(!draftOrder?.piPdfUrl && true),
																false,
															],
															dialogs: [
																{
																	dialogTitle: "Are you sure you want to send PI to Customer?",
																	dialogContent: "Once you send PI to Customer, it can't be deleted",
																	submitDialogButtonName: "Yes"
																},
															]
														}}
													handleClicks={
														[
															(e) => handleSendPiWa(e),
															(e) => handleSendPiDownload(e),
															(e) => handlePrintPI(e)
														]
													}
													styleForSelectedButton={
														{ backgroundColor: '#6c943eeb', borderColor: 'white', width: 'fit-content' }
													}
													styleForDropdownButton={
														{ backgroundColor: '#6c943eeb', }
													}
													ids={[
														'sendPiWa',
														'sendPiDownload',
														'submitBtn'
													]}
													classes={
														[Classes.Button, Classes.deleteOrderButton].join(' ')
													}
													dialogTitle={
														"Are you sure you want to send PI to Customer?"
													}
													dialogContent={
														"Once you send PI to Customer, you can't edit the order"
													}
													submitDialogButtonName={
														"Yes"
													}
												/>
											</Permission>
											<Permission service={services?.draftOrder} permission="update">
												<div>
													<button
														type='btn'
														className={[Classes.Button, Classes.editOrderButton].join(' ')}
														onClick={() => navigate(`/createOrder/${completeExistOrder}`)}
													>
														Edit Order
													</button>
												</div>
											</Permission>
										</div>
									</div>
								)}
							</div>

							{draftOrder?.length !== 0 && (
								<div id='TaxRatesPrint'>
									<TaxRatesPopup
										showTaxRate={showTaxRate}
										setTaxRates={setTaxRates}
										handleTaxRates={handleTaxRates}
										hideTaxPopup={hideTaxPopup}
										draftOrder={draftOrder}
										type={"draftOrders"}
									/>
								</div>
							)}
						</div>
					</div>
					
					<div className={Classes.asideContainer} id='asideContainer'>
						<Aside
							draftOrder={draftOrder}
							deliveryRemark={deliveryRemark}
							paymentRemark={paymentRemark}
						/>
					</div>
				</div>
			</div>

			<div>
				<ConfirmPopup
					showConfirmPopup={showConfirmPopup}
					hideConfirmPopup={hideConfirmPopup}
				/>
			</div>

			<div className={showDeleteConfirmPopup ? Classes.UpdateConfirmationDialog : undefined}>
				<DeleteOrderConfirmPopup
					showDeleteConfirmPopup={showDeleteConfirmPopup}
					hideDeleteOrderConfirmPopup={hideDeleteOrderConfirmPopup}
					draftOrderId={draftOrder?.length !== 0 && draftOrder?.draftOrderId}
				/>
			</div>
			<SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
		</Fragment>
	);
};

export default DraftOrderDetailPageNew;