import React, { Fragment, useState } from 'react';
import Table from '../../../components/table/table';
import Button from '@mui/material/Button';
import CustomerModal from '../createPurchaseOrder/aside/newLocationModal';
import Classes from '../customers/css/customer.module.css';
import ContactPopUpLocation from '../createPurchaseOrder/aside/contactPopUpLocation';
import axiosInstance from '../../../utility/axios-instance';
import FormDialog from '../../../components/FormDialog/FormDialog';
import { MenuItem, TextField, Typography } from '@mui/material';
import SnackBar from '../../../components/SnackBar';
import ExportDatas from '../../../components/exportDataDialog';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';
import MobileResponsive from './mobileResponsive';
import { useSelector } from 'react-redux';
import { setLocations } from '../../../store/locationsSlice';
import DetailsPopUp from "../createPurchaseOrder/aside/contactDetailsPopUp";

const Locations = (props) => {
    const [open, setOpen] = useState(false);
    const [upadateDetails, setUpadateDetails] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [location, setLocation] = useState([]);
    const [reRender, setReRender] = useState(false);
    const [locationContactDetailId, setLocationContactDetailId] = useState('')
    const [locationContactDetails, setLocationContactDetails] = useState({})
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [openExport,setOpenExport] = useState(false);

    const locations = useSelector((state) => state.locations);
    const handleExportDialog = () => {
        setOpenExport(true);
    }

    const handleClose = () => {
        setOpenExport(false);
    }

    const columnVisibilityModel = {
        firstName: false,
        lastName: false,
        role: false,
        email: false,
        locationContactDetailId: false,
        locationGstNumber: false,
        locationId: false,
        city: false,
        state: false,
    }

    const L1CategoriesRenderCell = (row) => {
        const l1s = row?.l1s
        return (
            l1s?.length > 0 && (
                <TextField
                    id="l1s"
                    select
                    renderValue={(p) => p}
                    SelectProps={{ renderValue: (p) => p }}
                    sx={{
                        width:'100%', padding:'5px',
                        "& .MuiOutlinedInput-input": { padding: '7px', fontSize: 13 },
                    }}
                    key={`${l1s[0]}`}
                    defaultValue={`${l1s[0]}`}
                >
                    {l1s?.map((l1, index) => (
                        <MenuItem
                            disabled={true}
                            key={`${index}`}
                            defaultValue={`${l1}`}
                            style={{fontSize:13,padding:"3px 10px"}}
                            sx={{ '&.Mui-disabled': { color: '#000000', opacity: 1 } }}
                        >{`${l1}`}</MenuItem>
                    ))}
                </TextField >
            )
        )
    }

    const UpdateRenderCell = (row) => {
        return (
            <Permission service={services?.locations} permission={"update"}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                    onClick={(e) => handleUpdateLocation(row)}
                >Update</Button>
            </Permission>
        )
    }

    const ContactDetailsRenderCell = (row) => {
        return (
            <Permission service={services?.locations} permission={"update"}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                    onClick={(e) => handleUpdateDetails(row)}
                >Update</Button>
            </Permission>
        )
    }

    const DeleteLocationRenderCell = (row) => {
        return (
            <Permission service={services?.locations} permission={"delete"}>
                <FormDialog
                    dialogTitle="Confirm Location Deletion?"
                    dialogActionType="confirmation"
                    openDialogButtonName="Delete"
                    submitDialogButtonName="Delete"
                    onConfirmation={() => handleDeleteLocation(row?.locationCode)}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        This action would permanently delete Location?
                    </Typography>
                </FormDialog>
            </Permission>
        )
    }

    const columns = [
        {
            field: 'locationId',
            headerName: 'Location Id',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'locationCode',
            headerName: 'Location Code',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'locationName',
            headerName: 'Location Name',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'locationType',
            headerName: 'Location Type',
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
        { 
            field: 'district', 
            headerName: 'District', 
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'firstName', 
            headerName: 'First Name', 
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'lastName', 
            headerName: 'Last Name', 
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'city', 
            headerName: 'City', 
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'state', 
            headerName: 'State', 
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'phoneNumber', 
            headerName: 'Phone Number', 
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'email', 
            headerName: 'Email', 
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'role', 
            headerName: 'Role', 
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'locationContactDetailId', 
            headerName: 'locationContactDetailId', 
            flex:1,
            headerClassName: 'super-app-theme--header',
            filterable: false,
        },
        {
            field: 'locationGstNumber', 
            headerName: 'Gst Number', 
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'l1s', 
            headerName: 'L1 Categories', 
            flex:1,
            headerClassName: 'super-app-theme--header',
            sortable: false,
            filterable: false,
            renderCell: (params) => ( <L1CategoriesRenderCell {...params?.row} />),
            customRenderCell: L1CategoriesRenderCell
        },
        {
            field: 'default', 
            headerName: 'Default', 
            flex:1,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'Update',
            headerName: 'Update',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <UpdateRenderCell {...params?.row} />),
            customRenderCell: UpdateRenderCell
        },
        {
            field: 'Contact  Details',
            headerName: 'Contact',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <ContactDetailsRenderCell {...params?.row} />),
            customRenderCell: ContactDetailsRenderCell
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <DeleteLocationRenderCell {...params?.row} />),
            customRenderCell: DeleteLocationRenderCell
        }
    ];


    const handleLocation = () => {
        setOpen(true);
    }

    const hideLocation = () => {
        setOpen(false);
    }

    const handleUpdateLocation = (location) => {
        setLocation([location])
        setUpdateOpen(true);
        setLocationContactDetailId(location?.locationContactDetailId)
        setLocationContactDetails((prevLocationContactDetails)=>{
            return {
                ...prevLocationContactDetails,
                firstName: location?.firstName,
                lastName: location?.lastName,
                role: location?.role,
                email: location?.email,
                phoneNumber: location?.phoneNumber,
            }
        })
    }
    const handleUpdateDetails = (location) => {
      setLocation([location]);
      setUpadateDetails(true);
      setLocationContactDetailId(location?.locationContactDetailId);
      setLocationContactDetails((prevLocationContactDetails) => {
        return {
          ...prevLocationContactDetails,
          firstName: location?.firstName,
          lastName: location?.lastName,
          role: location?.role,
          email: location?.email,
          phoneNumber: location?.phoneNumber,
        };
      });
    };

    const hideUpdateLocation = () => {
        setUpdateOpen(false);
    }
    const hideUpdateDetails=()=>{
      setUpadateDetails(false);
    }

    const handleDeleteLocation = async (locationCode) => {
        await axiosInstance
            .delete(`/location/deleteLocation/${locationCode}`)
            .then((res) => {
                setReRender((prevReRender) => !prevReRender);
                const successMessage = res?.data?.message ? res?.data?.message : 'Location Deleted Successfully'
                 setSnackBar((prevSnackBar) => {
                     return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                 });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong'
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    return (
        <Fragment>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
            <div style={{ display:'flex',justifyContent:'end',marginBottom:10,marginTop:-25 }}>
                <Button  
                    variant="contained"
                    color="primary"
                    sx={{ textTransform:'capitalize',marginRight:"10px" }}
                    onClick={handleExportDialog}
                >
                    Export
                </Button>
                <Permission service={services?.locations} permission={"create"}>
                <Button
                    variant="contained"
                    onClick={handleLocation}
                    style={{ textTransform: 'capitalize' }}
                >
                    Create Location
                </Button>
                </Permission>
            </div>

            {/* <div className={Classes.MuiTable}> */}
                <Table
                    columns={columns}
                    dataPosition={"fetchedLocations"}
                    endpoint={`/location/fetchAllLocations`}
                    rowId={'locationCode'}
                    checkBoxSelection={false}
                    reRender={reRender}
                    columnVisibilityModel={columnVisibilityModel}
                    service={'locations'}
                    tabName={'Locations'}
                    setData={setLocations}
                    data={locations}
                    mobileResponsive={true}
                />
            {/* </div> */}

            {/* <div className={Classes.MobileResponsive}>
                <MobileResponsive 
                    columns={columns}
                    dataPosition={"fetchedLocations"}
                    endpoint={`/location/fetchAllLocations`}
                    rowId={'locationCode'}
                    handleUpdateLocation={handleUpdateLocation}
                    handleDeleteLocation={handleDeleteLocation}
                    reRender={reRender}
                />
            </div> */}

            <div className={open ? Classes.UpdateSalesDialog : undefined}>
                <CustomerModal
                    onShowModal={open}
                    onHideModal={hideLocation}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>

            <div className={updateOpen ? Classes.UpdateSalesDialog : undefined}>
                <ContactPopUpLocation
                    contactModal={updateOpen}
                    hideContactPop={hideUpdateLocation}
                    showCustomerDetail={location}
                    setCustomerDetail={setLocation}
                    setReRender={setReRender}
                    locationContactDetailId={locationContactDetailId}
                    setLocationContactDetailId={setLocationContactDetailId}
                    locationContactDetails={locationContactDetails}
                    setLocationContactDetails={setLocationContactDetails}
                    reRender={reRender}
                    l1MappingsCreateUpdate={true}
                />
            </div>
            <div className={upadateDetails ? Classes.UpdateSalesDialog : undefined}>
        <DetailsPopUp
          contactModal={upadateDetails}
          hideContactPop={hideUpdateDetails}
          showCustomerDetail={location}
          setCustomerDetail={setLocation}
          setReRender={setReRender}
          locationContactDetailId={locationContactDetailId}
          setLocationContactDetailId={setLocationContactDetailId}
          locationContactDetails={locationContactDetails}
          setLocationContactDetails={setLocationContactDetails}
          reRender={reRender}
          l1MappingsCreateUpdate={true}
        />
      </div>

            <ExportDatas 
                open={openExport}
                handleClose={handleClose}
                endPoint={'/exports/locations'}
                dataName="Locations"
            />
        </Fragment>
    )
}
export default Locations;