import React, { useEffect, useState } from "react";
import axiosInstance from "../../../utility/axios-instance";
import DateRange from "../../../components/DateRange/DateRange";
import Classes from './css/customerProfile.module.css';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Autocomplete, Button, Chip, Paper, TextField } from "@mui/material";
import CollectCustomerPayment from "./aside/customerPaymentCollection";
import Spinner from "../../../components/spinner";
import { createdAt } from "../../../utility/textFormatting";
import { useNavigate } from 'react-router-dom';

export default function Ledger({ selectedCustomer, setSelectedCustomer }) {
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [details, setDetails] = useState(null);
    const [customerId, setCustomerId] = useState(selectedCustomer ? selectedCustomer : null);
    const [reRender, setReRender] = useState(false);
    const [open, setOpen] = useState(false);
    const [sellers, setSellers] = useState(null);
    const [selectedSeller, setSelectedSeller] = useState(null);


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const getFormattedDate = (inputDate) => {
        const dateObject = new Date(inputDate);
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        const day = dateObject.getDate().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };


    const fetchData = async () => {
        setLoading(true);
        let params = {
            companyId: selectedSeller?.id ? selectedSeller?.id : undefined,

        };
        if (startDate && endDate) {
            params = {
                ...params,
                startDate: startDate ? getFormattedDate(startDate) : undefined,
                endDate: endDate
                    ? getFormattedDate(endDate)
                    : startDate
                        ? getFormattedDate(endDate)
                        : undefined,
            };
        }
        await axiosInstance
            .get(`/ledger/fetchCustomerLedger/${customerId}`, { params })
            .then((res) => {
                setDetails(res?.data?.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchSellers = async () => {
        setLoading(true);
        await axiosInstance
            .get(`/company/getAllCompanies`)
            .then((res) => {
                setSellers(res?.data?.data?.companies);
                setSelectedSeller(res?.data?.data?.companies[0]);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (!customerId) return;
        fetchData();
    }, [startDate, endDate, customerId, reRender, selectedSeller]);

    useEffect(() => {
        fetchSellers()
    }, [])



    return (
        <>
            <div style={{ display: 'flex', marginTop: '20px', padding: '15px 20px', flexDirection: 'column', width: '100%' }} className={Classes.Boxes}>
                <div style={{display:'flex', justifyContent:'space-between',flexWrap:'wrap'}}>
                    <h2>Ledger Details</h2>
                    <div style={{marginBottom:4}}>
                        <Button variant="contained" onClick={() => navigate(`/customers/${customerId}/creditAgreement`)}>
                            Credit Agreement
                        </Button>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                    width: '100%',
                    flexWrap: 'wrap',
                    gap:10
                }}>
                    <Button variant="contained" onClick={(e) => setOpen(true)}>Collect Payment</Button>
                    <DateRange startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                    <Autocomplete
                        size="small"
                        name="sellerId"
                        sx={{width: '30%'}}
                        id="multiple-limit-tags"
                        options={sellers ? sellers : [{ name: "Loading...", id: 0 }]}
                        value={selectedSeller}
                        getOptionLabel={(seller) => seller?.companyNameInTally}
                        getOptionSelected={(option, seller) => option === seller}
                        onChange={(e, newVal) => setSelectedSeller(newVal)}
                        renderInput={(params) => (
                            <TextField {...params} label="Company" variant="outlined" />
                        )}
                    />
                </div>
                <div className="flex-1 space-y-4">
                    {details ? (
                        <div className="w-full">
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem', alignItems: 'flex-end', flexWrap:'wrap' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '1.5rem', fontWeight: 500 }}>
                                    <p>{details?.customerDetails?.displayName}</p>
                                    <p>{details?.customerDetails?.email}</p>
                                    <p>{details?.customerDetails?.phone}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 500 }}>
                                    <p style={{ fontWeight: 'semibold' }}>Summary</p>
                                    <div style={{ display: 'flex', gap: '0.25rem' }}>
                                        <p>Total Amount Debited:</p>
                                        <p>₹{Math.round(details?.totalDebitedAmount?.toFixed(2))}</p>
                                    </div>
                                    <div style={{ display: 'flex', gap: '0.25rem' }}>
                                        <p>Total Amount Credited:</p>
                                        <p>₹{Math.round(details?.totalCreditedAmount?.toFixed(2))}</p>
                                    </div>
                                </div>
                            </div>

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Date</StyledTableCell>
                                            <StyledTableCell>Type of Entry</StyledTableCell>
                                            <StyledTableCell>Details of Entry</StyledTableCell>
                                            <StyledTableCell>Debit (INR)</StyledTableCell>
                                            <StyledTableCell>Credit (INR)</StyledTableCell>
                                            <StyledTableCell>Balance</StyledTableCell>
                                            <StyledTableCell>Total Due</StyledTableCell>
                                            <StyledTableCell>Status (Fulfillment)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colspan="5">**Opening Balance**</TableCell>
                                            <TableCell colspan="3">
                                                ₹{Math.round(details?.openingBalance)}
                                            </TableCell>
                                        </TableRow>
                                        {details?.customerLedgerEntries?.map((journal) => (
                                            <StyledTableRow key={journal.id}>
                                                <StyledTableCell component="th" scope="row">
                                                    {createdAt(journal?.date)}
                                                </StyledTableCell>
                                                <StyledTableCell >{journal?.entry_type}</StyledTableCell>
                                                <StyledTableCell >{journal?.entry_details}</StyledTableCell>
                                                <StyledTableCell >{journal?.debit_amount
                                                    ? `₹${journal?.debit_amount}`
                                                    : "-"}</StyledTableCell>
                                                <StyledTableCell >{journal?.credit_amount
                                                    ? `₹${journal?.credit_amount}`
                                                    : "-"}</StyledTableCell>
                                                <StyledTableCell >₹{Math.round(journal?.balance)}</StyledTableCell>
                                                <StyledTableCell >₹{Math.round(journal?.total_due)}</StyledTableCell>
                                                <StyledTableCell >{journal?.fulfillmentStatus ? (
                                                    <Chip
                                                        label={journal?.fulfillmentStatus}
                                                        color={journal?.fulfillmentStatus === "Paid" ? "success" : "error"}
                                                    />
                                                ) : null}</StyledTableCell>

                                            </StyledTableRow>
                                        ))}

                                        <TableRow>
                                            <TableCell colspan="5">**Closing Balance**</TableCell>
                                            <TableCell colspan="3">
                                                ₹{Math.round(details?.closingBalance)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                    ) : (
                        <div className="flex justify-center items-center gap-2 py-8">
                            <p className="text-lg">No data available..</p>
                        </div>
                    )}
                </div>
            </div>
            <CollectCustomerPayment sellers={sellers} setReRender={setReRender} open={open} setOpen={setOpen} id={customerId} />
            {loading && (<Spinner />)}
        </>
    );
}
