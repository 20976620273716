import { Fragment } from 'react';
import Classes from '../../pages/home/createPurchaseOrder/css/create.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import CapsuleChip from '../CapsuleChip';
import { Switch } from '@mui/material';
import ChildProductsList from '../../pages/home/createOrders/childProductsList';
import BulkProductAddForCreateOrders from '../../pages/home/createOrders/bulkProductAddForCreateOrders';
import { roundOfUptoXDecimal } from '../../utility/calculation';

const CreateOrderComponent = (props) => {
    const { 
        handleEdit,
        handleBlur,
        handleOutline,
        getModal,
        showProduct,
        lineItems,
        handleEditPercentage,
        handleBlurPercentage,
        showLimitDiscount,
        handleChange,
        handleBlurQty,
        incQty,
        decQty,
        handleBlurBulkPackagingQty1,
        handleKeyUp,
        incQtyBulkPackaging,
        decQtyBulkPackaging,
        handleBlurBulkPackagingQty2,
        updateArr,
        active,
        onChangeActive,
        componentType,
        setProductDetails, setAddToggle
    } = props;

    const formatNumber = (number) => {
        const formattedNumber = parseFloat(number).toFixed(2);
        return formattedNumber;
    };

    const preventValueWithoutDecimal = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    return (
        <Fragment>
            <div className={[Classes.Box,'box'].join(' ')}>
                <div className={Classes.containerBox} id='createOrderHeader'>
                    <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}>
                        <h6 style={{ margin: 'auto 0px 12px' }}>Products</h6>
                        <BulkProductAddForCreateOrders setProductDetails={setProductDetails} setAddToggle={setAddToggle} type={'purchaseOrder'} />
                        {showProduct?.length !== 0  && componentType === 'Purchase Order' && (
                            <div style={{display:'flex',marginRight:30}}>
                                <div style={{marginTop:-5}}>
                                    <Switch
                                        name="active"
                                        checked={active}
                                        onChange={onChangeActive}
                                        id="flexSwitchCheckChecked"
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </div>
                                <p>Price</p>
                            </div>
                        )}
                    </div>
                    <div id='outline' onClick={handleOutline} className={Classes.searchContainer}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} className={Classes.searchIcon} />
                        <div style={{ width: '100%' }}>
                            <input
                                type="text"
                                id="search"
                                autoComplete='off'
                                className={Classes.searchField}
                                placeholder="Search products here"
                                value={props.text}
                                onChange={(e) => getModal(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                {showProduct?.length !== 0 ? (
                    <div className={Classes.variantContainer}>
                        <div className={Classes.Thead} style={{justifyContent:'space-between',paddingRight: componentType && !(active) ? '40px' : '22px' }} >
                            <div className={['createOrderProductHeading',Classes.variantProductHeader].join(' ')}>
                                Product
                            </div>

                            {componentType && active && (
                                <div className={['discountCreateOrder',Classes.variantDiscountHeader].join(' ')}>
                                    <p>Discount</p>
                                    <p id='inclGst'>(Incl. GST)</p>
                                </div>
                            )}                            

                            <div className={['qtyHeader',Classes.variantQuantityHeader].join(' ')}>
                                Quantity
                            </div>
                            
                            {componentType && active && (
                                <div className={['subtotal',Classes.variantSubTotalHeader].join(' ')}>
                                    <p style={{ paddingLeft: 5 }}>Subtotal</p>
                                    <p>(Excl. GST)</p>
                                </div>
                            )}

                            {componentType && active && (
                                <div className={['total',Classes.variantTotalHeader].join(' ')}>
                                    <p style={{ paddingLeft: 10 }}>Total</p>
                                    <p>(Incl. GST)</p>
                                </div>
                            )}
                        </div>

                        {showProduct?.lineItems?.map((item, index) => (
                            <div
                                className={[Classes.containProduct,'variantProduct'].join(' ')}
                                id={item?.productId}
                                key={item?.productId}
                                style={{justifyContent:'space-between'}}
                            >
                                <div style={{justifyContent:'space-between',display:'flex',width:'98%'}}>
                                    <div className={[Classes.productDetail,'productVariant'].join(' ')} id={item?.productId}>
                                        <div>
                                            <img
                                                src={
                                                    item?.mainImageUrl ?
                                                    item?.mainImageUrl :
                                                    "https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png"
                                                }
                                                alt="icon"
                                                className={Classes.variantImage}
                                            />
                                        </div>

                                        <div className={Classes.variantDetail}>
                                            <p style={{ color:'#0d6efd' }}>{item?.basicCollectionDataBrandData?.productName}</p>
                                            <div style={{ color: '#6d7175' }}>
                                                <div className={Classes.commonDisplay}>
                                                    <p>{item?.variationDataProductInformationProductDetail?.variable1Value}</p>

                                                    {item?.variationDataProductInformationProductDetail?.variable2Value && (
                                                        <div className={Classes.commonDisplay}>
                                                            <p style={{ margin: '0px 5px' }}>/</p>
                                                            <p>{item?.variationDataProductInformationProductDetail?.variable2Value}</p>
                                                        </div>
                                                    )}

                                                    {item?.variationDataProductInformationProductDetail?.variable3Value && (
                                                        <div className={Classes.commonDisplay}>
                                                            <p style={{ margin: '0px 5px' }}>/</p>
                                                            <p>{item?.variationDataProductInformationProductDetail?.variable3Value}</p>
                                                        </div>
                                                    )}
                                                </div>

                                                <p>{item?.basicCollectionDataBrandData?.sku}</p>
                                                
                                                {componentType && active && (
                                                    <div className={[Classes.GstRate, 'gstRate'].join(' ')}>
                                                        <p>GST Rate:- </p>
                                                        <p style={{ marginLeft: 5 }}>{item?.priceDataAffiliateData?.gst}%</p>
                                                    </div>
                                                )}
                                            </div>

                                            {componentType && active && (
                                                <div
                                                    style={{ display:'flex' }}
                                                    className={[Classes.rateFontSize,'hidePricePrint'].join(' ')}
                                                >
                                                    <p style={{ color:'rgb(109, 113, 117)',marginRight:9 }}>Net Price</p>
                                                    <div style={{ display: 'flex', color: '#0d6efd' }}>
                                                        <p>&#8377;</p>
                                                        <p
                                                            style={{ padding: '0px 5px' }}
                                                            contentEditable={true}
                                                            suppressContentEditableWarning={true}
                                                            onKeyDown={(e) => handleEdit(e)}
                                                            onBlur={(e) =>
                                                                handleBlur(
                                                                    e,
                                                                    item?.productId,
                                                                    item?.discountedUnitPrice,
                                                                    item?.priceDataAffiliateData?.mrp
                                                                )
                                                            }
                                                        >
                                                            {formatNumber(item?.discountedUnitPrice)}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}

                                            {componentType && active && (
                                                <div 
                                                    style={{ display:'flex' }}
                                                    className={[Classes.rateFontSize,'hidePricePrint'].join(' ')}
                                                >
                                                    <p style={{ color:'rgb(109, 113, 117)',marginRight:9 }}>
                                                        MRP
                                                    </p>
                                                    <p style={{ padding: '0px 5px',color:'#0d6efd' }}>
                                                        &#8377;{item?.priceDataAffiliateData?.mrp}
                                                    </p>
                                                </div>
                                            )}

                                            {componentType && active && (
                                                <div className={Classes.MrpLessThanRestrict}>
                                                    <p className={Classes.MrpRestrictMessage}>
                                                        Price must be less than {item?.priceDataAffiliateData?.originalListingPrice}
                                                    </p>
                                                </div>
                                            )}

                                            {componentType && active && (
                                                <div id='manuallyDiscountPercent'>
                                                    <div className={Classes.commonDisplay}>
                                                        <p className={Classes.variantDiscountInclGst}>Discount Incl. GST</p>
                                                        <div>
                                                            <input
                                                                type="number"
                                                                id={`discountPercentage-${index}`}
                                                                onChange={(e) => handleEditPercentage(e)}
                                                                onBlur={(e) => handleBlurPercentage(
                                                                    e,
                                                                    item?.productId,
                                                                    item?.priceDataAffiliateData?.mrp,
                                                                    item?.priceDataAffiliateData?.gst,
                                                                    item?.priceDataAffiliateData?.mrp
                                                                )}
                                                                className={Classes.variantDiscountInclGstInput}
                                                                onKeyDown={preventValueWithoutDecimal}
                                                            />
                                                        </div>

                                                        <div className={[Classes.hideResponsiveRow,'hideResponsiveRow'].join(' ')}>
                                                            <div
                                                                id='discountIncWidPrint'
                                                                className={[
                                                                    item?.priceDataAffiliateData?.appliedDiscountTitle ?
                                                                    Classes.marginTop : '',
                                                                    Classes.variantDiscountTitle
                                                                ].join(' ')}
                                                            >
                                                                <div style={{ display:'flex',justifyContent: 'center' }}>
                                                                    <p>{formatNumber(item?.discountPercentage)}</p>
                                                                    <p>%</p>
                                                                </div>

                                                                {item?.priceDataAffiliateData?.appliedDiscountTitle && (
                                                                    <div id='discountType' className={Classes.appliedDiscountType}>
                                                                        {item?.priceDataAffiliateData?.appliedDiscountTitle}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div id='discountPercentRestrict' className={Classes.DiscountMsg}>
                                                        <p className={Classes.DiscountRestrictMsg}>
                                                            Discount must be smaller than 100%
                                                        </p>
                                                    </div>

                                                    <div id='discountPercentRestrict' className={Classes.DiscountMsg}>
                                                        <p className={Classes.DiscountRestrictMsg}>
                                                            Discount must be smaller than 100%
                                                        </p>
                                                    </div>

                                                    <div className={[Classes.MrpLessThanRestrict,'index','discountContent'].join(' ')}>
                                                        <div className={Classes.MrpRestrictMessage} style={{ display:'flex',marginTop:5 }}>
                                                            <p>Discount must be greater than</p>
                                                            <p style={{ marginLeft: 5 }}>
                                                                {showLimitDiscount(
                                                                    item?.priceDataAffiliateData?.mrp,
                                                                    item?.priceDataAffiliateData?.mrp,
                                                                    item?.priceDataAffiliateData?.gst
                                                                )}
                                                            </p>
                                                            <p>%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div style={{ display: 'flex' }} id='PCS'>
                                                <p style={{ marginTop:15,fontSize:13,width:55 }}>
                                                    {item?.basicCollectionDataBrandData?.unit ? item?.basicCollectionDataBrandData?.unit : 'PCS'}
                                                </p>

                                                <div
                                                    className={[Classes.bulkPackagingQtyBox,'variantQty'].join(' ')}
                                                    style={{ marginTop: '5px', width: '82px' }}
                                                >
                                                    <div className='variantQty'>
                                                        <input
                                                            type="number"
                                                            className={[Classes.Input,Classes.bulkPackagingQtyInput].join(' ')}
                                                            style={{ paddingLeft:8 }}
                                                            name="quantity"
                                                            onChange={(e) => handleChange(e.target.value,item?.basicCollectionDataBrandData?.sku)}
                                                            value={item?.PCS}
                                                            onBlur={(e) =>
                                                                handleBlurQty(
                                                                    e.target.value,
                                                                    item?.productId,
                                                                    item?.basicCollectionDataBrandData?.bulkPackingValue1,
                                                                    item?.basicCollectionDataBrandData?.bulkPackingValue2,
                                                                    `${index}-PCS`
                                                                )
                                                            }
                                                            onKeyDown={handleKeyUp}
                                                        />
                                                    </div>

                                                    <div>
                                                        <div
                                                            className={Classes.bulkPackagingIncQty}
                                                            onClick={(e) => incQty(
                                                                e.target.value,
                                                                item?.productId,
                                                                item?.basicCollectionDataBrandData?.bulkPackingValue1,
                                                                item?.basicCollectionDataBrandData?.bulkPackingValue2
                                                            )}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faSortUp}
                                                                style={{ fontSize:12,position:"relative",top:"-21%" }}
                                                            />
                                                        </div>

                                                        <div
                                                            className={Classes.bulkPackagingDecQty}
                                                            onClick={(e) => decQty(
                                                                e.target, 
                                                                item?.productId,
                                                                item?.basicCollectionDataBrandData?.bulkPackingValue1,
                                                                item?.basicCollectionDataBrandData?.bulkPackingValue2
                                                            )}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faSortDown}
                                                                style={{ position:"relative",top:"-53%",fontSize:12 }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div 
                                                className={Classes.QtyNegativeMsg}
                                                style={{ color:'#fff',marginTop:5 }}
                                                id={`${index}-PCS`}
                                            >
                                                <p>{item?.basicCollectionDataBrandData?.unit ? item?.basicCollectionDataBrandData?.unit : 'PCS'} can't be Negative</p>
                                            </div>

                                            {item?.basicCollectionDataBrandData?.bulkPackingType1 && (
                                                <div style={{ display: 'flex' }} id='bulkPacking1'>
                                                    <p style={{ marginTop:12,width:55 }}>
                                                        {item?.basicCollectionDataBrandData?.bulkPackingType1}
                                                    </p>

                                                    <div
                                                        className={[Classes.bulkPackagingQtyBox,'variantQty'].join(' ')}
                                                        style={{ marginTop:5,width:82 }}
                                                    >
                                                        <div className='variantQty'>
                                                            <input
                                                                type="number"
                                                                className={[
                                                                    Classes.Input,
                                                                    Classes.bulkPackagingQtyInput
                                                                ].join(' ')}
                                                                style={{ paddingLeft: 8 }}
                                                                name="quantity"
                                                                key={item?.bulkPackingValue1 || 0}
                                                                defaultValue={item?.bulkPackingValue1 || 0}
                                                                onBlur={(e) =>
                                                                    handleBlurBulkPackagingQty1(
                                                                        e.target.value,
                                                                        item?.productId,
                                                                        item?.basicCollectionDataBrandData?.bulkPackingValue1,
                                                                        'bulkPackingValue1',
                                                                        item?.basicCollectionDataBrandData?.bulkPackingValue2,
                                                                        `${index}-bulkPacking1`
                                                                    )
                                                                }
                                                                onKeyDown={handleKeyUp}
                                                            />
                                                        </div>

                                                        <div>
                                                            <div
                                                                className={Classes.bulkPackagingIncQty}
                                                                onClick={(e) => incQtyBulkPackaging(
                                                                    e.target,
                                                                    item?.productId,
                                                                    item?.basicCollectionDataBrandData?.bulkPackingValue1,
                                                                    'bulkPackingValue1'
                                                                )}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faSortUp}
                                                                    style={{ fontSize:12,position:"relative",top:"-21%" }}
                                                                />
                                                            </div>

                                                            <div
                                                                className={Classes.bulkPackagingDecQty}
                                                                onClick={(e) => decQtyBulkPackaging(
                                                                    e.target,
                                                                    item?.productId,
                                                                    item?.basicCollectionDataBrandData?.bulkPackingValue1,
                                                                    'bulkPackingValue1'
                                                                )}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faSortDown}
                                                                    style={{ position:"relative",top:"-53%",fontSize:12 }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <p className={Classes.bulkPackagingValue} style={{ marginTop: 12 }}>
                                                        {item?.basicCollectionDataBrandData?.bulkPackingValue1}{" "}
                                                        {item?.basicCollectionDataBrandData?.unit ? item?.basicCollectionDataBrandData?.unit : 'PCS'}
                                                    </p>
                                                </div>
                                            )}

                                            <div
                                                className={Classes.QtyNegativeMsg}
                                                style={{ color: '#fff', marginTop: 5 }}
                                                id={`${index}-bulkPacking1`}
                                            >
                                                <p>{item?.basicCollectionDataBrandData?.bulkPackingType1} can't be Negative</p>
                                            </div>

                                            {item?.basicCollectionDataBrandData?.bulkPackingType2 && (
                                                <div style={{ display: 'flex' }} id='bulkPacking2'>
                                                    <p className={Classes.bulkPackagingType}>
                                                        {item?.basicCollectionDataBrandData?.bulkPackingType2}
                                                    </p>

                                                    <div
                                                        className={[Classes.bulkPackagingQtyBox,'variantQty'].join(' ')}
                                                        style={{ marginTop:5,width:82 }}
                                                    >
                                                        <div className='variantQty'>
                                                            <input
                                                                type="number"
                                                                className={[
                                                                    Classes.Input,
                                                                    Classes.bulkPackagingQtyInput
                                                                ].join(' ')}
                                                                style={{ paddingLeft: 8 }}
                                                                name="quantity"
                                                                key={item?.bulkPackingValue2 || 0}
                                                                defaultValue={item?.bulkPackingValue2 || 0}
                                                                onBlur={(e) =>
                                                                    handleBlurBulkPackagingQty2(
                                                                        e.target.value,
                                                                        item?.productId,
                                                                        item?.basicCollectionDataBrandData?.bulkPackingValue2,
                                                                        'bulkPackingValue2',
                                                                        item?.basicCollectionDataBrandData?.bulkPackingValue1,
                                                                        `${index}-bulkPacking2`
                                                                    )
                                                                }
                                                                onKeyDown={handleKeyUp}
                                                            />
                                                        </div>

                                                        <div>
                                                            <div
                                                                className={Classes.bulkPackagingIncQty}
                                                                onClick={(e) => incQtyBulkPackaging(
                                                                    e.target,
                                                                    item?.productId,
                                                                    item?.basicCollectionDataBrandData?.bulkPackingValue2,
                                                                    'bulkPackingValue2'
                                                                )}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faSortUp}
                                                                    style={{ fontSize:12,position:"relative",top:"-21%" }}
                                                                />
                                                            </div>

                                                            <div
                                                                className={Classes.bulkPackagingDecQty}
                                                                onClick={(e) => decQtyBulkPackaging(
                                                                    e.target,
                                                                    item?.productId,
                                                                    item?.basicCollectionDataBrandData?.bulkPackingValue2,
                                                                    'bulkPackingValue2'
                                                                )}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faSortDown}
                                                                    style={{ position:"relative",top:"-53%",fontSize:12 }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ marginTop: 12 }}>
                                                        <p className={Classes.bulkPackagingValue}>
                                                            {item?.basicCollectionDataBrandData?.bulkPackingValue2}{" "} 
                                                            {item?.basicCollectionDataBrandData?.unit ? item?.basicCollectionDataBrandData?.unit : 'PCS'}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}

                                            <div
                                                className={Classes.QtyNegativeMsg}
                                                style={{ color: '#fff', marginTop: 5 }}
                                                id={`${index}-bulkPacking2`}
                                            >
                                                <p>{item?.basicCollectionDataBrandData?.bulkPackingType2} can't be Negative</p>
                                            </div>
                                            {componentType && active && item?.districtForPrices && 
                                                <CapsuleChip capsulePart1={"District For Prices"} capsulePart2={item?.districtForPrices} colorChip={"#243750"} />
                                            }
                                            {item?.isParentProduct === true && 
                                                <ChildProductsList capsulePart1={"Bundle Product"} capsulePart2={`${item?.childProducts?.length} x SKUs`} colorChip={"#ff6200"} childProducts={item?.childProducts}/>
                                            }
                                            <div className={Classes.SubTotal} style={{ marginTop: 20 }}>
                                                <div className='subtotal' id='discTotalPrint'>
                                                    <p className={Classes.responsiveTotal}>Subtotal</p>
                                                    <p>&#8377;{formatNumber(item?.subTotal)}</p>
                                                </div>

                                                <div
                                                    className={['total',Classes.variantTotal].join(' ')}
                                                    id='discTotalPrint'
                                                >
                                                    <p className={Classes.responsiveTotal}>Total</p>
                                                    <p>&#8377;{roundOfUptoXDecimal(item.total)}</p>
                                                </div>
                                            </div>

                                            <div className={Classes.QuantityProductErrorMsg}>
                                                <p>Quantity Cannot be less than 1</p>
                                            </div>
                                        </div>
                                    </div>

                                    {componentType && active && (
                                        <div                                
                                            className={[Classes.hideDiscountResponsive,Classes.variantDiscountBox,'createOrderPercent'].join(' ')}
                                            id='discountIncWidPrint'
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>{formatNumber(item?.discountPercentage)}</p>
                                                <p>%</p>
                                            </div>
                                            {item?.appliedDiscount && ( 
                                                <div id='discountType' className={Classes.appliedDiscountType}>
                                                    {item?.appliedDiscount.title}
                                                </div>
                                            )}
                                            {item?.priceDataAffiliateData?.appliedDiscountTitle && (
                                                <p 
                                                    id='exclGSTPrint'
                                                    className={['exclGst',Classes.responsiveExclGst].join(' ')}
                                                >(Incl. GST)</p>
                                            )}
                                            <p > </p>
                                        </div>
                                    )}

                                    <div 
                                        className={['createOrderQty',Classes.createOrderQuantity].join(' ')}
                                        style={{ width:'14%' }}
                                    >
                                        <p>{item?.quantity}</p>
                                        <p className={Classes.QtyPCSHighlight}>
                                            {item?.basicCollectionDataBrandData?.unit ? item?.basicCollectionDataBrandData?.unit : 'PCS'}
                                        </p>
                                    </div>

                                    {componentType && active && (
                                        <div
                                            className={[Classes.hideTotalResponsive,Classes.variantProductTotal,'total'].join(' ')}
                                            id='discTotalPrint'
                                        >
                                            &#8377;{roundOfUptoXDecimal(item?.subTotal)}
                                        </div>
                                    )}

                                    {componentType && active && (
                                        <div
                                            className={[Classes.hideTotalResponsive,Classes.variantProductTotal,'total'].join(' ')}
                                            id='discTotalPrint'
                                        >
                                            &#8377;{roundOfUptoXDecimal(item.total)}
                                        </div>
                                    )}
                                </div>

                                <div className='removeIcon'>
                                    <FontAwesomeIcon
                                        icon={faXmark}
                                        className={Classes.removeProductInContainer}
                                        onClick={() => updateArr(item?.productId)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </Fragment>
    )
}

export default CreateOrderComponent;