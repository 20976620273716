import * as React from 'react';
import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Divider, Drawer, Box, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { setShow } from '../store/toggleNavSlice';
import NavSection from './nav-section';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Navbar = (props) => {
  const { navConfig, handleHover, isNavCollapse, handleNavigation } = props;
  const theme = useTheme();
  const [isNavSectionScroll , setIsNavSectionScroll] = useState(false);
  const show = useSelector((state) => state.toggleNav.show);
  const dispatch = useDispatch();
  
  return (
    <Box 
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: 240 },
      }}
    >
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: {
            width: drawerWidth,
            bgcolor: 'background.default',
            border: 'none',
            boxSizing:"border-box",
            boxShadow: '0px 1px 16px -5px grey',
            transition: 'all 0.3s ease-in-out'
          },
        }}
        open={show}
      >
        <DrawerHeader>
          <Box sx={{ display: 'inline-flex', width: '100%', justifyContent: 'center' }}>
            <img 
              style={{width: '100px'}} 
              src="https://depo24.in/static/media/logo.bde3bc246ae23cbc5bc1e5cd4efdaa78.svg" 
              alt="Depo24" 
            />
          </Box>
          <IconButton onClick={() => { dispatch(setShow({ show: !show })) }}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <NavSection
          data={navConfig}
          handleNavigation={handleNavigation}
          handleHover={handleHover}
          isNavCollapse={isNavCollapse}
          isNavSectionScroll ={isNavSectionScroll}
          setIsNavSectionScroll = {setIsNavSectionScroll}
          mobileResponsive={true}
        />
      </Drawer>
    </Box>
  );
}

export default Navbar;