import { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { Badge, Button, CircularProgress } from '@mui/material';
import { Add } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import axiosInstance from '../../../utility/axios-instance';
import { setUsers } from '../../../store/usersSlice';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Tooltip from '@mui/material/Tooltip';
import Table from '../../../components/table/table';
import LogoutIcon from '@mui/icons-material/Logout';

const UsersNew = (props) => {
    const users = useSelector((state) => state.users);
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const handleLogoutByAdmin = (e, id , currentlyActive) => {
        e.stopPropagation();
        setLoading2(true);
        if(id && currentlyActive) {
            axiosInstance.post(`/admin/auth/adminLogout`, { id: id })
            .then(res => {
                setSuccess(res.data?.message);
            }).catch(err => {
                setError(err.response?.data?.message);
            })
            .finally(() => {
                setLoading2(false);
            });
        }
    }
    
    const ActiveRenderCell = (row) => {
       return row?.active ? <BsCheckCircleFill color="green" /> : <BsXCircleFill color="red" />
    }

    const ServiceRenderCell = (row) => {
        return ['super-admin', 'admin'].includes(row?.role) ? (
            <div>
                <Chip label={"All"} style={{ margin: '3px' }} />
            </div>
        ) : (
            Array.isArray(row?.services) ? (
                <div>
                    {row?.services?.slice(0,2)?.map((service, index) => (
                        <Chip key={index} label={service?.service} style={{ margin: '3px' }} />
                    ))}
                    {row?.services?.length > 2 ? (
                        <Tooltip title={
                            <div>
                                {row?.services?.slice(2)?.map((service, index) => (
                                    <p>{service?.service}, </p>
                                ))}
                            </div>
                        } arrow>
                        <Chip size="small" label={`+ ${row?.services?.length - 2} `} style={{ margin: '3px', cursor: "pointer", opacity:'0.8'}} />
                        </Tooltip>
                    ) : null}
                </div>
            ) : (
                <Chip label={row?.services} style={{ margin: '3px' }} />
            )
        )
    }

    const CurrentlyActiveRenderCell = (row) => {
        return row?.currentlyActive && 
            <Button 
                variant='outlined' startIcon={<LogoutIcon />} 
                onClick={(e) => handleLogoutByAdmin(e, row?._id, row?.currentlyActive)}
            >Logout</Button>
            
    }

    const UserNameRenderCell = (row) => {
        return (
            <Badge color="success" badgeContent=" " invisible={!row?.currentlyActive ?? true} variant='dot'>
                {row?.username}
            </Badge>
        )
    }

    const columns = [
        { 
            field: 'username', 
            headerName: 'USERNAME', 
            flex: 1, 
            headerClassName: 'super-app-theme--header', 
            sortable: true,
            type: 'enum',
            tableName: 'admins',
            columnName: 'username',
            renderCell: (props) => ( <UserNameRenderCell {...props?.row} />),
            customRenderCell: UserNameRenderCell,
        },
        { 
            field: 'createdAt', 
            headerName: 'CREATED AT', 
            flex: 1.5, 
            headerClassName: 'super-app-theme--header', 
            filterable: false, 
            sortable: true 
        },
        { 
            field: 'name', 
            headerName: 'NAME', 
            flex: 1, 
            headerClassName: 'super-app-theme--header', 
            sortable: true,
            type: 'enum',
            tableName: 'admins',
            columnName: 'name',
        },
        { 
            field: 'role', 
            headerName: 'ROLE', 
            flex: 1, 
            headerClassName: 'super-app-theme--header', 
            sortable: true,
            type: 'enum',
            tableName: 'admins',
            columnName: 'role',
        },
        { 
            field: 'defaultLocation', 
            headerName: 'LOCATION', 
            flex: 1, 
            headerClassName: 'super-app-theme--header', 
            sortable: true,
            type: 'enum',
            tableName: 'admins',
            columnName: 'default_location',
        },
        {
            field: 'active',
            headerName: 'ACTIVE',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            sortable: true,
            flex: .5,
            renderCell: (props) => ( <ActiveRenderCell {...props?.row} /> ),
            customRenderCell: ActiveRenderCell,
            
        },
        {
            field: 'services',
            headerName: 'SERVICES',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: true,
            flex: 2,
            renderCell: (props) => ( <ServiceRenderCell {...props?.row} /> ),
            customRenderCell: ServiceRenderCell,
        },
        {
            field: 'currentlyActive',
            headerName: 'LOGOUT BY ADMIN',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            flex: 1,
            renderCell: (props) => ( <CurrentlyActiveRenderCell {...props?.row} /> ),
            customRenderCell: CurrentlyActiveRenderCell,
        }
    ];

    if (document.querySelector('.MuiDataGrid-footerContainer')) {
        document.querySelector('.MuiDataGrid-footerContainer').style.display = 'none';
    }

    const [loading2, setLoading2] = useState(false);
    const [success, setSuccess] = useState(null);

    const syncAdmins = async () => {
        setLoading2(true);

        axiosInstance.post('/admin/syncAdmins').then(res => {
            setSuccess(res.data?.message);
        }).catch(err => {
            setError(err.response?.data?.message);
        }).finally(() => {
            setLoading2(false);
        });
    }

    return (
        <Fragment>
            <div style={{ display:'flex',justifyContent:'flex-end',marginTop:-25 }}>
                <Button
                    variant="contained"
                    color="primary"
                    size='small'
                    onClick={() => syncAdmins()}
                    style={{ marginLeft: '10px' }}
                >
                    {loading2 ? <CircularProgress size={24} /> : 'Sync Admins'}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size='small'
                    startIcon={<Add />}
                    onClick={() => navigate('/users/new')}
                    style={{ marginLeft: '10px' }}
                >
                    Add New
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<ManageAccountsIcon />}
                    onClick={() => navigate('/users/roles')}
                    style={{ marginLeft: '10px' }}
                    size='small'
                >
                    Roles
                </Button>
            </div>

            {error ? (
                <Alert variant="danger" onClose={() => setError(false)} dismissible>
                    <Alert.Heading>{error}</Alert.Heading>
                    <p>Try reloading!</p>
                </Alert>
            ) : null}

            {success ? (
                <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                    <Alert.Heading>{success}</Alert.Heading>
                </Alert>
            ) : null}

                <Table
                    columns={columns}
                    dataPosition={"users"}
                    endpoint={'/admin/'}
                    rowId={'_id'}
                    checkBoxSelection={false}
                    service={'users'}
                    tabName={'usersNew'}
                    setData={setUsers}
                    data={users}
                    version='version-4'
                    navigateOnRowClickEndpoint={`/users`}
                    mobileResponsive={true}
                    exportEndpoint={'/exports/admins'}
                    exportFileName={`Users - ${new Date()}`}
                    clickable={true}
                />
        </Fragment>
    );
};

export default UsersNew;