import * as React from 'react';
import Table from '../../../components/table/table';
import { Fragment, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Classes from '../customers/css/customer.module.css';
import ButtonComponent from '../../../components/button';
import { ImportFileComponent } from '../../../components/importFileComponent';
import InventoryAdjustmentDialog from './addNewInventoryDialog';
import ExportDatas from '../../../components/exportDataDialog';
import Button from '@mui/material/Button';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';
import { useSelector } from 'react-redux';
import { setInventoryAdjustments } from '../../../store/inventoryAdjustments';

const columns = [
  {
    field: `sku`,
    headerName: `SKU`,
    flex: 1,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'left',
    align: 'left',
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: `bin_code`,
    headerName: `Bin Code`,
    flex: 1,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'left',
    align: 'left',
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: `adjusted_qty`,
    headerName: `Adjusted Quantity`,
    flex: 1,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'left',
    align: 'left',
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: `mode`,
    headerName: `Mode`,
    flex: 0.6,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'left',
    align: 'left',
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: `remark`,
    headerName: `Remark`,
    flex: 1,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'left',
    align: 'left',
    editable: false,
    sortable: true,
    filterable: false,
  },
  {
    field: `location_code`,
    headerName: `Location Code`,
    flex: 1,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'left',
    align: 'left',
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: `created_by`,
    headerName: `Created By`,
    flex: 1,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'left',
    align: 'left',
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: `created_at`,
    headerName: `Created At`,
    flex: 1.5,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'left',
    align: 'left',
    editable: false,
    sortable: true,
    filterable: false,
  },
];

export default function InventoryAdjustments() {
  const [open, setOpen] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [openExport,setOpenExport] = useState(false);

  const inventoryAdjustments = useSelector((state) => state.inventoryAdjustments);

  const openDialog = () => {
    setOpen(true);
  }

  const closeDialog = () => {
    setOpen(false);
  }

  const handleExportDialog = () => {
    setOpenExport(true);
  }

  const handleClose = () => {
    setOpenExport(false);
  }

  return (
    <Fragment>
      <div
        className="d-flex justify-content-between"
        id='header'
        style={{ marginTop: "10px",flexWrap:'wrap' }}
      >
        <div style={{display:'flex'}}>
          <Permission service={services?.inventoryAdjustments} permission={"create"}>
            <ButtonComponent Icon={<AddCircleIcon />} open={openDialog} />
          </Permission>
          <div>
            <Button 
              variant="contained" color="primary" 
              sx={{ textTransform:'capitalize',marginLeft:"10px" }} 
              onClick={handleExportDialog}
            >
              Export
            </Button>
          </div>
        </div>

        <div style={{marginTop:-25}}>
          <p>Inventory Create/ Update via CSV will override existing values</p>
          <ImportFileComponent
            endpoint="/inventory/createInventory"
            serviceName={services?.inventory}
            type="inventory"
            templateDownloadEndpoint={"/templates/inventoryAdjustmentCsv"}
            setReRender={setReRender}
          />
        </div>
      </div>

      <Table 
        version="version-2" 
        rowId={'id'} 
        columns={columns} 
        dataPosition={"fetchedInventoryAdjustment"} 
        endpoint={'/inventory/fetchInventoryAdjustments'} 
        checkBoxSelection={false} 
        reRender={reRender} 
        service={'inventoryAdjustments'}
        tabName={'Inventory Adjustments'}
        setData={setInventoryAdjustments}
        data={inventoryAdjustments}
        mobileResponsive={true}
      />

      <div className={open ? Classes.UpdateSalesDialog : undefined}>
        <InventoryAdjustmentDialog
          showPopup={open}
          hidePopup={closeDialog}
          reRender={reRender}
          setReRender={setReRender}
        />
      </div>

      <ExportDatas 
        open={openExport}
        handleClose={handleClose}
        endPoint={'/exports/inventoryAdjustments'}
        dataName="Inventory Adjustments"
      />
    </Fragment>
  );
}