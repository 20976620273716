import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


import { ListItemText } from '@mui/material';

import Classes from './home.module.scss';
import Navbar  from '../../components/navbar';
import Nav from '../../layouts/nav';
import Breadcrumb from '../../components/breadcrumb';
import { useState, useEffect } from 'react';

import { NavLink as RouterLink } from 'react-router-dom';

import navConfig from '../../layouts/nav/config';

import Permission from '../../pages/auth/permissions';

import { StyledNavItem, StyledNavItemIcon } from '../../components/nav-section/styles';
import PushPinIcon from '@mui/icons-material/PushPin';
import { setPinTab } from '../../store/pinTabSlice';
import SnackBar from '../../components/SnackBar';

const Home = ({ isCreateOrderPage, setIsCreateOrderPage, collapseNavbar, isNavCollapse, navWidth }) => {

	const groupLabels = {
		dashboard : 'Dashboard',
        ordersNew: 'Orders',
        draftOrder : 'Draft Orders',
        purchase_orders: 'Purchase Orders',
        products: 'Products',
				productTransactions :"Products Transactions",
        inventory: 'Inventory',
        users: 'Users',
        customers: 'Customers',
        createOrder: 'Create Order',
        createPurchaseOrder: 'Create Purchase Order',
        draftPurchaseOrders: 'Draft Purchase Orders',
        draftOrders: 'Draft Orders',
        purchaseOrders : 'Purchase Orders',
        inboundPurchaseOrders : 'Inbound Purchase Orders',
        productsCreated: 'Report Service',
        inventoryView : 'Inventory View',
        inventoryMove: 'Inventory Move',
        inventoryAdjustments: 'Inventory Adjustments',
        bins: 'Bins',
        blog : 'Blog',
        affiliates : 'Affiliates',
        fieldSales : 'Field Sales',
        returnsNew : 'Returns',
        cancelsNew : 'Cancels',
        'refundsNew' : 'Refunds',
        dispatch : 'Dispatches',
        updatePricing : 'Update Pricing',
        uploadImages : 'Upload Images',
        uploadOthersMaster : 'Upload Others Master',
        skuToQrcodeBarcode : 'SKU to Qrcode/Barcode',
        myTasks: 'My Tasks',
        myCustomers: 'My Customers',
        reports: 'Sales User',
		deliveryRiders: 'Delivery Riders',
		schemeDashboard: 'Scheme Dashboard',
		schemes: 'Schemes',
		orderAllocations: "Order Allocations",
		skuTransactions: "SKU Transactions",
		locations: "Locations",
		estimates: "Estimates",
		commissions: "Commissions",
		createCommission: "Create Commission",
		affiliateCommissions: "Affiliate Commissions",
		deliveryShipping: 'Delivery Shipping',
		pickList: "Pick List",
		qc: "QC",
		pack: "Pack",
		dispatch: "Dispatch",
		onHold: "On Hold",
		inwardReturns: "Inward Returns",
		pushNotifications: "Push Notifications",
		inwardPurchaseOrders: "Inward Purchase Orders",
		settings : 'Settings'
    };

	const auth = useSelector((state) => state.auth);
	const show = useSelector((state) => state.toggleNav.show);
	const [hoveringList, setHoveringList] = useState(null);
	const [showhoveringList, setShowHoveringList] = useState(false);
	const [hoverListTop, sethoveringListTop] = useState(0);
	const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

	const dispatch = useDispatch();
	const location = useLocation();
	const pinTab = useSelector((state) => state.pinTab);

	const navigate = useNavigate();
	const handleNavigation = (event, destination) => {
		if (isCreateOrderPage) {
		event.preventDefault();
		const confirmNavigation = window.confirm(
			"Do you want to quit this page?"
		);
		if (confirmNavigation) {
			setIsCreateOrderPage(false);
			navigate(destination);
			// history.push(destination);
		}
		}
	};

	// Set default path to dashboard when component mounts
	useEffect(() => {
    if (auth.user.role === "super-admin" || auth.user.role === "admin") {
      if (location.pathname === "/") {
        navigate("/dashboard");
      }
    }
  }, [location, navigate]);

	try {
		const pathnames = location.pathname.split('/').filter((x) => x);
		if (pathnames.length > 0) {
			document.title = `DEPO24 | Portal - ${groupLabels[pathnames[0]] ? groupLabels[pathnames[0]] : pathnames[0]}`
		}
	} catch (err) {
		console.log(err)
	}

	const handleHover = (event, item, show) => {
		sethoveringListTop(event.clientY);
		setHoveringList(item);
		setShowHoveringList(show);
	}

	const handlePinTab = (e,tabName,path,dispatch, pinTab,setSnackBar) => {
		const multiTabName = window?.location?.pathname?.split('/')?.[2] ? window?.location?.pathname?.split('/')?.[2]: null;
		const checkTab = Object.keys(pinTab)?.some((key) => pinTab[key]?.name === null);
		if (!checkTab) {
		  setSnackBar((prevSnackBar) => {
			return { ...prevSnackBar, display: true, message: "You can pin only 4 tabs", type: "error" }
		  });
		} else {
		  for (const key in pinTab) {
			if(!pinTab[key]?.name) {
			  dispatch(setPinTab({ ...pinTab, [key]:{ name: tabName, url:path, multiTabName:multiTabName }  }));
			  return;
			}
		  }
		}
	}
	  
	const handleRemovePinnedTab = (e,tabName,dispatch, pinTab) => {
		for (const key in pinTab) {
		  if(pinTab[key]?.name === tabName) {
			dispatch(setPinTab({ ...pinTab, [key]:{ name: null, url:null, multiTabName:null }  }));
			return;
		  }
		}
	}

	function NavItem({ item, isNavCollapse }) {
		const { title, path, icon, info } = item;

		return (
			<div style={{display:'flex',padding:'0px 5px'}} className='Item'>
				<StyledNavItem
					component={RouterLink}
					onClick={(e) => handleNavigation(e, path)}
					to={path}
					sx={{
					'&.active': {
						color: 'text.primary',
						bgcolor: 'action.selected',
						fontWeight: '550',
					},
					'&:hover' : {
						color: 'rgb(255, 98, 0)'
					}
					}}
				>
					<StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
			
					{!isNavCollapse && <ListItemText disableTypography primary={title} />}
			
					{info && info}
				</StyledNavItem>

				{Object.keys(pinTab)?.some((key) => pinTab[key]?.name === title) ? (
					<div style={{margin:'auto 0px',cursor:'pointer'}} className='Icon'> 
					<img 
						src="https://www.svgrepo.com/show/436850/pin-slash-fill.svg"  
						alt="" srcset="" width='18px' height='16px'
						onClick={(e) => handleRemovePinnedTab(e,title,dispatch,pinTab)}
					/>
					</div>
				) : (
					<PushPinIcon 
						style={{fontSize:18,margin:'auto 0px',cursor:'pointer'}} 
						onClick={(e) => handlePinTab(e,title,path,dispatch,pinTab,setSnackBar)}
					/>
				)}
			</div>
		);
	}

	return (
		<div className=".container-fluid d-flex flex-grow-1 position-relative">
			<div
				className={[
					"p-3",
					auth.user.role === "delivery-boy" ? "d-none" : "d-flex",
					Classes.nav,
					"flex-column",
				].join(" ")}
				style={{
					width: navWidth,
					position: 'relative',
					display: "flex",
					transition: 'all 0.3s ease-in-out'
				}}
			>
				<Nav
					auth={auth}
					navConfig={navConfig}
					isCreateOrderPage={isCreateOrderPage}
					setIsCreateOrderPage={setIsCreateOrderPage}
					collapseNavbar={collapseNavbar}
					handleHover={handleHover}
					isNavCollapse={isNavCollapse}
					handleNavigation={handleNavigation}
					navWidth={navWidth}
				/>
				{
					(isNavCollapse && showhoveringList) &&
					<div
						className='hovering-list'
						style={{top: hoverListTop - 150}}
						onMouseEnter={(event) => setShowHoveringList(true)}
						onMouseLeave={(event) => setShowHoveringList(false)}
						>
						{
							hoveringList?.childs?.map((childItem, index) => 
								<div key={childItem.title + index}>
									<Permission service={childItem?.serviceName} permission={childItem?.permission}>
										<NavItem item={childItem} />
									</Permission>
								</div>
							)
						}
					</div>
				}
			</div>
			<div
				id="removePadding"
				className={(isNavCollapse ? Classes.NavCollapsed : Classes.DynamicDash)}
				style={{
					position: 'relative',
					padding: '0.5rem',
				}}
			>
				<Breadcrumb />
				<Outlet />
			</div>
			{auth.authenticated ? null : <Navigate to="/sign-in" />}

			<div
				className={[
					auth.user.role === "delivery-boy" ? "d-none" : "d-flex",
					Classes.navToggle
				].join(" ")}
				style={{ transition: 'all 0.3s ease-in-out' }}
			>
				{show && ( 
					<Navbar 
						navConfig={navConfig}
						handleHover={handleHover}
						isNavCollapse={isNavCollapse}
						handleNavigation={handleNavigation} 
					/> 
				)}
			</div>
			<SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
		</div>
	);
};

export default Home;
