import React, { Fragment, useEffect,useState } from "react";
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from "../../../utility/axios-instance";
import Spinner from '../../../components/spinner';
import SnackBar from '../../../components/SnackBar';
import { Autocomplete, TextField } from '@mui/material';

const SalesUserDialog = (props) => {
    const [loading,setLoading] = useState(false);
    const [user,setUser] = useState('');
    const [users,setUsers] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const {
        pickListOpen,
        handlePickListClose,
        pickList,
        setReRender
    } = props;

    useEffect(() => {
        const query = {
            type: "picker",
            service: "picker",
            locationCode: pickList[0]?.locationCode
        };

        axiosInstance
            .get(`/admin/allPiAdmin`, { params: query })
            .then((res) => {
            setUsers(res?.data?.data);
            });
    }, [pickList]);

    const handlePickOrder = (event) => {
        event.preventDefault();
        setLoading(true);

        const data = {
            "payload" :[
                {
                    // "picklist_number":pickList?.length !== 0 && qc[0]?.pickListNo,
                    "deliveryNumber":pickList?.length !== 0 && pickList[0]?.deliveryNo,
                    "picker": event.target.picker.value
                }
            ]
        }

        // setReRender(false);
        axiosInstance
            .post('/picklist/generate-picklist', {...data})
            .then((res) => {
                setLoading(false);
                setReRender((prev) => !prev);
                const successMessage = `Order Pick Successfully`
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                handlePickListClose()
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setReRender((prev) => !prev);
                setLoading(false);
            })
    }

    return (
        <Fragment>
            {pickListOpen && (
                <div
                    className={Classes.confirmModal}
                    style={{ bottom: '20%',top:'24%',boxShadow:'0 0px 9px 0' }}
                >
                    <form method='post' onSubmit={handlePickOrder}>
                        <div style={{padding:20}}>
                            <Autocomplete
                                id="picker"
                                name="picker"
                                disablePortal
                                options={users ? users : [{name:"Loading...",id:0}]}
                                sx={{width: '100%',"& label": {top: user? "0%":"-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                getOptionLabel={(user) => user.name}
                                ListboxProps={{sx: { fontSize: 14 }}}
                                onChange={(event, newValue) => { setUser(newValue?.name) }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Picker"
                                        variant="outlined"
                                        required
                                    />
                                )}
                            />
                        </div>

                        <div className={Classes.ConfirmBtnBox} style={{bottom:'18%'}}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={handlePickListClose}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='addCustomer' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                GENERATE PICK
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {loading ? <Spinner /> : null}
            
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

        </Fragment>
    );
}


export default SalesUserDialog;