import { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Classes from './css/draftOrderDetailPage.module.css';
import Aside from './aside/aside';
import axiosInstance from '../../../utility/axios-instance';
import { BiArrowBack } from 'react-icons/bi';
import { AiFillCloseCircle, AiFillCheckCircle, AiFillStar } from 'react-icons/ai';
import QcPopup from './qcPopup';
import TaxRatesPopup from './TaxRates_popup';
import QcPopupProcurement from './qcPopupProcurementWise';
import Permission from '../../auth/permissions';
import { services } from '../../../utility/checkRoleBasedPermission';
import SnackBar from '../../../components/SnackBar';
import moment from 'moment';

const InboundPurchaseOrderDetail = (props) => {
    const [showTaxRate, setTaxRates] = useState(false);
    const [draftOrder, setDraftOrder] = useState([]);
    const [totalItems, setTotalItems] = useState(0)
    // store deliveryRemark & notes in State
    const [notes, setNotes] = useState('');
    const [deliveryRemark, setDeliveryRemark] = useState('');
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });

    // sendExistOrder
    const [completeExistOrder, setCompleteExistOrder] = useState([]);
    const [groupedProcuredLineItems, setGroupedProcuredLineItems] = useState([]);
    const [bins, setBins] = useState([]);
    const [saleBins, setSaleBins] = useState([]);
    const [showConfirmPopup, setConfirmShowPopup] = useState(false);
    const [procurementName, setProcurementName] = useState('');
    const [lineItem, setLineItem] = useState({});
    const [showConfirmQc, setShowConfirmQc] = useState(false);
    const [showConfirmProcurementQc, setShowConfirmProcurementQc] = useState(false);
    const [showConfirmProcurementPopup, setConfirmShowProcurementPopup] = useState(false);
    const [selectedQcBin, setSelectedQcBin] = useState('');
    const [binCodeForRecAllQty, setBinCodeForRecAllQty] = useState(null);
    const [forceClose, setForceClose] = useState(false);
    const [defaultStagingBin, setDefaultStagingBin] = useState("");
    const [defaultSaleableBin, setDefaultSaleableBin] = useState("");
    const [defaultFiles, setDefaultFiles] = useState([]);
    const [documentsAttached, setDocumentsAttached] = useState([]);

    const hideConfirmPopupWindow = () => {
        setProcurementName('');
        setLineItem({})
        setConfirmShowPopup(false);
    }

    const hideConfirmPopupWindow2 = () => {
        setProcurementName('');
        setConfirmShowProcurementPopup(false);
    }

    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-IN');
        }
    }

    const formatNumber = (number) => {
        const formattedNumber = parseFloat(number).toFixed(2);
        return formattedNumber;
    };

    const navigate = useNavigate();

    const { id } = useParams();

    const handlePartiallyDispatchOrder = (event) => {
        event.target.disabled = true;
        event.target.innerHTML = 'Dispatching...';
        axiosInstance
            .put(`/purchaseOrders/partiallyDispatchPurchaseOrder/${draftOrder.purchaseOrderName}`)
            .then((response) => {
                navigate('/inboundPurchaseOrders');
            })
            .catch((error) => {
                console.log(error);
                event.target.innerHTML = 'Partially Dispatch';
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: error?.response?.data?.message, type: "error" }
                });
                event.target.disabled = false;
            })
    }

    const handleFullDispatchOrder = (event) => {
        event.target.disabled = true;
        event.target.innerHTML = 'Dispatching...';
        axiosInstance
            .put(`/purchaseOrders/dispatchPurchaseOrder/${draftOrder.purchaseOrderName}`)
            .then((response) => {
                navigate('/inboundPurchaseOrders');
            })
            .catch((error) => {
                console.log(error);
                event.target.innerHTML = 'Dispatch Order';
                setSnackBar((prevSnackBar) => {
                  return {
                    ...prevSnackBar,
                    display: true,
                    message: error?.response?.data?.message,
                    type: "error",
                  };
                });
                event.target.disabled = false;
            })
    }

    const handleTaxRates = () => {
        setTaxRates(true);
    };

    const hideTaxPopup = () => {
        setTaxRates(false);
    }

    const getBins = (locCode) => {
        axiosInstance
            .get(`/inventory/bins?locationCode=${locCode}&type=Staging`)
            .then((response) => {
                setBins(response.data.data.bins);
                const defaultObj = response.data.data.bins.find((bin) => bin.default === true)
                if (defaultObj) {
                    setDefaultStagingBin(defaultObj.code);
                    setBinCodeForRecAllQty(defaultObj.code);
                }
            })
        axiosInstance
            .get(`/inventory/bins?locationCode=${locCode}&type=Saleable`)
            .then((response) => {
                setSaleBins(response.data.data.bins);
                const defaultObj = response.data.data.bins.find((bin) => bin.default === true)
                if (defaultObj) {
                    setDefaultSaleableBin(defaultObj.code);
                    setSelectedQcBin(defaultObj.code);
                }
            })
    }

    const handlePaidPurchaseOrder = (e) => {
        // e.target.disabled = true;
        e.target.innerHTML = 'Paying...';
        const data = {
            "PoPaymentDetail": {
                "poPaymentDetailId": draftOrder.PoPaymentDetails.poPaymentDetailId,
                "paymentStatus": "Paid"
            }
        }
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));

        axiosInstance
            .put(`/purchaseOrders/purchaseOrder/${draftOrder.purchaseOrderName}`, formData)
            .then((response) => {
                navigate('/inboundPurchaseOrders');
            })
            .catch((error) => {
                console.log(error);
                e.target.innerHTML = 'Paid';
                setSnackBar((prevSnackBar) => {
                  return {
                    ...prevSnackBar,
                    display: true,
                    message: error?.response?.data?.message,
                    type: "error",
                  };
                });
                e.target.disabled = false;
            })
    }

    const handleDocumentsUpdate = async (e) => {
        e.target.innerHTML = 'Uploading...';
        let data = {
            "documentsAttached": defaultFiles
        };
        const formData = new FormData()
        for (let i = 0; i < documentsAttached.length; i++) {
            formData.append("documentsAttached", documentsAttached[i]);
        }
        formData.append('data', JSON.stringify(data));

        await axiosInstance
            .put(`/purchaseOrders/purchaseOrder/${draftOrder.purchaseOrderName}`, formData)
            .then((response) => {
                navigate('/inboundPurchaseOrders');
            })
            .catch((error) => {
                console.log(error);
                e.target.innerHTML = 'Update Docs';
                setSnackBar((prevSnackBar) => {
                  return {
                    ...prevSnackBar,
                    display: true,
                    message: error?.response?.data?.message,
                    type: "error",
                  };
                });
                e.target.disabled = false;
            })

    }

    const handleCopyPo = (event) => {
        event.target.innerHTML = 'Copying Order...';
        axiosInstance
            .post(`/purchaseOrders/copyPoToDpo/${draftOrder.purchaseOrderName}`)
            .then((res) => {
                navigate(`/draftPurchaseOrders`)
            })
            .catch((error) => {
                event.target.innerHTML = 'Copy Order';
                setSnackBar((prevSnackBar) => {
                  return {
                    ...prevSnackBar,
                    display: true,
                    message: error?.response?.data?.message,
                    type: "error",
                  };
                });
            })
    }

    const handleQcApproveCheck = (binCode) => {
        axiosInstance
            .post(`/purchaseOrders/qualityCheckPurchaseOrder?purchaseOrderName=${draftOrder?.purchaseOrderName}&binCode=${binCode}&procurementName=${procurementName}&qcStatus=QC-Pass`)
            .then((res) => {
                navigate('/inboundPurchaseOrders');

            })
            .catch((err) => {
                console.log(err);
            });
        setConfirmShowPopup(false);
        setSelectedQcBin("");
        setShowConfirmQc(false);
        setProcurementName("");
    }

    const handleQcRejectCheck = (e) => {
        axiosInstance
            .post(`/purchaseOrders/qualityCheckPurchaseOrder?purchaseOrderName=${draftOrder?.purchaseOrderName}&binCode=aman-1&procurementName=${procurementName}&qcStatus=QC-Fail`)
            .then((res) => {
                navigate('/inboundPurchaseOrders');

            })
            .catch((err) => {
                console.log(err);
            });
        setConfirmShowPopup(false);
        setSelectedQcBin("");
        setProcurementName("");

    }
    const handleForceClose = (e) => {
        // e.target.disabled = true;
        e.target.innerHTML = 'Closing...';
        axiosInstance
            .put(`/purchaseOrders/forceClosePo/${draftOrder.purchaseOrderName}`)
            .then((res) => {
                navigate(`/inboundPurchaseOrders`)
                const successMessage = res?.data?.message;
                setSnackBar((prevSnackBar) => {
                    return {
                        ...prevSnackBar,
                        display: true,
                        message: successMessage || "Force Close Successful",
                        type: "success",
                    };
                });
            })
            .catch((error) => {
                e.target.innerHTML = 'Force Close';
                const errorMessage = error?.response?.data?.message;
                setSnackBar((prevSnackBar) => {
                    return {
                        ...prevSnackBar,
                        display: true,
                        message: errorMessage || "Force Close Failed",
                        type: "success",
                    };
                });
            })
    }

    const fetchPurchaseOrder = () => {
        if (id) {
            axiosInstance
                .get(`/purchaseOrders/purchaseOrder/${id}`)
                .then((response) => {
                    let ti = 0;
                    for (
                        let i = 0;
                        i < response?.data?.data?.PoLineItems?.procuredLineItems?.length;
                        i++
                    ) {
                        ti += response?.data?.data?.PoLineItems?.procuredLineItems[i].quantity;
                    }
                    for (
                        let i = 0;
                        i < response?.data?.data?.PoLineItems?.unprocuredLineItems?.length;
                        i++
                    ) {
                        ti += response?.data?.data?.PoLineItems?.unprocuredLineItems[i].quantity;
                    }
                    setTotalItems(ti);
                    setDraftOrder(response.data.data)
                    setDeliveryRemark(response.data.data.deliveryRemark);
                    setNotes(response.data.data.notes)
                    let arrOfFiles = [];
                    for (let i = 0; i < response?.data?.data?.documentsAttached.length; i++) {
                        arrOfFiles.push(response?.data?.data?.documentsAttached[i])
                    }
                    setDefaultFiles(arrOfFiles)

                    if (response.data?.data?.PoLineItems?.procuredLineItems?.length !== 0 && response.data?.data?.PoLineItems?.unprocuredLineItems?.length === 0 && response.data?.data?.status !== "Closed") {
                        setForceClose(true);
                    }
                    setCompleteExistOrder([response.data.data.purchaseOrderName]);
                    setGroupedProcuredLineItems(response?.data?.data?.PoLineItems?.procuredLineItems?.reduce((groups, item) => {
                        const procurementName = item.procurementName;
                        if (!groups[procurementName]) {
                            groups[procurementName] = [];
                        }
                        groups[procurementName].push(item);
                        return groups;
                    }, {}))
                    getBins(response?.data?.data?.PoLocationDetails[0]?.locationCode, "Staging");
                });
        }
    }


    useEffect(() => {
        fetchPurchaseOrder();
    }, [id])

    return (
        <Fragment>
            <div className={Classes.container} id="color">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    id='hideHeader'
                >
                    <div
                        className="d-flex align-items-center"
                        style={{ gap: '1rem' }}
                    >
                        <div>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <BiArrowBack />
                            </button>
                        </div>

                        <div>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <h6 className={Classes.draftOrderDetail}>
                                    {draftOrder?.purchaseOrderName}
                                </h6>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <p style={{
                                        padding: '2px 10px',
                                        borderRadius: '10px',
                                        backgroundColor: draftOrder.status === 'Completed' ? '#d4edda' : '#ffd79d'
                                    }}>{draftOrder.status}</p>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <p style={{
                                        padding: '2px 10px',
                                        borderRadius: '10px',
                                        backgroundColor: '#FFEA8A'
                                    }}>{draftOrder?.PoPaymentDetails?.paymentStatus}</p>
                                </div>
                            </div>

                            <h6 className={Classes.draftOrderDetail}>
                                {moment(draftOrder.createdAt).format('MMM DD, YYYY h:mm:ss A')}
                            </h6>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 10,
                        gap: '12px'
                    }}>
                        <Permission service={services?.draftPurchaseOrders} permission="create">
                            <button
                                onClick={handleCopyPo}
                                style={{ backgroundColor: 'black', color: '#fff' }}
                                className={[Classes.Btn, Classes.sendPIButton].join(' ')}
                            >
                                Copy PO
                            </button>
                        </Permission>
                    </div>
                </div>



                {draftOrder.length !== 0 && (
                    <div className={Classes.draftOrderName} id='orderNamePrint'>
                        <h6 style={{ marginRight: '5px' }}>Order Name:-</h6>
                        <p>{draftOrder?.draftOrder?.name}</p>
                    </div>
                )}

                <div
                    className={Classes.mainContainer}
                    style={{ marginTop: '30px' }}
                >
                    <div
                        className={Classes.containBoxes}
                        id='productContainer'
                    >
                        <div
                            className={[
                                Classes.Box,
                                'box'
                            ].join(' ')}
                        >
                            {draftOrder.length !== 0 ? (
                                <div
                                    style={{ fontSize: '14px', padding: 0 }}
                                >
                                    <div className={Classes.Thead}>
                                        <div
                                            style={{
                                                padding: '10px 20px',
                                                fontWeight: 600
                                            }}
                                            className={Classes.ProductHeading}
                                            id='productHeading'
                                        >
                                            <p>Product</p>
                                        </div>

                                        <div
                                            className={[
                                                Classes.hideResponsive,
                                                'discount'
                                            ].join(' ')}
                                            style={{
                                                width: '15%',
                                                padding: '10px 0px'
                                            }}
                                        >
                                            <p style={{ fontWeight: 600 }}>
                                                Discount
                                            </p>
                                            <p
                                                style={{ fontSize: '13px' }}
                                                id='hideInclGst'
                                            >
                                                (Incl. GST)
                                            </p>
                                        </div>
                                        <div
                                            className={Classes.hideResponsive}
                                            style={{ width: '10%', padding: '10px 0px' }}
                                        >
                                            <p style={{ fontWeight: 600 }}>
                                                Quantity
                                            </p>
                                            <p
                                                style={{ fontSize: '11px' }}
                                            >
                                                (Expected)
                                            </p>
                                        </div>

                                        <div
                                            className={Classes.hideResponsive}
                                            style={{ padding: '10px 0px 10px 10px', width: '13%' }}>
                                            <p style={{ fontWeight: 600, paddingLeft: 5 }}>
                                                Total
                                            </p>
                                            <p>(Excl. GST)</p>
                                        </div>
                                    </div>
                                    {draftOrder?.PoLineItems?.unprocuredLineItems?.length !== 0 && (
                                        <div className={Classes.Thead} style={{
                                            padding: '10px 20px',
                                            fontWeight: 600, alignItems: 'center', gap: '10px', color: 'red'
                                        }} >
                                            <AiFillCloseCircle />
                                            <p
                                                style={{

                                                }}>UnProcured Items
                                            </p>
                                        </div>
                                    )}

                                    <div id='productContainer'>
                                        {draftOrder?.PoLineItems?.unprocuredLineItems?.map((item, index) => (
                                            <div
                                                className={[
                                                    Classes.containProduct,
                                                    'variantProduct'
                                                ].join(' ')}
                                                // id={item.lineItemId}
                                                key={index}
                                            >
                                                <div
                                                    className={[
                                                        Classes.productDetail,
                                                        'products'
                                                    ].join(' ')}
                                                    id={index}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        <img
                                                            src={'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                                            alt="icon"
                                                            className={Classes.productImage}
                                                        />
                                                    </div>

                                                    <div className={Classes.VariantDetail}>
                                                        <div>
                                                            <p style={{ color: '#0d6efd' }}>
                                                                {item.productTitle}
                                                            </p>
                                                        </div>
                                                        <div style={{ color: '#6d7175' }}>
                                                            {/* <p>{item.node.variantTitle}</p> */}
                                                            <p>{item.sku}</p>
                                                        </div>
                                                        <div style={{ display: 'flex' }}
                                                            className={[
                                                                Classes.rateFontSize,
                                                                'rateExcl hidePricePrint'
                                                            ].join(' ')}
                                                        >
                                                            <p className={Classes.newRateExclGst}>
                                                                Subtotal (Exc. GST)
                                                            </p>
                                                            <div className={Classes.DiscountedUnitPrice}>
                                                                <p style={{ marginRight: 2 }}>&#8377;</p>
                                                                <p>{formatNumber(item?.discountedSubTotal)}</p>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex' }}
                                                            className={[
                                                                Classes.rateFontSize,
                                                                'rateExcl hidePricePrint'
                                                            ].join(' ')}
                                                        >
                                                            <p className={Classes.newRateExclGst}>
                                                                Net Price
                                                            </p>
                                                            <div className={Classes.DiscountedUnitPrice}>
                                                                <p style={{ marginRight: 2 }}>&#8377;</p>
                                                                <p>{item.discountedUnitPrice}</p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{ display: 'flex' }}
                                                            className={[Classes.rateFontSize, 'rateExcl'].join(' ')}
                                                        >
                                                            <p className={Classes.newRateExclGst}>
                                                                MRP
                                                            </p>
                                                            <div className={Classes.DiscountedUnitPrice}>
                                                                <p style={{ marginRight: 2 }}>&#8377;</p>
                                                                <p>{item?.mrp}</p>
                                                            </div>
                                                        </div>


                                                        {/* In screen is smaller than 650px then show this box */}
                                                        <div className={Classes.ShowResponsive}>
                                                            <div className='percent' style={{ fontSize: 14, display: 'flex' }}>
                                                                <div>
                                                                    <p className={Classes.Text}>
                                                                        Discount Incl. GST:
                                                                    </p>
                                                                </div>

                                                                <div style={{ display: 'flex' }}>
                                                                    <p>{item.discountPercentage}</p>
                                                                    <p>%</p>
                                                                </div>

                                                                {item.appliedDiscount !== null && (
                                                                    <div className={[Classes.hideDiscountTitle, 'hideDiscountTitle'].join(' ')}>
                                                                        {item.appliedDiscount}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className='qty' style={{ display: 'flex' }}>
                                                                <p className={Classes.Text}>
                                                                    Quantity:
                                                                </p>
                                                                <p>{item.quantity}</p>
                                                            </div>


                                                            <div className='total' id='discTotalPrint' style={{ display: 'flex' }}>
                                                                <p className={Classes.Text}>
                                                                    Total:
                                                                </p>
                                                                &#8377;{item.discountedTotal}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={[Classes.percent, Classes.PercentBox, 'percent'].join(' ')}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <p>{item.discountPercentage}</p>
                                                        <p>%</p>
                                                    </div>

                                                    {item.node?.appliedDiscount !== null && (
                                                        <div className={[Classes.hideDiscountNewTitle, 'hideDiscountTitle'].join(' ')}>
                                                            {item?.appliedDiscount?.title}
                                                        </div>
                                                    )}

                                                    {item?.appliedDiscount !== null && (
                                                        <div
                                                            className='exclGSTPrint'
                                                            style={{ fontSize: 12, display: 'none' }}
                                                        >
                                                            <p>(Incl. GST)</p>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className={[Classes.percent, Classes.Quantity, 'qty'].join(' ')}>
                                                    <p style={{ opacity: '0.9', fontSize: '13px', fontWeight: 'bold' }}>Total: {item?.quantity || 0}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Open: {item?.openQty || 0}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Received: {item?.receivedQty || 0}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Qc Pass: {item?.qcPassQty || 0}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Qc Fail: {item?.qcFailQty || 0}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Returned: {item?.returnedQty || 0}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Closed: {item?.closedQty || 0}</p>
                                                    <p style={{ opacity: '0.9', fontSize: '13px' }}>Frc Closed: {item?.forceClosedQty || 0}</p>
                                                </div>


                                                <div
                                                    className={[Classes.percent, 'total'].join(' ')}
                                                    style={{ padding: '10px 8px', width: 80 }}
                                                    id='discTotalPrint'
                                                >
                                                    &#8377;{item.discountedTotal}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {draftOrder?.PoLineItems?.procuredLineItems?.length !== 0 && (
                                        <div className={Classes.Thead} style={{
                                            padding: '10px 20px',
                                            fontWeight: 600, alignItems: 'center', gap: '10px', color: 'green'
                                        }} >
                                            <AiFillCheckCircle />
                                            <p style={{}}>Procured Items
                                            </p>
                                        </div>
                                    )}
                                    <div id='productContainer'>
                                        <div>
                                            {groupedProcuredLineItems && Object.keys(groupedProcuredLineItems).map((groupName) => (
                                                <div key={groupName}>
                                                    <div className={Classes.Thead} style={{
                                                        padding: '10px 20px',
                                                        fontWeight: 600, justifyContent: 'center', alignItems: 'center',
                                                    }} >
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px', color: 'green' }}>
                                                            <AiFillStar />
                                                            <p>
                                                                Procurement No: {groupName}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {groupedProcuredLineItems[groupName].map((item) => (
                                                            <div id={item.lineItemId} key={item.lineItemId}>
                                                                <div className={[Classes.containProduct, 'variantProduct'].join(' ')}>
                                                                    <div className={[Classes.productDetail, 'products'].join(' ')} id={item.lineItemId}>
                                                                        <div>
                                                                            <img
                                                                                src={'https://depo24-images-shopify.s3.ap-south-1.amazonaws.com/Assets/Image+Coming+Soon.png'}
                                                                                alt="icon"
                                                                                className={Classes.productImage}
                                                                            />
                                                                        </div>

                                                                        <div className={Classes.VariantDetail}>
                                                                            <div>
                                                                                <p style={{ color: '#0d6efd' }}>
                                                                                    {item.productTitle}
                                                                                </p>
                                                                            </div>
                                                                            <div style={{ color: '#6d7175' }}>
                                                                                <p>{item.sku}</p>
                                                                            </div>
                                                                            <div
                                                                                style={{ display: 'flex' }}
                                                                                className={[Classes.rateFontSize, 'rateExcl hidePricePrint'].join(' ')}
                                                                            >
                                                                                <p className={Classes.newRateExclGst}>
                                                                                    Subtotal (Exc. GST)
                                                                                </p>
                                                                                <div className={Classes.DiscountedUnitPrice}>
                                                                                    <p style={{ marginRight: 2 }}>&#8377;</p>
                                                                                    <p>{formatNumber(item?.discountedSubTotal)}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                style={{ display: 'flex' }}
                                                                                className={[Classes.rateFontSize, 'rateExcl hidePricePrint'].join(' ')}
                                                                            >
                                                                                <p className={Classes.newRateExclGst}>
                                                                                    Net Price
                                                                                </p>
                                                                                <div className={Classes.DiscountedUnitPrice}>
                                                                                    <p style={{ marginRight: 2 }}>&#8377;</p>
                                                                                    <p>{item.discountedUnitPrice}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                style={{ display: 'flex' }}
                                                                                className={[Classes.rateFontSize, 'rateExcl'].join(' ')}
                                                                            >
                                                                                <p className={Classes.newRateExclGst}>
                                                                                    MRP
                                                                                </p>
                                                                                <div className={Classes.DiscountedUnitPrice}>
                                                                                    <p style={{ marginRight: 2 }}>&#8377;</p>
                                                                                    <p>{item?.mrp}</p>
                                                                                </div>
                                                                            </div>

                                                                            {/* In screen is smaller than 650px then show this box */}
                                                                            <div className={Classes.ShowResponsive}>
                                                                                <div className='percent' style={{ fontSize: 14, display: 'flex' }}>
                                                                                    <div>
                                                                                        <p className={Classes.Text}>
                                                                                            Discount Incl. GST:
                                                                                        </p>
                                                                                    </div>

                                                                                    <div style={{ display: 'flex' }}>
                                                                                        <p>{item.discountPercentage}</p>
                                                                                        <p>%</p>
                                                                                    </div>

                                                                                    {item.appliedDiscount !== null && (
                                                                                        <div className={[Classes.hideDiscountTitle, 'hideDiscountTitle'].join(' ')}>
                                                                                            {item.appliedDiscount}
                                                                                        </div>
                                                                                    )}
                                                                                </div>

                                                                                <div className='qty' style={{ display: 'flex' }}>
                                                                                    <p className={Classes.Text}>Quantity:</p>
                                                                                    <p>{item?.quantity}</p>
                                                                                </div>


                                                                                <div className='total' id='discTotalPrint' style={{ display: 'flex' }}>
                                                                                    <p className={Classes.Text}>Total:</p>
                                                                                    &#8377;{item.discountedTotal}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className={[Classes.percent, Classes.PercentBox, 'percent'].join(' ')}>
                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <p>{item.discountPercentage}</p>
                                                                            <p>%</p>
                                                                        </div>

                                                                        {item?.appliedDiscount !== null && (
                                                                            <div className={[Classes.hideDiscountNewTitle, 'hideDiscountTitle'].join(' ')}>
                                                                                {item?.appliedDiscount?.title}
                                                                            </div>
                                                                        )}

                                                                        {item?.appliedDiscount !== null && (
                                                                            <div
                                                                                className='exclGSTPrint'
                                                                                style={{ fontSize: 12, display: 'none' }}
                                                                            >
                                                                                <p>(Incl. GST)</p>
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    <div className={[Classes.percent, Classes.Quantity, 'qty'].join(' ')}>
                                                                        <p style={{ opacity: '0.9', fontSize: '13px', fontWeight: 'bold' }}>Total: {item?.quantity || 0}</p>
                                                                        <p style={{ opacity: '0.9', fontSize: '13px' }}>Open: {item?.openQty || 0}</p>
                                                                        <p style={{ opacity: '0.9', fontSize: '13px' }}>Received: {item?.receivedQty || 0}</p>
                                                                        <p style={{ opacity: '0.9', fontSize: '13px' }}>Qc Pass: {item?.qcPassQty || 0}</p>
                                                                        <p style={{ opacity: '0.9', fontSize: '13px' }}>Qc Fail: {item?.qcFailQty || 0}</p>
                                                                        <p style={{ opacity: '0.9', fontSize: '13px' }}>Returned: {item?.returnedQty || 0}</p>
                                                                        <p style={{ opacity: '0.9', fontSize: '13px' }}>Closed: {item?.closedQty || 0}</p>
                                                                        <p style={{ opacity: '0.9', fontSize: '13px' }}>Frc Closed: {item?.forceClosedQty || 0}</p>
                                                                    </div>

                                                                    <div
                                                                        className={[Classes.percent, 'total'].join(' ')}
                                                                        style={{ padding: '10px 0px', width: 80 }}
                                                                        id='discTotalPrint'
                                                                    >
                                                                        &#8377;{item.discountedTotal}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>


                        <div className={[Classes.Box, 'box'].join(' ')} id='paymentBox'>
                            <div className={Classes.containerBox}>
                                <div className="d-flex justify-content-between mb-3">
                                    <h6 style={{ margin: 'auto 0px' }}>
                                        Payment
                                    </h6>
                                </div>

                                <div className={Classes.fontSize}>
                                    {draftOrder?.PoPaymentDetails?.invoiceNumber && (
                                        <div style={{ marginBottom: '1rem', width: '100%', display: 'flex' }}>
                                            <div style={{ width: '25%' }}>
                                                <p>Invoice Number</p>
                                            </div>

                                            <p style={{ width: '75%', textAlign: 'end' }}>
                                                {draftOrder?.PoPaymentDetails?.invoiceNumber}
                                            </p>
                                        </div>
                                    )}

                                    {draftOrder?.PoPaymentDetails?.invoiceDate && (
                                        <div style={{ marginBottom: '1rem', width: '100%', display: 'flex' }}>
                                            <div style={{ width: '25%' }}>
                                                <p>Invoice Date</p>
                                            </div>

                                            <p style={{ width: '75%', textAlign: 'end' }}>
                                                {formatDate(draftOrder?.PoPaymentDetails?.invoiceDate)}
                                            </p>
                                        </div>
                                    )}

                                    <div style={{ marginBottom: '1rem', width: '100%', display: 'flex' }}>
                                        <div style={{ width: '25%' }}>
                                            <p>Payment Status</p>
                                        </div>

                                        <p style={{ width: '75%', textAlign: 'end' }}>
                                            {draftOrder?.PoPaymentDetails?.paymentStatus}
                                        </p>
                                    </div>

                                    {draftOrder?.PoPaymentDetails?.paymentMode && (
                                        <div style={{ marginBottom: '1rem', width: '100%', display: 'flex' }}>
                                            <div style={{ width: '25%' }}>
                                                <p>Payment Mode</p>
                                            </div>

                                            <p style={{ width: '75%', textAlign: 'end' }}>
                                                {draftOrder?.PoPaymentDetails?.paymentMode?.toUpperCase()}
                                            </p>
                                        </div>
                                    )}

                                    <div style={{ marginBottom: '1rem', width: '100%', display: 'flex' }}>
                                        <div style={{ width: '25%' }}>
                                            <p>Subtotal</p>
                                        </div>

                                        <div style={{ width: '50%', display: 'flex' }}>
                                            <p style={{ marginRight: 3 }}>{totalItems}</p>
                                            <p>Items</p>
                                        </div>

                                        {draftOrder.length !== 0 && (
                                            <p style={{ width: '25%', textAlign: 'end' }}>
                                                &#8377;{formatNumber(draftOrder?.PoPaymentDetails?.subTotalAmount)}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        {draftOrder?.length !== 0 && (
                                            <div style={{ marginBottom: '1rem' }}>
                                                {draftOrder?.PoPaymentDetails?.overallDiscountValue && (
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <p style={{ width: '25%' }}>Discount</p>

                                                        <div style={{ width: '50%', display: 'flex' }}>
                                                            <p style={{ marginRight: 3 }}>
                                                                {draftOrder.PoPaymentDetails?.overallDiscountTitle}
                                                            </p>
                                                        </div>
                                                        <p style={{ width: '25%', textAlign: 'end' }}>
                                                            -&#8377;{draftOrder.PoPaymentDetails?.overallDiscountValue}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {draftOrder?.length !== 0 && (
                                            <div style={{ marginBottom: '1rem' }}>
                                                {draftOrder?.PoShipmentDetails?.freightCharges && (
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <p style={{ width: '25%' }}>Shipping</p>

                                                        <div style={{ width: '50%', display: 'flex' }}>
                                                            <p style={{ marginRight: 3 }}>
                                                                {draftOrder?.PoShipmentDetails?.transporterName}
                                                            </p>
                                                        </div>
                                                        <p style={{ width: '25%', textAlign: 'end' }}>
                                                            &#8377;{draftOrder?.PoShipmentDetails?.freightCharges}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        <div style={{ marginBottom: '1rem', display: 'flex' }}>
                                            <p style={{ width: '25%' }} id='tax'>Tax</p>
                                            {draftOrder.PoTaxLines ? (
                                                <p
                                                    id='taxRate'
                                                    style={{ color: '#0d6efd', cursor: 'pointer', width: '50%' }}
                                                    onClick={handleTaxRates}
                                                >
                                                    Show tax rates
                                                </p>
                                            ) : (
                                                <p style={{ width: '50%' }}></p>
                                            )}


                                            {draftOrder.length !== 0 && (
                                                <p
                                                    id='taxRateAmount'
                                                    style={{ textAlign: 'end', color: '#000', width: '25%' }}
                                                >
                                                    &#8377;{formatNumber(draftOrder?.PoPaymentDetails?.totalTaxAmount)}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between" style={{ fontSize: 14, fontWeight: 600 }}>
                                        <p>Total</p>
                                        <div>
                                            {draftOrder.length !== 0 ? (
                                                <p> &#8377;{Math.round(draftOrder.PoPaymentDetails?.totalAmount)} </p>
                                            ) : (
                                                <p>&#8377;0.00</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(draftOrder.length !== 0 && draftOrder.status !== "Closed") && (
                                <div className={Classes.containerFooter} id='removeCreateOrderPrint'>
                                    {draftOrder.length !== 0 && (
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '0.5em' }}>
                                                    {forceClose && (
                                                        <div>
                                                            <Permission service={services?.inboundPurchaseOrders} permission="create">
                                                                <button
                                                                    type='btn'
                                                                    className={[Classes.Button, Classes.editOrderButton].join(' ')}
                                                                    onClick={handleForceClose}
                                                                >
                                                                    Force Close
                                                                </button>
                                                            </Permission>
                                                        </div>
                                                    )}

                                                    {draftOrder?.PoPaymentDetails?.paymentStatus !== "Paid" && (
                                                        <div style={{marginRight: '10px'}}>
                                                            <Permission service={services?.purchaseOrders} permission="update">
                                                                <button
                                                                    type='btn'
                                                                    className={[Classes.Button, Classes.editOrderButton].join(' ')}
                                                                    onClick={handlePaidPurchaseOrder}
                                                                    style={{width:'fit-content'}}
                                                                >
                                                                    Mark Paid
                                                                </button>
                                                            </Permission>
                                                        </div>
                                                    )}

                                                    {draftOrder.status === "Open" && (
                                                        <div>
                                                            <Permission service={services?.inboundPurchaseOrders} permission="create">
                                                                <button
                                                                    type='btn'
                                                                    className={[Classes.Button, Classes.deleteOrderButton].join(' ')}
                                                                    onClick={(e) => handlePartiallyDispatchOrder(e)}
                                                                    style={{width:'fit-content'}}
                                                                >
                                                                    Partial Dispatch
                                                                </button>
                                                            </Permission>
                                                        </div>
                                                    )}

                                                    {(draftOrder.status !== "Received" && draftOrder.status !== "Dispatched" && draftOrder.status !== "Partially Received") && (
                                                        <div>
                                                            <Permission service={services?.inboundPurchaseOrders} permission="create">
                                                                <button
                                                                    type='btn'
                                                                    className={[Classes.Button, Classes.editOrderButton].join(' ')}
                                                                    style={{width:'fit-content'}}
                                                                    onClick={(e) => handleFullDispatchOrder(e)}
                                                                >
                                                                    Dispatch Order
                                                                </button>
                                                            </Permission>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                        </div>

                        {draftOrder.length !== 0 && (
                            <div id='TaxRatesPrint'>
                                {draftOrder.PoTaxLines && (
                                    <div className={showTaxRate ? Classes.UpdateSalesDialog : undefined}>
                                        <TaxRatesPopup
                                            showTaxRate={showTaxRate}
                                            setTaxRates={setTaxRates}
                                            handleTaxRates={handleTaxRates}
                                            hideTaxPopup={hideTaxPopup}
                                            draftOrder={draftOrder}
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                        {/* {draftOrder.length !== 0 && (
                            <div
                                id='TaxRatesPrint'
                                className={Classes.TaxRatesPrint}
                            >
                                {draftOrder.taxLines && (
                                    <div>
                                        {draftOrder.taxLines.length !== 0 && (
                                            <TaxRatesPrint
                                                draftOrder={draftOrder}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        )} */}
                    </div>
                    <div className={showConfirmPopup ? Classes.UpdateSalesDialog : undefined}>
                        <QcPopup
                            showConfirmPopup={showConfirmPopup}
                            show={showConfirmQc}
                            setShow={setShowConfirmQc}
                            selectedBin={selectedQcBin}
                            setSelectedBin={setSelectedQcBin}
                            bins={saleBins}
                            defaultSaleableBin={defaultSaleableBin}
                            lineItem={lineItem}
                            setLineItem={setLineItem}
                            hideConfirmPopupWindow={hideConfirmPopupWindow}
                            fetchPurchaseOrder={fetchPurchaseOrder}
                        />
                    </div>
                    <div className={showConfirmProcurementPopup ? Classes.UpdateSalesDialog : undefined}>
                        <QcPopupProcurement
                            showConfirmPopup={showConfirmProcurementPopup}
                            handleQcApproveCheck={handleQcApproveCheck}
                            handleQcRejectCheck={handleQcRejectCheck}
                            show={showConfirmProcurementQc}
                            setShow={setShowConfirmProcurementQc}
                            selectedBin={selectedQcBin}
                            setSelectedBin={setSelectedQcBin}
                            bins={saleBins}
                            hidePopup={hideConfirmPopupWindow2}
                        />
                    </div>

                    <div
                        className={Classes.asideContainer}
                        id='asideContainer'
                    >
                        <Aside
                            draftOrder={draftOrder}
                            deliveryRemark={deliveryRemark}
                            notes={notes}
                            files={documentsAttached}
                            setFiles={setDocumentsAttached}
                            defaultFiles={defaultFiles}
                            setDefaultFiles={setDefaultFiles}
                            handleDocumentsUpdate={handleDocumentsUpdate}
                        />
                    </div>
                </div>
            </div>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
};

export default InboundPurchaseOrderDetail;