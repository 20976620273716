import { useState, useEffect, forwardRef } from 'react';
import { 
    Button, Dialog, DialogActions, DialogContent, Slide, Autocomplete, 
    TextField, Chip, Stack
} from '@mui/material';
import axiosInstance from '../../utility/axios-instance';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TimeStampInputValue from './TimeStampInputValue';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogSlide = (props) => {
    let { 
        open, handleRemoveAllFilter, columns, filters, multiFilters, handleApplyFilter, 
        handleAddFilter, handleRemoveFilter, startDate, setStartDate,
        endDate, setEndDate
    } = props;
    const filterColumns = columns?.filter((column) => column?.filterable !== false);
    const [filter,setFilter] = useState({
        field: filters?.field ? filters?.field : filterColumns?.[0]?.field, 
        value: filters?.value, 
        operator: filters?.operator
    });
    const [filterEnum,setFilterEnum] = useState(false);
    const [fetchedEnumValues, setFetchedEnumValues] = useState([]);

    const handleFilterColumn = (event) => {
        setFilter({...filter, field: event?.target?.value, value: ''})
    }

    const handleFilterValue = (value) => {
        setFilter({ ...filter, value: value })
    }

    const handleFilterOperator = (event) => {
        setFilter({ ...filter, operator: event?.target?.value })
    }

    const operatorsArray = ['contains', 'equals', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty', 'isAnyOf']
    const operatorsDate = ['between']
    const operators = 
        filter?.field ? 
        ((filterColumns?.find((column) => column?.field === filter?.field))?.filterOperators)?.map((operator) => operator?.value) ?
        ((filterColumns?.find((column) => column?.field === filter?.field))?.filterOperators)?.map((operator) => operator?.value)
        :  (filter?.field === 'createdAt' || filter?.field === 'created_at')? operatorsDate : operatorsArray
        : operatorsArray

    
    const fetchEnumValues = async (tableName, columnName) => {
        if (!tableName || !columnName) return;
        const query = { tableName: tableName, columnName: columnName }
        axiosInstance
            .get(`/product/filtersForUI`, {
                params: query,
            })
            .then((res) => {
                let enumValues = res?.data?.data?.enumValues;
                enumValues = enumValues?.filter((value) => value !== null && value !== undefined && value !== '' && value !== 'null');
                setFetchedEnumValues(enumValues);
            });
    };

    useEffect(() => {
        const filterData = filterColumns?.find((column) => column?.field === filter?.field);
        if (filter?.field &&  filterData?.type === 'enum') {
            const filterColumn = filterColumns?.find((column) => column?.field === filter?.field);
            fetchEnumValues(filterColumn?.tableName, filterColumn?.columnName);
            setFilterEnum(true);
        } else if (filter?.field && filterData?.enumValues){
            setFilterEnum(true)
            setFetchedEnumValues(filterData?.enumValues)
        } else {
            setFilterEnum(false);
        }
    }, [filter]);

    const dateHandle = (filters) => {
        const startDateData = startDate ? `${startDate.$y}-${startDate.$M + 1}-${startDate.$D}` : '';
        const endDateData = endDate ? `${endDate.$y}-${endDate.$M + 1}-${endDate.$D}` : 
            new Date().toJSON().slice(0,10).replace(/-/g,'-');
        filters.value = filter?.value ? filter?.value : '';
        if (
            (filter?.field === 'createdAt' || filter?.field === 'created_at') && 
            (startDateData && endDateData)
        ) {
            filters.value = `${startDateData}-${endDateData}`;
            filters.operator = 'between'
        }
        return filters;
    }

    const applyFilter = () => {
        filters.field = filter?.field ? filter?.field : '';
        filters.operator = filter?.operator ? filter?.operator : '';
        filters.value = filter?.value ? filter?.value : '';
        filters = dateHandle(filters)
        handleApplyFilter();
    }

    const handleFilter = () => {
        filters.field = filter?.field ? filter?.field : '';
        filters.operator = filter?.operator ? filter?.operator : '';
        filters.value = filter?.value ? filter?.value : '';
        filters = dateHandle(filters)
        handleAddFilter();
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleRemoveAllFilter}
            aria-describedby="alert-dialog-slide-description"
            sx={{'& .MuiDialog-paper': {margin: '15px'}}}

        >
            <DialogContent style={{width:'92vw',padding:"18px",fontSize:'15px'}}>
                {multiFilters?.length !== 0 && multiFilters?.filter((item) => item?.value)?.length !== 0 && (
                    <div>
                        <Stack direction="row" spacing={1} style={{marginBottom:3}}>
                            <h6 style={{width:'36%',textAlign:'center',fontSize:14 }}>Column</h6>
                            <h6 style={{width:'26%',textAlign:'center',fontSize:14 }}>Operator</h6>
                            <h6 style={{width:'22%',textAlign:'center',fontSize:14 }}>Value</h6>
                        </Stack>
                        {multiFilters?.filter((item) => item?.value)?.map((filter, index) => (
                            <Stack direction="row" spacing={1} key={index} style={{marginBottom:5}}>
                                <Chip label={filter?.field} variant="outlined" style={{width:'40%'}} />
                                <Chip label={filter?.operator} variant="outlined" style={{width:'26%'}} />
                                <Chip label={filter?.value} variant="outlined" style={{width:'30%'}} />
                                <DeleteForeverIcon 
                                    style={{margin:'auto', color:'#EC4134', fontSize:'21px'}}
                                    onClick={(e) => handleRemoveFilter(e, index)}
                                />
                            </Stack>
                        ))}
                    </div>
                )}
                {multiFilters?.filter((item) => item?.value)?.length < 3  && (
                    <div>
                        <div>
                            <label style={{fontSize:12, marginLeft: 8}}>Columns</label>
                            <div>
                                <select
                                    style={{border:0, borderBottom:"1px solid black", borderRadius:0, minWidth:16 }}
                                    onChange={(e) => handleFilterColumn(e)}
                                    value={filter?.field}
                                >
                                    {filterColumns?.length && filterColumns?.map((column) => (
                                        <option value={column?.field}>{column?.headerName}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
    
                        <div> 
                            <label style={{ fontSize:12, marginLeft:8 }}>Operator</label>
                            <div>
                                <select 
                                    style={{border:0, borderBottom:"1px solid black", borderRadius:0, minWidth:16 }}
                                    onChange={(event) => handleFilterOperator(event)}
                                    value={filter?.operator}
                                >
                                    {operators?.map((operator) => (
                                        <option value={operator}>{operator}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
    
                        <div style={{marginTop: '8px'}}>
                            {(filter?.field !== 'createdAt' && filter?.field !== 'created_at') &&
                                <div>
                                    {filterEnum && (
                                        <Autocomplete
                                            id="attributeName"
                                            value={filter?.value || ''}
                                            options={fetchedEnumValues || []}
                                            onChange={(event, newValue) => { handleFilterValue(newValue) }}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            sx={{
                                                "& .MuiInput-input": { marginLeft: '10px', fontSize:'15px'},
                                                "& label": { marginLeft:'8px'}
                                            }}
                                            style={{width:'100%', marginTop:'10px'}}
                                            renderInput={(params) =>
                                                <TextField 
                                                    {...params} variant="standard" label="Attribute Name" 
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            }
                                        />
                                    )}
    
                                    {!filterEnum && (
                                        <TextField 
                                            label="Value" variant="standard" placeholder='Filter Value'
                                            size='small'
                                            sx={{ 
                                                "& .MuiInput-input": {
                                                    borderBottom:"1px solid black", borderRadius:0, marginLeft:'10px',fontSize:'15px' }, 
                                                "& label": {fontSize: '12px', marginLeft:'8px'}
                                            }}
                                            style={{width:'100%'}}
                                            value={filter?.value || ''}
                                            onChange={(event) => handleFilterValue(event?.target?.value)}
                                        />
                                    )}
                                </div>
                            }
    
                            {(filter?.field === 'createdAt' || filter?.field === 'created_at') && (
                                <TimeStampInputValue 
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                />
                            )}
                        </div>
                    </div>
                )}
            </DialogContent>
            <DialogActions style={{display:'flex',justifyContent:'space-between'}}>
                <Button onClick={handleRemoveAllFilter}>Remove</Button>
                <div>
                    {multiFilters?.length < 3 && (
                        <Button onClick={handleFilter}>Add</Button>
                    )}
                    <Button onClick={applyFilter}>Apply</Button>
                </div>
            </DialogActions>
        </Dialog>
  );
}

export default DialogSlide;